import { Row } from "react-bootstrap";

export const TabBar = ({ tabArray, activeTab = 0, setActiveTab }) => {
  const changePage = (e, nav, ndx, val) => {
    setActiveTab(ndx);
  };

  return (
    <Row>
      <div className="tab-action">
        <div className="left">
          {tabArray.map((val, index) => {
            return (
              <div
                key={val.key || index}
                className={activeTab === index ? "active" : ""}
                onClick={(e) => changePage(e, val.nav, index, val.val)}
              >
                <h3 className="p-15">{val.title}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </Row>
  );
};

export default TabBar;
