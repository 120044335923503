import { useState, useEffect } from 'react';
import { PlusIcon, UploadIconGreen } from 'assets/icons';
import { toast } from 'react-toastify';
import './index.css';
import { Modal } from 'components';

const ImageUploaderCustom = ({ name, maxImages, onChange, data, rowIndex, hideUpload = false, handleDelete }) => {
  const maxUploadImages = maxImages || 3;
  const [viewImage, setViewImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState(data || []);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const isValidFunction = (func) => {
    return typeof func === 'function';
  };

  const handleOnChange = (images, isDelete = false, imageToRemove = []) => {
    if (isValidFunction(onChange)) {
      if (isDelete) onChange(images, isDelete, imageToRemove);
      else onChange(images);
    } else {
      console.warn('onChange is not a function');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length + images.length > maxUploadImages) {
      toast.error(`You can only upload up to ${maxUploadImages} images.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const files = Array.from(e.target.files);
    const SIZE_LIMIT = 5 * 1024 * 1024;
    const largeFiles = files.filter((file) => file.size > SIZE_LIMIT);
    if (largeFiles.length > 0) {
      largeFiles.forEach((file) => {
        const fileName = file.name;
        const fileSizes = (file.size / (1024 * 1024)).toFixed(2);
        toast.error(`File ${fileName} is ${fileSizes}MB which exceeds the limit of 5MB.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
      return;
    }

    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        if (file?.src?.startsWith('http')) {
          resolve({ base64: file.src, fileName: file.name });
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ base64: reader.result, fileName: file.name });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        }
      });
    });

    Promise.all(promises)
      .then((newImages) => {
        const updatedImages = [...images, ...newImages];
        setImages(updatedImages);
        handleOnChange(updatedImages);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveImage = (index, event) => {
    let uploaderComp = document.getElementsByClassName("imageUploaderCustom__popup");
    let uploaderCompRow = document.getElementsByClassName(`imageUploaderCustom__popup ${rowIndex}`);
    if (uploaderComp.length > 0) {
      for (let i = 0; i < uploaderComp.length; i++) uploaderComp[i].style.display = "none";
      for (let i = 0; i < uploaderCompRow.length; i++) uploaderCompRow[i].style.display = "block";
    };

    confirmRemoveImageByIndex(index);
  };
  const confirmRemoveImageByIndex = (index) => {
    setImages((oldImages) => oldImages.filter((_, i) => i !== index));
    if (handleDelete) handleOnChange(images.filter((_, i) => i !== index), true, images[index]);
    else handleOnChange(images.filter((_, i) => i !== index));
  };

  const handleViewImage = (image) => {
    setViewImage(image);
    setShowModal(true);
  };

  return (
    <div className="imageUploaderCustom">
      {images.map((image, index) => {
        let fileName = "";
        if (image.src) {
          const fileArr = (image.src).split("/");
          fileName = fileArr[fileArr.length - 1];
        } else {
          fileName = image?.fileName || image?.name
        }
        return (<div key={index} className="imageUploaderCustom__imageContainer overflow-hidden">
          {/* <img
            src={image?.base64 || image?.src}
            alt={image?.fileName || image?.name}
            className="imageUploaderCustom__image"
            onClick={() => handleViewImage(image)}
          /> */}
          <div onClick={() => handleViewImage(image)} className="truncate mr-10 mt-10 cursor-pointer" title={fileName}>
            {fileName}
          </div>

          <div className="imageUploaderCustom__removeIcon" onClick={(event) => handleRemoveImage(index, event)}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.47339 0 6.85714 0.383756 6.85714 0.857143V5.14286H11.1429C11.6162 5.14286 12 5.52661 12 6C12 6.47339 11.6162 6.85714 11.1429 6.85714H6.85714V11.1429C6.85714 11.6162 6.47339 12 6 12C5.52661 12 5.14286 11.6162 5.14286 11.1429V6.85714H0.857143C0.383756 6.85714 0 6.47339 0 6C0 5.52661 0.383756 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.383756 5.52661 0 6 0Z" fill="#FF0000" />
            </svg>
          </div>
        </div>);
      })}
      {showModal && (
        <Modal
          title=''
          size='auto'
          centered
          showHeader={false}
          onShow={() => {
            const modalBody = document.querySelector('.modal-body');
            if (showModal) {
              modalBody?.classList.remove('modal-body');
            }
          }}
          onHide={() => {
            setShowModal(false);
            setViewImage(null);
          }}
          showDialog={showModal}
        >
          <img
            src={viewImage?.base64 || viewImage?.src}
            alt={viewImage?.fileName || viewImage?.name}
            className="imageUploaderCustom__modalImage fullScreenImage" />
        </Modal>
      )}
      <div>
        {images.length < maxUploadImages && !hideUpload && (
          <>
            <label
              htmlFor={`${name}-file-upload-${rowIndex}`}
              className="imageUploaderCustom__fileUploadContainer">
              <UploadIconGreen />
              Upload Image
            </label>
            <input
              multiple
              id={`${name}-file-upload-${rowIndex}`}
              type="file"
              accept="image/*"
              className="imageUploaderCustom__fileUpload"
              onChange={handleFileChange}
            />
          </>
        )}
      </div>
    </div>
  );
};


export default ImageUploaderCustom;
