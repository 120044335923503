import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrls, get } from "utils/request";

const initialState = {
  performanceStatistic: [],
  delayStatistic: {
    crushingDelayStatisticList: [],
    delayStatisticList: []
  },
  isLoading: false,
  error: null,
  errorMessages: '',
};

export const getPerformanceStatistic = createAsyncThunk(
  'DashboardPerformance/getPerformanceStatistic',
  async (params) => {
    const { endDate, startDate, shift } = params

    const response = await get(
      `${apiUrls.actualPortCrushing}/admin/performance-statistic?endDate=${endDate}&startDate=${startDate}&shift=${shift}&`
    );
    return response?.data;
  }
);

export const getDelayStatistic = createAsyncThunk(
  'DashboardPerformance/getDelayStatistic',
  async (params) => {
    const { endDate, startDate, shift } = params

    const response = await get(
      `${apiUrls.actualPortCrushing}/admin/delay-statistic?endDate=${endDate}&startDate=${startDate}&shift=${shift}&`
    );
    return response?.data;
  }
);

const DashboardPerformanceSlice = createSlice({
  name: 'DashboardPerformance',
  initialState,
  reducers: {
    resetState: (state) => {
      state.performanceStatistic = []
      state.delayStatistic = {
        crushingDelayStatisticList: [],
        delayStatisticList: []
      }
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
  },
  extraReducers: {
    [getPerformanceStatistic.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getPerformanceStatistic.fulfilled]: (state, action) => {
      let payload = action?.payload || [];

      if (payload && payload.length > 0) {
        payload = payload.sort((a, b) => a.cp > b.cp ? 1 : -1)
      }

      state.performanceStatistic = payload;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getPerformanceStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },

    [getDelayStatistic.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getDelayStatistic.fulfilled]: (state, action) => {
      state.delayStatistic = action.payload;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getDelayStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
  }
});

export const { actions } = DashboardPerformanceSlice;
export const { resetState, resetDate, setDate } = actions;
export const dashboardPerformanceSelector = (state) => state.dashboardPerformance;
export default DashboardPerformanceSlice.reducer;
