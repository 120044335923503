import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIEndpoint, post, get } from "utils/request";
import { backendUrl } from "utils/constants";

export const initialState = {
  userList: {
    list: [],
    recordsTotal: 0,
  },
  userData: null,
  userOnline: false,
  loading: false,
};

export const fetchUserList = createAsyncThunk(
  "users/filter",
  async (filter) => {
    const response = await post(
      getAPIEndpoint("user", "v1", "/users/filter"),
      filter
    );
    return response.data;
  }
);
export const fetchUserSession = createAsyncThunk("user/auth", async (token) => {
  const response = await get(backendUrl + "/auth/me?token=" + token);
  return response.data;
});

export const GLOBAL_REDUCER = "GLOBAL_REDUCER";
const AppSlice = createSlice({
  name: GLOBAL_REDUCER,
  initialState: initialState,
  reducers: {
    changeUserStatus: (state, action) => {
      const payload = action.payload;
      state.userOnline = payload;
    },
    setStatusLoading: (state, action) => {
      const payload = action.payload;
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
    builder.addCase(fetchUserSession.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userData = data;
    });
    builder.addCase(fetchUserSession.pending, (state) => {
      state.userData = null;
    });
  },
});

export const userDataSelector = (state) => state.global.userList;
export const getUserLogged = (state) => state.global.userData;
export const getUserStatus = (state) => state.global.userOnline;
export const getStatusLoading = (state) => state.global.loading;
export const { changeUserStatus, setStatusLoading } = AppSlice.actions;

export default AppSlice.reducer;
