import { useState, useEffect } from 'react';
import { PlusIcon } from 'assets/icons';
import { toast } from 'react-toastify';
import './index.css';
import { Modal } from 'components';
import imageCompression from 'browser-image-compression';

const ImageUploader = ({ maxImages, onChange, data, rowIndex, hideUpload = false, handleDelete }) => {
  const maxUploadImages = maxImages || 3;
  const [viewImage, setViewImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState(data || []);
  const [showPopup, setShowPopup] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const isValidFunction = (func) => {
    return typeof func === 'function';
  };

  const handleOnChange = (images, isDelete = false, imageToRemove = []) => {
    if (isValidFunction(onChange)) {
      if (isDelete) onChange(images, isDelete, imageToRemove);
      else onChange(images);
    } else {
      console.warn('onChange is not a function');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length + images.length > maxUploadImages) {
      toast.error(`You can only upload up to ${maxUploadImages} images.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const files = Array.from(e.target.files);
    const TARGET_SIZE_MB = 0.3;
    const compressionOptions = {
      maxSizeMB: TARGET_SIZE_MB,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const promises = files.map((file) => {
      return new Promise(async (resolve, reject) => {
        if (file?.src?.startsWith('http')) {
          resolve({ base64: file.src, fileName: file.name });
          return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            let compressedFile = await imageCompression(file, compressionOptions);
            console.log(`Compressed File Size: ${compressedFile.size / 1024 / 1024} MB`);

            while (compressedFile.size > TARGET_SIZE_MB * 1024 * 1024 && compressionOptions.maxWidthOrHeight > 200) {
              compressionOptions.maxWidthOrHeight -= 200;
              compressedFile = await imageCompression(compressedFile, compressionOptions);
              console.log(`Further Compressed File Size: ${compressedFile.size / 1024 / 1024} MB`);
            }

            if (compressedFile.size > TARGET_SIZE_MB * 1024 * 1024) {
              const fileName = compressedFile.name;
              const fileSizes = (compressedFile.size / (1024 * 1024)).toFixed(2);
              toast.error(`File ${fileName} is ${fileSizes}MB which exceeds the target limit of ${TARGET_SIZE_MB}MB.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              reject(`File ${fileName} is too large after compression`);
            } else {
              reader.readAsDataURL(compressedFile);
              reader.onloadend = () => {
                resolve({ base64: reader.result, fileName: file.name });
              };
              reader.onerror = reject;
            }
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((newImages) => {
        const updatedImages = [...images, ...newImages];
        setImages(updatedImages);
        handleOnChange(updatedImages);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveImage = (index, event) => {
    let uploaderComp = document.getElementsByClassName("imageUploader__popup");
    let uploaderCompRow = document.getElementsByClassName(`imageUploader__popup ${rowIndex}`);
    if (uploaderComp.length > 0) {
      for (let i = 0; i < uploaderComp.length; i++) uploaderComp[i].style.display = "none";
      for (let i = 0; i < uploaderCompRow.length; i++) uploaderCompRow[i].style.display = "block";
    };

    setImageToRemove(index);
    setShowPopup(true);
    setCursorPosition({ x: event.pageX, y: event.pageY });
  };

  const handleViewImage = (image) => {
    setViewImage(image);
    setShowModal(true);
  };

  const confirmRemoveImage = () => {
    setImages((oldImages) => oldImages.filter((_, i) => i !== imageToRemove));
    if (handleDelete) handleOnChange(images.filter((_, i) => i !== imageToRemove), true, images[imageToRemove]);
    else handleOnChange(images.filter((_, i) => i !== imageToRemove));
    setShowPopup(false);
  };

  return (
    <div className="imageUploader">
      {images.map((image, index) => {
        return (<div key={index} className="imageUploader__imageContainer">
          <img
            src={image?.base64 || image?.src}
            alt={image?.fileName || image?.name}
            className="imageUploader__image"
            onClick={() => handleViewImage(image)}
          />

          <div className="imageUploader__removeIcon" onClick={(event) => handleRemoveImage(index, event)}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.47339 0 6.85714 0.383756 6.85714 0.857143V5.14286H11.1429C11.6162 5.14286 12 5.52661 12 6C12 6.47339 11.6162 6.85714 11.1429 6.85714H6.85714V11.1429C6.85714 11.6162 6.47339 12 6 12C5.52661 12 5.14286 11.6162 5.14286 11.1429V6.85714H0.857143C0.383756 6.85714 0 6.47339 0 6C0 5.52661 0.383756 5.14286 0.857143 5.14286H5.14286V0.857143C5.14286 0.383756 5.52661 0 6 0Z" fill="#FF0000" />
            </svg>
          </div>
        </div>);
      })}
      {showPopup && (
        <div className={`imageUploader__popup ${rowIndex}`} style={{ top: cursorPosition.y, left: cursorPosition.x }}>
          <p className="imageUploader__popupText">Delete Image?</p>
          <button
            className="imageUploader__button imageUploader__button--yes"
            onClick={confirmRemoveImage}>
            Yes
          </button>
          <button
            className="imageUploader__button imageUploader__button--no"
            onClick={() => setShowPopup(false)}>
            Cancel
          </button>
        </div>
      )}
      {showModal && (
        <Modal
          title=''
          size='auto'
          centered
          showHeader={false}
          onShow={() => {
            const modalBody = document.querySelector('.modal-body');
            if (showModal) {
              modalBody?.classList.remove('modal-body');
            }
          }}
          onHide={() => {
            setShowModal(false);
            setViewImage(null);
          }}
          showDialog={showModal}
        >
          <img
            src={viewImage?.base64 || viewImage?.src}
            alt={viewImage?.fileName || viewImage?.name}
            className="imageUploader__modalImage fullScreenImage" />
        </Modal>
      )}
      <div>
        {images.length < maxUploadImages && !hideUpload && (
          <>
            <label
              htmlFor={`file-upload-${rowIndex}`}
              className="imageUploader__fileUploadContainer">
              <PlusIcon />
            </label>
            <input
              multiple
              id={`file-upload-${rowIndex}`}
              type="file"
              accept="image/*"
              className="imageUploader__fileUpload"
              onChange={handleFileChange}
            />
          </>
        )}
      </div>
    </div>
  );
};


export default ImageUploader;
