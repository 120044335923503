import RBDropdown from 'react-bootstrap/Dropdown';
import RBDropdownButton from 'react-bootstrap/DropdownButton';
import { useState, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller } from 'react-hook-form';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import find from 'lodash/find';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { Check } from 'assets/icons';
import { InputGroup } from 'react-bootstrap';


export const DropdownSearch = ({
  name,
  onSelect = () => null,
  control,
  rules,
  error,
  errorList = [],
  selectedOption,
  placeholder,
  options = [],
  variant = 'secondary',
  label,
  multi = false,
  group,
  groupClass = 'mb-3',
  disabled = false,
  showSearch = true,
  ...rest
}) => {
  const [query, setQuery] = useState("")
  const [items, setItems] = useState([])
  useMemo(() => {
    setItems(options.filter(e => (e.label.toString() || "").toLowerCase().indexOf((query || "").toLowerCase()) >= 0))
  }, [options, query])
  const dropdownNode = (() => {
    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={selectedOption}
          render={({ field }) => {
            const handleClick = (option) => () => {
              field.onChange(option);
              onSelect(option)
            };
            let title = field.value?.label ?? placeholder;
            return (
              <RBDropdownButton title={title} variant={variant} disabled={disabled} onToggle={() => setQuery("")} {...rest} {...field}>
                {showSearch && <InputGroup >
                  <Form.Control type="text" placeholder="Search" className="m-8 mt-0" onChange={(val) => setQuery(val.currentTarget.value)} value={query} />
                </InputGroup>}
                {items.map((option, index) => (
                  <RBDropdown.Item
                    key={`${index}-${option.value}`}
                    onClick={handleClick(option)}
                    active={selectedOption?.value === option.value}>
                    {option.label}
                  </RBDropdown.Item>
                ))}
                {!items.length && (
                  <div className="ccm-text-black-lighter px-16 py-5px d-flex align-center">
                    <span>No Options</span>
                  </div>
                )}
              </RBDropdownButton>
            );
          }}
        />
        <div className="ccm-error">{error}</div>
        {errorList.map(e => (<div className="ccm-error">{e}</div>))}
      </>
    )
  })();
  return group ? <Form.Group className={groupClass}>{dropdownNode}</Form.Group> : dropdownNode;
};

export default DropdownSearch;
