
import axios from 'axios';
import { apiUrls } from "utils/request";
import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');
const generatePayload = (params) => {
  const { pit, date, contractor } = params;
  const currentDate = moment().format('YYYY-MM-DD');

  let payload = {
    date: formatDate(date) || currentDate,
  };

  if (pit) {
    payload.pit = pit !== undefined && pit !== "All" ? pit : "";
  }

  if (contractor) {
    payload.contractor = contractor !== undefined && contractor !== "All" ? contractor : "";
  }

  return payload;
};

export const initialState = {
  coalGettingHour: {
    loading: false,
    records: []
  },
  wasteRemovalHour: {
    loading: false,
    records: []
  }
};

export const getCoalHourlyActualProgress = createAsyncThunk(
  'dashboardPit/getCoalHourlyActualProgress',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.get(`${apiUrls.quickReport}/coal-getting?date=${payload.date}&pit=${payload.pit !== undefined ? payload.pit : ""}&contractor=${payload.contractor !== undefined ? payload.contractor : ""}`);
    return response.data;
  }
);

export const getCoalHourlyActualProgressInterval = createAsyncThunk(
  'dashboardPit/getCoalHourlyActualProgress-interval',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.get(`${apiUrls.quickReport}/coal-getting?date=${payload.date}&pit=${payload.pit !== undefined ? payload.pit : ""}&contractor=${payload.contractor !== undefined ? payload.contractor : ""}`);
    return response.data;
  }
);

export const getWasteHourlyActualProgress = createAsyncThunk(
  'dashboardPit/getWasteHourlyActualProgress',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.get(`${apiUrls.quickReport}/waste-removal?date=${payload.date}&pit=${payload.pit !== undefined ? payload.pit : ""}&contractor=${payload.contractor !== undefined ? payload.contractor : ""}`);
    return response.data;
  }
);

export const getWasteHourlyActualProgressInterval = createAsyncThunk(
  'dashboardPit/getWasteHourlyActualProgress-interval',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.get(`${apiUrls.quickReport}/waste-removal?date=${payload.date}&pit=${payload.pit !== undefined ? payload.pit : ""}&contractor=${payload.contractor !== undefined ? payload.contractor : ""}`);
    return response.data;
  }
);

const downloadFile = (data, filename = 'file.xlsx') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const getExportWasteHourly = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrls.wasteRemoval}/export-excel/dashboard`, params, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.ms-excel',
      },
    });

    downloadFile(response.data, 'Waste Production.xlsx');

    dispatch({ type: 'GET_EXPORT_WASTE_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_EXPORT_WASTE_ERROR', payload: error });
  }
};

export const getExportCoalHourly = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrls.coalGetting}/export-excel/dashboard`, params, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.ms-excel',
      },
    });

    downloadFile(response.data, 'Coal Production.xlsx');

    dispatch({ type: 'GET_EXPORT_COAL_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_EXPORT_COAL_ERROR', payload: error });
  }
};

const dashboardPitSlice = createSlice({
  name: 'PIT_HOURLY',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoalHourlyActualProgress.pending, (state, action) => {
      state.coalGettingHour.loading = true;
    });
    builder.addCase(getCoalHourlyActualProgress.fulfilled, (state, action) => {
      state.coalGettingHour.records = action.payload || [];
      state.coalGettingHour.loading = false;
    });
    builder.addCase(getCoalHourlyActualProgressInterval.fulfilled, (state, action) => {
      state.coalGettingHour.records = action.payload || [];
      state.coalGettingHour.loading = false;
    });
    builder.addCase(getCoalHourlyActualProgress.rejected, (state, action) => {
      state.coalGettingHour.loading = false;
    });
    builder.addCase(getWasteHourlyActualProgress.pending, (state, action) => {
      state.wasteRemovalHour.loading = true;
    });
    builder.addCase(getWasteHourlyActualProgress.fulfilled, (state, action) => {
      state.wasteRemovalHour.records = action.payload || [];
      state.wasteRemovalHour.loading = false;
    });
    builder.addCase(getWasteHourlyActualProgressInterval.fulfilled, (state, action) => {
      state.wasteRemovalHour.records = action.payload || [];
      state.wasteRemovalHour.loading = false;
    });
    builder.addCase(getWasteHourlyActualProgress.rejected, (state, action) => {
      state.wasteRemovalHour.loading = false;
    });
  }
})

export const coalHourlySelector = (state) => state.dashboardPit.coalGettingHour;
export const wasteHourlySelector = (state) => state.dashboardPit.wasteRemovalHour;

export default dashboardPitSlice.reducer;
