import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import moment from "moment";
import { apiUrls, post } from "utils/request";

const initialState = {
  data: {
    dataActual: [],
    dataPlan: [],
    dataPlanAvg: [],
    planAccumulation: [],
    actualAccumulation: [],
    planProductivityAccumulation: [],
    dataProductivityPlan: [],
    dataProductivityAvg: [],
    dataProductivityActual: [],
    actualProductivityAccumulation: [],
    dtm: 0,
    ytd: 0,
  },
  isLoading: false,
  error: null,
  errorMessages: "",
  date: moment().format("YYYY-MM"),
  listMDRom: [],
};

const removeNullValues = (obj) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([key, value]) => value !== null);

  return Object.fromEntries(filteredEntries);
};

export const getMDRom = createAsyncThunk(
  "master-data-rom/v2/rom/filter",
  async (params, thunkAPI) => {
    const payload = {
      columns: [],
      start: 0,
      draw: 1,
      length: 500,
      ...params,
    };
    const response = await axios.post(`${apiUrls.mdRom}/filter`, payload);
    return response.data;
  }
);

export const getRomActualDashboard = createAsyncThunk(
  "fleet-actual-rom/v2/rom/dashboard/actual",
  async (params) => {
    const response = await post(
      `${apiUrls.actualRom}/admin/dashboard/actual`,
      removeNullValues(params)
    );
    return response?.data;
  }
);

export const getRomProductivityActualDashboard = createAsyncThunk(
  "fleet-actual-rom/v2/rom/dashboard/productivity",
  async (params) => {
    const response = await post(
      `${apiUrls.actualRom}/admin/dashboard/productivity`,
      removeNullValues(params)
    );
    return response?.data;
  }
);

export const getConfRomPlanDashboard = createAsyncThunk(
  "config-rom/v2/configuration-rom/dashboard/plan",
  async (params) => {
    const response = await post(
      `${apiUrls.configRom}/dashboard/plan`,
      removeNullValues(params)
    );
    return response?.data;
  }
);

const generateResultArray = (response = [], date, metrics = "actual") => {
  const daysInMonth = moment(date, "YYYY-MM").daysInMonth();
  const resultArray = new Array(daysInMonth).fill(0);

  if (metrics === "actual") {
    response.forEach((item) => {
      const dayOfMonth = moment(item.date, "YYYY-MM-DD").date();
      const index = dayOfMonth - 1;
      resultArray[index] += item.value;
      //disini tambah
    });
  } else if (metrics === "actual-productivity") {
    response.forEach((item) => {
      const dayOfMonth = moment(item.date, "YYYY-MM-DD").date();
      const index = dayOfMonth - 1;
      resultArray[index] = item.value;
    });
  } else if (metrics === "plan" && response.length > 0) {
    const planValues = response[0].value || response[0].values;
    return new Array(daysInMonth).fill(planValues);
  } else if (metrics === "plan_productivity" && response.length > 0) {
    const planValues = response[0].productivity_target;
    return new Array(daysInMonth).fill(planValues);
  }

  return resultArray;
};

const cumulativeSum = (arr) => {
  let result = [];
  let sum = 0;
  arr.forEach((num) => {
    sum += num;
    result.push(sum);
  });
  return result;
};

const RomDashboardProductionSlice = createSlice({
  name: "RomDashboardProduction",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = {
        dataActual: [],
        dataPlan: [],
      };
      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    resetDate: (state) => {
      state.date = moment().format("YYYY-MM");
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
  },
  extraReducers: {
    [getRomActualDashboard.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },

    [getRomActualDashboard.fulfilled]: (state, action) => {
      const data = generateResultArray(action.payload.dataList, state.date);
      const dtm = action.payload.monthToDate;
      const ytd = action.payload.yearToDate;

      const groupByDayAndRom = (data) => {
        return data.reduce((acc, curr) => {
          const day = curr.day;
          const rom = curr.rom;

          if (!acc[day]) {
            acc[day] = {};
          }

          if (!acc[day][rom]) {
            acc[day][rom] = [];
          }

          acc[day][rom].push(curr);
          return acc;
        }, {});
      };
      const transformData = (groupedData) => {
        const transformedData = {};

        for (const day in groupedData) {
          transformedData[day] = {
            Rom: [],
            value: [],
          };

          for (const id in groupedData[day]) {
            const firstItem = groupedData[day][id][0];
            transformedData[day].Rom.push(firstItem.rom);
            transformedData[day].value.push(firstItem.value);
          }
        }

        return transformedData;
      };
      const groupedData = groupByDayAndRom(action.payload.dataList);
      const mergedDataByDay = transformData(groupedData);

      state.data.dtm = dtm;
      state.data.ytd = ytd;
      state.data.dataActual = data;
      state.data.actualAccumulation = cumulativeSum(data);
      state.data.mergedDataByDay = mergedDataByDay; // Add mergedDataByDay to the state
      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getRomActualDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getRomProductivityActualDashboard.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },
    [getRomProductivityActualDashboard.fulfilled]: (state, action) => {
      const data = generateResultArray(
        action.payload,
        state.date,
        "actual-productivity"
      );

      state.data.dataProductivityActual = data;
      state.data.actualProductivityAccumulation = cumulativeSum(data);
      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getRomProductivityActualDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getConfRomPlanDashboard.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },
    [getConfRomPlanDashboard.fulfilled]: (state, action) => {
      const data = generateResultArray(action.payload, state.date, "plan");
      const data2 = generateResultArray(
        action.payload,
        state.date,
        "plan_productivity"
      );
      state.data.dataPlan = data;
      state.data.dataProductivityPlan = data2;
      state.data.planAccumulation = cumulativeSum(data);
      state.data.planProductivityAccumulation = cumulativeSum(data2);
      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getConfRomPlanDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getMDRom.fulfilled]: (state, action) => {
      const data = action.payload.list || [];
      state.listMDRom = data.reduce((unique, item) => {
        const isDuplicate = unique.some(
          (uniqueItem) => uniqueItem.rom === item.rom
        );
        return isDuplicate
          ? unique
          : [...unique, { value: item.rom, label: item.rom, ...item }];
      }, []);
      state.listMDRom.unshift({ value: null, label: "All" });
    },
    [getMDRom.rejected]: (state, action) => {
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
  },
});

export const { actions } = RomDashboardProductionSlice;
export const { resetState, resetDate, setDate } = actions;
export const romDashboardProductionSelector = (state) =>
  state.romDashboardProduction;
export default RomDashboardProductionSlice.reducer;
