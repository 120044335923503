import { apiUrls } from 'utils/request';
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getUsername } from 'utils/helper';
import { shortData, addUuid } from './helpers';

const initialState = {
  records: [],
  dataCreate: {
    listCP: [],
    listCategory: [],
    originalList: [],
  },
  loading: true,
  error: {},
  recordsTotal: 0,
  showPopup: {
    children: {},
    isOpen: false,
    onchange: {},
  },
  detailAdmin: [],
  originalDetailAdmin: [],
  detailActualPort: [],
  crushingActualUserListOptions: [],
};

const API_CATEGORY = `${apiUrls.actualPortCategory}`;
const API_ANSWER = `${apiUrls.actualPortAnswer}`;

export const fetchDetailActualPort = createAsyncThunk(
  'actual-port-crusher/detail',
  async (id) => {
    const response = await axios.get(`${apiUrls.actualPortCrushing}/${id}`);
    return response.data;
  }
);

export const getCrusherPlan = createAsyncThunk(
  "crusherPlan/get",
  async (params, thunkAPI) => {
    const response = await axios.post(`${apiUrls.crushers}/filter`, params);
    return response.data;
  }
);

export const getCategoryByFilter = createAsyncThunk(
  "category/getByFilter",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/filter`, {
      columns: [],
      start: 0
    });
    return response.data;
  }
);

export const getCategoryById = createAsyncThunk(
  "category/getById",
  async (id, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/${id}`);
    return response.data;
  }
);

export const addCategory = createAsyncThunk(
  "category/add",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_CATEGORY}/add?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const saveCategory = createAsyncThunk(
  "category/save",
  async (params, thunkAPI) => {
    const renumberSort = (data) => {
      data.categoryList = data.categoryList.map((item, index) => {
        return {
          ...item,
          sort: index,
        };
      });

      return data;
    }
    const newPayload = renumberSort(params);

    const response = await axios.put(`${API_CATEGORY}/save?userName=${getUsername()}`, newPayload);
    return response.data;
  }
);

export const crushingActualUserList = createAsyncThunk(
  "user/crushingActual",
  async (params, thunkAPI) => {
    const response = await axios.get(`${apiUrls.userMember}/user-members`);
    return response.data;
  }
);

export const getDetailAdmin = createAsyncThunk(
  "answer/getByFilter",
  async (params, thunkAPI) => {
    if (!params.category) {
      delete params.category;
    }
    const response = await axios.post(`${API_ANSWER}/get?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const saveAnswer = createAsyncThunk(
  "answer/save/item",
  async (params, thunkAPI) => {
    const response = await axios.post(`${API_ANSWER}/save/item?userName=${getUsername()}`, params);
    return response.data;
  }
);

export const getAnswerStatus = createAsyncThunk(
  'crushing/attendance/get-answer-status',
  async (option) => {
    let response;

    try {
      response = await axios.post(`${apiUrls.actualPort}answer/get/status-submit?userName=${getUsername()}`, option);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

export const submitAnswer = createAsyncThunk(
  'crushing/attendance/submit-answer',
  async (option) => {
    let response;

    try {
      response = await axios.post(`${apiUrls.actualPort}answer/submit?userName=${getUsername()}`, option);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

export const submitAnswerProgress = createAsyncThunk(
  'crushing/attendance/submit-answer-progress',
  async (option) => {
    let response;
    const { status, params } = option;

    try {
      response = await axios.post(`${apiUrls.actualPort}answer/submit/progress?status=${status}&userName=${getUsername()}`, params);
      return response?.data;
    }
    catch (err) {
      return err;
    }
  }
);

export const getLatestByUser = createAsyncThunk(
  'crushing/get-latest',
  async (param) => {
    let response;
    try {
      response = await axios.get(`${apiUrls.portCrushing}/latest-by-user?userName=${getUsername()}`);
    }
    catch (e) {
      return e.data;
    }

    return response.data;
  }
);



const P2H = createSlice({
  name: "P2H",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = {};
    },
    resetPopup: (state) => {
      state.showPopup = {
        children: {},
        isOpen: false,
        onchange: {},
      }
    },
    setPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    insertNewCategory: (state, action) => {
      let listCategory = state.dataCreate.listCategory;
      listCategory.forEach(e => e.sort += 1)
      state.dataCreate.listCategory = listCategory

      const categoryTemplate = {
        id: null,
        uuid: uuidv4(),
        isNew: true,
        sort: 0,
        name: `New Category`,
        crushingPlantId: null,
        entityStatus: 1,
        disableRow: false,
        question: [],
      };
      state.dataCreate.listCategory.unshift(categoryTemplate);
    },
    deleteNewCategory: (state, action) => {
      const uuid = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        state.dataCreate.listCategory.splice(index, 1);
      }
    },
    resetNewData: (state) => {
      state.dataCreate = {
        listCP: [],
        listCategory: [],
      };
    },
    insertListCP: (state, action) => {
      state.dataCreate.listCP = action.payload;
    },
    handleDragDrop: (state, action) => {
      const { listCategory } = action.payload;
      state.dataCreate.listCategory = listCategory;
    },
    handleDragDropQuestion: (state, action) => {
      const { categoryUuid, listQuestion } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        state.dataCreate.listCategory[index].question = listQuestion;
      }
    },
    insertQuestion: (state, action) => {
      const { uuid } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        let listQuestion = state.dataCreate.listCategory[index].question;
        listQuestion.forEach(e => e.sort += 1)
        state.dataCreate.listCategory[index].question = listQuestion

        const questionTemplate = {
          disableRow: false,
          entityStatus: 1,
          id: null,
          isNew: false,
          name: 'Question',
          plant: '',
          sort: 0,
          typeAnswer: 0,
          uuid: uuidv4(),
        };
        state.dataCreate.listCategory[index].question.unshift(questionTemplate);
      }
    },
    handleDeleteQuestion: (state, action) => {
      const { categoryUuid, questionUuid } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question.splice(questionIndex, 1);
        }
      }
    },
    replaceQuestion: (state, action) => {
      const { categoryUuid, questionUuid, question } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question[questionIndex].name = question;
        }
      }
    },
    replaceAnswer: (state, action) => {
      const { categoryUuid, questionUuid, typeAnswer } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        const questionIndex = state.dataCreate.listCategory[index].question.findIndex((item) => item.uuid === questionUuid);
        if (questionIndex !== -1) {
          state.dataCreate.listCategory[index].question[questionIndex].typeAnswer = typeAnswer;
        }
      }
    },
    replaceCategory: (state, action) => {
      const { categoryUuid, category } = action.payload;
      const index = state.dataCreate.listCategory.findIndex((item) => item.uuid === categoryUuid);
      if (index !== -1) {
        state.dataCreate.listCategory[index].name = category;
      }
    },
    handleInsertQuestionAdmin: (state, action) => {
      const { categoryId, questionId, answerId, parentData, nik, inputName, inputValue } = action.payload;

      const index = state.detailAdmin.findIndex((item) => item?.id === categoryId);
      if (index !== -1) {
        const questionIndex = state.detailAdmin[ index ]?.question.findIndex((item) => item?.id === questionId);
        if (questionIndex !== -1) {
          state.detailAdmin = state.detailAdmin.map((item) => {
            if (item.id === categoryId) {
              const isNewAnswer = item.question[ questionIndex ]?.answer === null;

              return {
                ...item,
                ...(isNewAnswer ? { isNew: true } : { isEdited: true }),
                question: item.question.map((itemQuestion) => {
                  if (itemQuestion.id === questionId) {
                    const newAnswer = {
                      category: item?.name,
                      checkbox: itemQuestion?.typeAnswer,
                      documentList: [],
                      questionId,
                      freetext: '',
                    };

                    let tempAnswer = isNewAnswer ? newAnswer : itemQuestion.answer;
                    const isInputNameOperator = inputName === 'operator'

                    if (isInputNameOperator) {
                      tempAnswer = {
                        ...tempAnswer,
                        nik: nik || ''
                      }
                    }

                    return {
                      ...itemQuestion,
                      answer: {
                        ...tempAnswer,
                        ...(isNewAnswer ? { isNew: true } : { isEdited: true }),
                        questionId,
                        [ inputName ]: inputValue,
                        // updatedBy: getUsername(),
                        // updatedAt: new Date().toISOString(),
                      }
                    };
                  }
                  return itemQuestion;
                })
              }
            }
            return item;
          })
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCrusherPlan.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCrusherPlan.fulfilled, (state, action) => {
      const filterStatusActive = (data) => {
        const STATUS_ACTIVE = 1;
        return data.filter((item) => item.entityStatus === STATUS_ACTIVE);
      }
      state.loading = false;
      state.records = filterStatusActive(action.payload?.list);
      state.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(getCrusherPlan.rejected, (state, action) => {
      state.loading = false;
      state.error.getCrusherPlan = action.error;
    });
    builder.addCase(getCategoryById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryById.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload
      state.dataCreate.listCategory = shortData(addUuid(data));
      state.dataCreate.originalList = shortData(addUuid(data));
    });
    builder.addCase(getCategoryById.rejected, (state, action) => {
      state.loading = false;
      state.error.getCategoryById = action.error;
    });
    builder.addCase(addCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.loading = false;
      state.error.addCategory = action.error;
    });
    builder.addCase(saveCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCategory.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(saveCategory.rejected, (state, action) => {
      state.loading = false;
      state.error.saveCategory = action.error;
    });
    builder.addCase(crushingActualUserList.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(crushingActualUserList.fulfilled, (state, action) => {
      // state.loading = false;
      const crushingActualUserList = action.payload.filter((item) => item.entityName === "CrsuhingActual");
      state.crushingActualUserListOptions = crushingActualUserList.map((item) => ({
        value: item.name,
        label: item.name,
        ...item,
      }));
    });
    builder.addCase(crushingActualUserList.rejected, (state, action) => {
      // state.loading = false;
      state.error.crushingActualUserList = action.error;
    });
    builder.addCase(getDetailAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailAdmin.fulfilled, (state, action) => {
      state.detailAdmin = action.payload;
      state.originalDetailAdmin = action.payload;
      state.loading = false;
    });
    builder.addCase(getDetailAdmin.rejected, (state, action) => {
      state.error.getDetailAdmin = action.error;
      state.loading = false;
    });
    builder.addCase(fetchDetailActualPort.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(fetchDetailActualPort.fulfilled, (state, action) => {
      // state.loading = false;
      state.detailActualPort = action.payload;
    });
    builder.addCase(fetchDetailActualPort.rejected, (state, action) => {
      // state.loading = false;
      state.error.fetchDetailActualPort = action.error;
    });
  }
})

export const {
  resetPopup,
  resetError,
  setPopup,
  insertNewCategory,
  resetNewData,
  insertListCP,
  deleteNewCategory,
  insertQuestion,
  handleDeleteQuestion,
  replaceQuestion,
  replaceAnswer,
  replaceCategory,
  handleDragDrop,
  handleDragDropQuestion,
  handleInsertQuestionAdmin,
  insertPayloadParentData,
  getAllAnswer
} = P2H.actions;
export default P2H.reducer;
