import { Row, Col } from "react-bootstrap";
import cx from "classnames";
import _, { isInteger, isUndefined } from "lodash";
import { getStatusClass } from "utils/helper";
import {
  ArrowDown,
  RightChevron,
  LeftChevron,
  DeleteCloseIconLarge,
} from "assets/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const LogStatus = ({
  data,
  displayReportId = true,
  pagination = false,
  pageSize = null,
  pageCount = null,
  changePage = false,
  changeIndex = false,
  pageIndexLog = 0,
  closeLog = null,
}) => {
  const ref = useRef();

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) onClose();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
  var formatData = data?.map((data) => {
    return {
      ...data,
      dateFormated: new Date(`${data?.date}Z`).toLocaleDateString(
        "id-ID",
        options
      ),
      newDate: new Date(`${data?.date}Z`).toLocaleDateString("id-ID", options),
      dateForSort: new Date(`${data?.date}Z`).getTime(),
    };
  });

  var newData = formatData,
    result = _(newData)
      .orderBy(["dateForSort"], ["desc"])
      .groupBy("dateFormated")
      .map((group) => ({
        group: _.map(
          group,
          ({
            content: name,
            dataStatus,
            modifiedDescriptions,
            action,
            dateFormated,
            date,
            reportId,
            notes,
          }) => ({
            name,
            dataStatus,
            modifiedDescriptions,
            action,
            dateFormated,
            date,
            reportId,
            notes,
          })
        ),
      }))
      .value();

  const totalPages = pageCount;
  const pageIndex = pageIndexLog - 1;

  useEffect(() => {
    if (pageIndex === 0) setCanPreviousPage(false);
    else setCanPreviousPage(true);

    if (pageIndex + 1 === pageCount) setCanNextPage(false);
    else setCanNextPage(true);
  }, [changeIndex, changePage]);

  const startPageIndex = 0;
  let startPage, endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = startPageIndex;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (pageIndex <= 6) {
      startPage = startPageIndex;
      endPage = 9;
    } else if (pageIndex + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = pageIndex - 5;
      endPage = pageIndex + 4;
    }
  }

  const gotoPage = (index) => changeIndex(index + 1);
  const previousPage = () => (canPreviousPage ? changeIndex(pageIndex) : null);
  const nextPage = () => (canNextPage ? changeIndex(pageIndex + 2) : null);
  const onClose = () => closeLog(false);

  const renderLogs = (e) => {
    const totalIndex = e.group.length
    return e.group.map((data, index) => {
      let reportId = data?.reportId ? data.reportId : "";
      let status = data?.action ? data.action : data.dataStatus;
      let message = data?.modifiedDescriptions
        ? data.modifiedDescriptions
        : data.message;
      let notes = data?.notes;
      let getStatus;

      var date = new Date(`${data?.date}Z`);
      var dateFormat = date.toLocaleDateString("id-ID", options);
      var timeFormat = date.toLocaleTimeString("id-ID", timeOptions);
      if (isUndefined(message) || !message) {
        let prefix = "";
        if (displayReportId) prefix = `${reportId} has been `;

        if ((status === "DRAFT" || status === 1) && index === (totalIndex - 1))
          message = `${prefix}created by Admin on ${dateFormat}`;
        else if ((status === "DRAFT" || status === 1) && index !== (totalIndex - 1))
          message = `${prefix}edited by Admin on ${dateFormat}`;
        else if (status == "WAITING_APPROVAL" || status === 2 || status >= 20)
          message = `${prefix}submitted by Admin on ${dateFormat}`;
        else if (status == "DONE" || status == 3)
          message = `${prefix}approved by Admin on ${dateFormat}`;
        else if (status == "REJECTED" || status == 4)
          message = `${prefix}rejected by Admin on ${dateFormat}`;
      }

      if (isInteger(status)) getStatus = getStatusClass(status || "");

      return (
        <>
          <Col className="mb-20">
            {isInteger(status) && (
              <div
                className={`log-status__status ${getStatus.divBG} ${getStatus.textColor}`}
              >
                {getStatus.text}
              </div>
            )}
            {!isInteger(status) && (
              <div className={`log-status__status ${status?.toLowerCase()}`}>
                {status}
              </div>
            )}
            <Col>
              <div className="d-flex">
                <div className="text-align-end">
                  <div className="content fs-14 lh-22">
                    {message !== "undefined" ? message : ""}
                  </div>
                  <div className="content fs-14 lh-22">
                    {notes !== "undefined" ? notes : ""}
                  </div>
                  <div className="content fs-14 lh-22 fw-bold">
                    {timeFormat}
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </>
      );
    });
  };

  return (
    <div ref={ref}>
      <div className="log-status border-t">
        <div
          className="d-flex border-b"
          style={{ justifyContent: "space-between" }}
        >
          <div className="log-status__title">Logs</div>
          {closeLog && (
            <div
              className="mt-6"
              style={{ cursor: "pointer" }}
              onClick={() => onClose()}
            >
              <DeleteCloseIconLarge style={{ borderRadius: "50%" }} />
            </div>
          )}
        </div>
        <div className="ml-18 border-l">
          {!data || !data.length
            ? "No logs"
            : result?.map((e, index) => {
                var date = new Date(`${e?.group[0]?.date}Z`);
                var dateFormat = date.toLocaleDateString("id-ID", options);

                return (
                  <div className="log-status__wrapper mt-20" key={index}>
                    <div className="d-flex align-items-center">
                      <div className="date">{dateFormat}</div>
                    </div>
                    {renderLogs(e)}
                  </div>
                );
              })}
        </div>
        {pagination ? (
          <>
            <div
              className="wrapper-pagination"
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="pagination">
                <div
                  className={`pagination-prev ${
                    !canPreviousPage && "disabled"
                  }`}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <LeftChevron />
                </div>
                {Array.from({ length: endPage - startPage }).map((_, index) => {
                  const currentPageIndex = startPage + index;
                  return (
                    <div
                      key={`page-${currentPageIndex}`}
                      className={cx("pagination-page", {
                        active: currentPageIndex === pageIndex,
                      })}
                      onClick={() => gotoPage(currentPageIndex)}
                    >
                      {currentPageIndex + 1}
                    </div>
                  );
                })}
                <div
                  className={`pagination-next ${!canNextPage && "disabled"}`}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <RightChevron />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LogStatus;
