import { Row } from "react-bootstrap";

export const TabPage = ({
  tabArray,
  activeTab = 0,
  setActive = () => { },
}) => {
  const changePage = (e, nav) => {
    if (nav && (!setActive || setActive === null)) {
      e.preventDefault();
      return window.location.href = nav
    }
    return setActive(nav)
  }

  return (
    <Row>
      <div className="tab-action">
        <div className="left">
          {tabArray.map((val, index) => {
            return (
              <div key={val.key || index} className={activeTab === index ? "active" : ""} onClick={(e) => changePage(e, val.nav)}>
                <h3 className='p-15'>{val.title}</h3>
              </div>
            )
          })}
        </div>
      </div>
    </Row>
  );
};

export default TabPage;
