import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, get, patch, post } from 'utils/request';
import { DATASOURCE_VALUE } from 'utils/helper';
var _ = require('lodash');

export const initialState = {
  portCrusher: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  confCrusherPlantMaster: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  portCrusherDetail: {
    loading: false,
    records: {
      planList: [],
      statusLogList: [{ dataStatus: 'DRAFT' }],
      documentList: [],
    },
    recordsTotal: '',
    error: '',
  },
  crusherSummary: {
    records: {
      production: 0,
      productivity: 0,
    },
  },
  versionList: [],
};

export const getPortCrusherData = createAsyncThunk(
  'portCrusher/get',
  async (params) => {
    const response = await post(apiUrls.planRom.concat(`/filter`), {
      columns: [
        {
          data: 'id',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: false,
        },
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date,
          searchable: true,
        },
        {
          data: 'planDate',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.planDate,
          searchable: true,
        },
        {
          data: 'port',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.port,
          searchable: true,
        },
        {
          data: 'shift',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.shift || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.version || '',
          searchable: true,
        },
        {
          data: 'bibNotes',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params?.status === DATASOURCE_VALUE.waitingApproval || params?.status >= 20 ? true : false,
            value: '',
          },
          searchValue: params.status,
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params.dataLength ? params.dataLength : -1,
      order: [
        {
          column: 7,
          dir: 'desc',
        },
      ],
      search: {
        regex: false,
        value: params.query || '',
      },
      start: 0,
    });
    return response?.data;
  }
);

export const getPortCrusherDetail = createAsyncThunk(
  'portCrusherDetail/get',
  async (params) => {
    const response = await get(apiUrls.planRom.concat(`/${params}`));

    const sortDataByTrainLoadingStation = (data) => {
      if (!data?.planList) return data;

      return data?.planList?.sort((a, b) => {
        const trainLoadingStationA = a.romTrainLoadingStation?.rom?.trainLoadingStation;
        const trainLoadingStationB = b.romTrainLoadingStation?.rom?.trainLoadingStation;
        return trainLoadingStationA.localeCompare(trainLoadingStationB);
      });
    };

    console.log('😒 ♾️ file: reducer.js:171 ♾️ sortDataByTrainLoadingStation(response?.data):', sortDataByTrainLoadingStation(response?.data));

    return {
      ...response?.data,
      planList: sortDataByTrainLoadingStation(response?.data) || [],
    };
  }
);

export const getLatestPortCrusher = createAsyncThunk(
  'portCrusher/getLatestByPortCode',
  async (params) => {
    const response = await get(apiUrls.planRom.concat(`/port-code/${params}`));
    return response?.data;
  }
);


const mapConfCrusher = (data) => {
  if (!data) return [];

 const tempData = data
    .map((item) => {
      return {
        romTrainLoadingStation: item,
        romActivity: {
          running: 0,
          idle: 0,
          shutdown: 12,
          breakdown: 0,
        },
        performanceTarget: {
          eu: item?.bib?.eu || item?.rom?.bib?.eu || item.performanceTarget?.eu || 0,
          ma: item?.bib?.ma || item?.rom?.bib?.ma || item.performanceTarget?.ma || 0,
          pa: item?.bib?.pa || item?.rom?.bib?.pa || item.performanceTarget?.pa || 0,
          ua: item?.bib?.ua || item?.rom?.bib?.ua || item.performanceTarget?.ua || 0,
        },
        production: {
          productivityTarget: item.productivityTarget,
          total: 0,
        },
        bibNotes: '',
        TEST: 'Test'
      };
    })
    .sort((a, b) =>
      (a?.rom?.rom).localeCompare(
        b?.rom?.rom
      )
  );

  console.log('😒 ♾️ file: reducer.js:228 ♾️ tempData:', tempData)
  return tempData;
}


export const getConfCrusherMaster = createAsyncThunk(
  'romPlan/rom',
  async (params) => {
    const response = await get(`${apiUrls.configRom}/rom`);
    console.log('😒 ♾️ file: reducer.js:228 ♾️ response.data:', response.data);
    return (response.data);
  }
);

export const getCrusherSummary = createAsyncThunk(
  'romPlan/summary',
  async (params) => {
    const { date, portId, shift } = params;
    const response = await get(
      `${apiUrls.planRom}/summary?date=${date}&portId=${portId}&shift=${shift}`
    );
    return response?.data;
  }
);

export const getVersionList = createAsyncThunk(
  'romPlan/versionList',
  async (param) => {
    const { reportId } = param;
    const response = await get(
      `${apiUrls.planRom}/${reportId}/versions`
    );
    return response.data;
  }
);

export const setActivatedVersion = createAsyncThunk(
  'romPlan/setActivatedVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await patch(
      `${apiUrls.planRom}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

export const getByVersion = createAsyncThunk(
  'romPlan/getByVersion',
  async (param) => {
    const { reportId, version } = param;
    const response = await get(
      `${apiUrls.planRom}/${reportId}/versions/${version}`
    );
    return response.data;
  }
);

export const intervensiCrusherPlan = createAsyncThunk(
  'romPlan/intervensi',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.portPlanIntervensi}/user-role?moduleName=Crushing`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const portCrusher = createSlice({
  name: 'ROM_PLAN',
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confCrusherPlantMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        payload.value
      );
    },
    onChangeTableCrusherActivityInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confCrusherPlantMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onChangeTableInputDetailCrushing: (state, action) => {
      const payload = action.payload;
      const intData = payload.int != undefined ? payload.int : true;

      if (intData) {
        _.set(
          state.portCrusherDetail?.records?.planList[payload.tableIndexRow],
          payload.tableRowName,
          parseInt(payload.value)
        );
      } else {
        _.set(
          state.portCrusherDetail?.records?.planList[payload.tableIndexRow],
          payload.tableRowName,
          payload.value
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPortCrusherData.pending, (state, action) => {
      state.portCrusher.loading = true;
    });
    builder.addCase(getPortCrusherData.fulfilled, (state, action) => {
      state.portCrusher.loading = false;
      state.portCrusher.records = action.payload.list;
      state.portCrusher.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getPortCrusherData.rejected, (state, action) => {
      state.portCrusher.loading = false;
      state.portCrusher.error = 'Invalid get data';
    });
    builder.addCase(getConfCrusherMaster.pending, (state, action) => {
      state.confCrusherPlantMaster.loading = true;
    });
    builder.addCase(getConfCrusherMaster.fulfilled, (state, action) => {
      state.confCrusherPlantMaster.records = mapConfCrusher(action.payload);
      console.log('😒 ♾️ file: reducer.js:339 ♾️ getConfCrusherMaster:', {
        default: action.payload,
        map: mapConfCrusher(action.payload),
        currentState: current(state).confCrusherPlantMaster.records
      });
    });
    builder.addCase(getConfCrusherMaster.rejected, (state, action) => {
      state.confCrusherPlantMaster.loading = false;
      state.confCrusherPlantMaster.error = 'Invalid get data';
    });
    builder.addCase(getPortCrusherDetail.pending, (state, action) => {
      state.portCrusherDetail.loading = true;
    });
    builder.addCase(getPortCrusherDetail.fulfilled, (state, action) => {
      state.portCrusherDetail.records = action.payload;
      console.log('😒 ♾️ file: reducer.js:343 ♾️ getPortCrusherDetail:', action.payload);
    });
    builder.addCase(getPortCrusherDetail.rejected, (state, action) => {
      state.portCrusherDetail.loading = false;
      state.portCrusherDetail.error = 'Invalid get data';
    });
    builder.addCase(getCrusherSummary.fulfilled, (state, action) => {
      state.crusherSummary.records = action.payload;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });

    // builder.addCase(getLatestPortCrusher.pending, (state, action) => {
    //   state.confCrusherPlantMaster.loading = true;
    // });
    // builder.addCase(getLatestPortCrusher.fulfilled, (state, action) => {
    //   if (action.payload?.id !== null && action.payload?.planList !== null) {
    //     console.log('😒 ♾️ file: reducer.js:373 ♾️ action.payload?.planList:', action.payload?.planList);
    //     state.confCrusherPlantMaster.records = action.payload?.planList;
    //   }
    // });
    // builder.addCase(getLatestPortCrusher.rejected, (state, action) => {
    //   state.confCrusherPlantMaster.loading = false;
    //   state.confCrusherPlantMaster.error = 'Invalid get data';
    // });
  },
});
export const {
  onChangeTableInput,
  onChangeTableInputDetailCrushing,
  onChangeTableCrusherActivityInput,
} = portCrusher.actions;
export const portCrusherDataSelector = (state) =>
  state.romPlan.portCrusher;
export const portCrusherDetailSelector = (state) =>
  state.romPlan.portCrusherDetail;
export const confCrusherPlantMaster = (state) =>
  state.romPlan.confCrusherPlantMaster;
export const crusherSummary = (state) => state.romPlan.crusherSummary;
export const crusherVersionListSelector = (state) =>
  state.romPlan.versionList;

export default portCrusher.reducer;
