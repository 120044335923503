import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, get, post, put } from 'utils/request';
import moment from 'moment';
import { toast } from 'react-toastify';
import { thousandSeparator } from 'utils/helper';

export const initialState = {
  selectedDate: "",
  mdPit: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdContractor: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdPort: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdRom: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdFloatingCrane: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  productionList: {
    records: {},
    loading: false,
    error: null,
  },
  pitToPortList: {
    records: [],
    loading: false,
    error: null,
  },
  pitToRomList: {
    records: [],
    loading: false,
    error: null,
  },
  romToPortList: {
    records: [],
    loading: false,
    error: null,
  },
  coalToMineList: {
    records: [],
    loading: false,
    error: null,
  },
  fleetFromPitList: {
    records: [],
    loading: false,
    error: null,
  },
  haulingFleet: {
    records: null,
    loading: false,
    error: null,
  },
  planCoalFleet: {
    records: [],
    loading: false,
    error: null,
  },
  stockpileList: {
    records: [],
    loading: false,
    error: null,
  },
  stockpileRomList: {
    records: [],
    loading: false,
    error: null,
  },
  stockpilePortList: {
    records: [],
    loading: false,
    error: null,
  },
  // weather
  weatherActual: {
    records: {},
    loading: false,
    error: null
  },
  weatherActualList: {
    records: [],
    loading: false,
    error: null
  },
  weatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  fileUpload: {
    records: {},
    loading: false,
    error: null
  },
  weatherForecastBeforeReportDate: {
    records: null,
    loading: false,
    error: null
  },
  weatherForecast: {
    records: null,
    loading: false,
    error: null
  },
  bargingList: {
    records: {},
    loading: false,
    error: null,
  },
  bargingPlan: {
    records: null,
    loading: false,
    error: null,
  },
  bargingPlanDetail: {
    records: [],
    loading: false,
    error: null,
  },
  bargingActual: {
    records: null,
    loading: false,
    error: null,
  },
  bargingActualDetail: {
    records: [],
    loading: false,
    error: null,
  },
  // barging status and load progress
  bargingStatus: {
    records: null,
    loading: false,
    error: null,
  },
  bargingProgressLoad: {
    records: [],
    loading: false,
    error: null,
  },
  // barging forecast
  bargingForecast: {
    records: [],
    loading: false,
    error: null,
  },

  // transshipment
  transshipmentList: {
    records: {},
    loading: false,
    error: null,
  },
  // transshipment actual
  transshipmentActualMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualInRangeMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualInRangeYearMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualBeforeMTDBLYTDBL: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualBefore: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActual: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualAfter: {
    records: null,
    loading: true,
    error: null,
  },
  transshipmentActualInRange: {
    records: [],
    loading: true,
    error: null,
  },
  transshipmentActualDetailBefore: {
    records: [],
    loading: true,
    error: null,
  },
  transshipmentActualDetail: {
    records: [],
    loading: true,
    error: null,
  },
  transshipmentActualDetailAfter: {
    records: [],
    loading: true,
    error: null,
  },
  // transshipment today
  transshipmentNextList: {
    records: [],
    loading: true,
    error: null,
  },
  // transshipment floating crane
  transshipmentFloatingCraneList: {
    records: [],
    loading: true,
    error: null,
  },
  // transshipment forecast
  transshipmentForecast: {
    records: [],
    loading: true,
    error: null,
  },

  // SUMMARY
  // weather
  summaryCoalChain: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryWeatherForecastBeforeReportDate: {
    records: null,
    loading: false,
    error: null
  },
  summaryWeatherForecast: {
    records: null,
    loading: false,
    error: null
  },
  summaryCoalToMine: {
    records: [],
    loading: false,
    error: null
  },
  summaryPitToRom: {
    records: [],
    loading: false,
    error: null
  },
  summaryPitToPort: {
    records: [],
    loading: false,
    error: null
  },
  summaryRomToPort: {
    records: [],
    loading: false,
    error: null
  },
  fleetFromPit: {
    records: [],
    loading: false,
    error: null
  },
  summaryFleetFromPit: {
    records: [],
    loading: false,
    error: null
  },
  summaryHaulingFleet: {
    records: null,
    loading: false,
    error: null
  },
  summaryPlanCoalFleet: {
    records: null,
    loading: false,
    error: null
  },
  summaryRomStockpile: {
    records: [],
    loading: false,
    error: null
  },
  summaryPortStockpile: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeStatus: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargePlan: {
    records: null,
    loading: false,
    error: null
  },
  summaryBargePlanDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryBargeLoadProgress: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentActual: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentNext: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentNextDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentFloatingCrane: {
    records: null,
    loading: false,
    error: null
  },
  summaryTransshipmentFloatingCraneDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentForecast: {
    records: [],
    loading: false,
    error: null
  },
  summaryTransshipmentForecastDetail: {
    records: [],
    loading: false,
    error: null
  },

  // mtd
  summaryMTDWeatherActual: {
    records: [],
    loading: false,
    error: null
  },
  summaryMTDWeatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  summaryMTDWeatherForecast: {
    records: [],
    loading: false,
    error: null
  },
  report: {
    records: [],
    loading: false,
    error: null
  },
  // END SUMMARY
}

export const yesterdayDate = moment().subtract(1, 'days').format("YYYY-MM-DD");

const handleErrorMessage = (msg) => {
  return toast.error(msg, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export const getMDPit = createAsyncThunk(
  'executive-report/activity/production/pit/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/pit`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDContractor = createAsyncThunk(
  'executive-report/activity/production/contractor/list',
  async (param) => {
    const { location_id } = param
    const response = await get(`${apiUrls.executiveReport}/masterData/contractor?${location_id ? 'location_id=' + location_id : ''}`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDPort = createAsyncThunk(
  'executive-report/activity/production/port/list',
  async (param) => {
    const { contractor_id } = param
    const response = await get(`${apiUrls.executiveReport}/masterData/port?${contractor_id ? 'contractor_id=' + contractor_id : ''}`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDRom = createAsyncThunk(
  'executive-report/activity/production/rom/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/rom`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });

    return response?.data;
  }
);

export const getMDFloatingCrane = createAsyncThunk(
  'executive-report/activity/production/floating-crane/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/floating-crane`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });

    return response?.data;
  }
);

export const getProductionList = createAsyncThunk(
  'executive-report/activity/production/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/production?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const getPitToPortList = createAsyncThunk(
  'executive-report/activity/production/pit-to-port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/pit-to-port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreatePitToPort = createAsyncThunk(
  'executive-report/activity/production/pit-to-port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePitToPort = createAsyncThunk(
  'executive-report/activity/production/pit-to-port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/pit-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeletePitToPort = createAsyncThunk(
  'executive-report/activity/production/pit-to-port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getPitToRomList = createAsyncThunk(
  'executive-report/activity/production/pit-to-rom',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/pit-to-rom?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);


export const handleCreatePitToRom = createAsyncThunk(
  'executive-report/activity/production/pit-to-rom/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePitToRom = createAsyncThunk(
  'executive-report/activity/production/pit-to-rom/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/pit-to-rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeletePitToRom = createAsyncThunk(
  'executive-report/activity/production/pit-to-rom/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-rom/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getRomToPortList = createAsyncThunk(
  'executive-report/activity/production/rom-to-port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/rom-to-port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);


export const handleCreateRomToPort = createAsyncThunk(
  'executive-report/activity/production/rom-to-port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/rom-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateRomToPort = createAsyncThunk(
  'executive-report/activity/production/rom-to-port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/rom-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteRomToPort = createAsyncThunk(
  'executive-report/activity/production/rom-to-port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/rom-to-port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getCoalToMineList = createAsyncThunk(
  'executive-report/activity/production/coal-to-mine',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/coal-to-mine?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateCoalToMine = createAsyncThunk(
  'executive-report/activity/production/coal-to-mine/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/coal-to-mine`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateCoalToMine = createAsyncThunk(
  'executive-report/activity/production/coal-to-mine/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/coal-to-mine`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteCoalToMine = createAsyncThunk(
  'executive-report/activity/production/coal-to-mine/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/coal-to-mine/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);


// actual coal fleet
// fleet from pit
export const getFleetFromPitList = createAsyncThunk(
  'executive-report/activity/production/fleet-from-pit',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/fleet-from-pit?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateFleetFromPit = createAsyncThunk(
  'executive-report/activity/production/fleet-from-pit/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/fleet-from-pit`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateFleetFromPit = createAsyncThunk(
  'executive-report/activity/production/fleet-from-pit/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/fleet-from-pit`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteFleetFromPit = createAsyncThunk(
  'executive-report/activity/production/fleet-from-pit/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/fleet-from-pit/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);
// hauling fleet
export const getHaulingFleet = createAsyncThunk(
  'executive-report/activity/production/hauling-fleet',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/hauling-fleet?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateHaulingFleet = createAsyncThunk(
  'executive-report/activity/production/hauling-fleet/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/hauling-fleet`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateHaulingFleet = createAsyncThunk(
  'executive-report/activity/production/hauling-fleet/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/production/hauling-fleet/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteHaulingFleet = createAsyncThunk(
  'executive-report/activity/production/hauling-fleet/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/hauling-fleet/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// plan coal fleet
export const getPlanCoalFleet = createAsyncThunk(
  'executive-report/activity/production/plan-coal-fleet/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/plan-coal-fleet?worked_at=${param?.date ?? yesterdayDate}`);
    return response?.data;
  }
);

export const handleCreatePlanCoalFleet = createAsyncThunk(
  'executive-report/activity/production/plan-coal-fleet/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/plan-coal-fleet`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePlanCoalFleet = createAsyncThunk(
  'executive-report/activity/production/plan-coal-fleet/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/production/plan-coal-fleet/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);


export const getStockpileList = createAsyncThunk(
  'executive-report/activity/stockpile/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile?worked_at=${param?.date ?? yesterdayDate}`);
    return response?.data;
  }
);

export const getStockpileRomList = createAsyncThunk(
  'executive-report/activity/stockpile/rom',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile/rom?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateStockpileRom = createAsyncThunk(
  'executive-report/activity/stockpile/rom/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateStockpileRom = createAsyncThunk(
  'executive-report/activity/stockpile/rom/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/stockpile/rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteStockpileRom = createAsyncThunk(
  'executive-report/activity/stockpile/rom/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/rom/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getStockpilePortList = createAsyncThunk(
  'executive-report/activity/stockpile/port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile/port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateStockpilePort = createAsyncThunk(
  'executive-report/activity/stockpile/port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateStockpilePort = createAsyncThunk(
  'executive-report/activity/stockpile/port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/stockpile/port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteStockpilePort = createAsyncThunk(
  'executive-report/activity/stockpile/port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);
// Barging
export const getBargingList = createAsyncThunk(
  'executive-report/activity/barging/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

// Barging plan
export const getBargingPlan = createAsyncThunk(
  'executive-report/activity/barging/plan',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging/plan?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingPlan = createAsyncThunk(
  'executive-report/activity/barging/plan/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/plan`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingPlan = createAsyncThunk(
  'executive-report/activity/barging/plan/update',
  (param) => {
    const { object, plan_id } = param
    if (object.length < 1 || !plan_id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/plan/${plan_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getBargingPlanDetailList = createAsyncThunk(
  'executive-report/activity/barging/plan-detail',
  async (param) => {
    const { plan_id } = param
    const response = await get(`${apiUrls.executiveReport}/barging/plan-detail?plan_id=${plan_id}`);

    return response?.data;
  }
);

export const handleCreateBargingPlanDetail = createAsyncThunk(
  'executive-report/activity/barging/plan-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/plan-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingPlanDetail = createAsyncThunk(
  'executive-report/activity/barging/plan-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/plan-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging actual
export const getBargingActual = createAsyncThunk(
  'executive-report/activity/barging/actual',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingActual = createAsyncThunk(
  'executive-report/activity/barging/actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingActual = createAsyncThunk(
  'executive-report/activity/barging/actual/update',
  (param) => {
    const { object, actual_id } = param
    if (object.length < 1 || !actual_id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/actual/${actual_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getBargingActualDetailList = createAsyncThunk(
  'executive-report/activity/barging/actual-detail',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/barging/actual-detail?actual_id=${actual_id}`);

    return response?.data;
  }
);

export const handleCreateBargingActualDetail = createAsyncThunk(
  'executive-report/activity/barging/actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingActualDetail = createAsyncThunk(
  'executive-report/activity/barging/actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging status
export const getBargingStatusList = createAsyncThunk(
  'executive-report/activity/barging/barge-status',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/barge-status?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingStatus = createAsyncThunk(
  'executive-report/activity/barging/barge-status/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/barge-status`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingStatus = createAsyncThunk(
  'executive-report/activity/barging/barge-status/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/barge-status/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging load progress
export const getBargingProgressLoadList = createAsyncThunk(
  'executive-report/activity/barging/barge-progress-load',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/barge-progress-load?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingProgressLoad = createAsyncThunk(
  'executive-report/activity/barging/barge-progress-load/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/barge-progress-load`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingProgressLoad = createAsyncThunk(
  'executive-report/activity/barging/barge-progress-load/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/barge-progress-load`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging forecast
export const getBargingForecastList = createAsyncThunk(
  'executive-report/activity/barging/forecast',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging/forecast?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingForecast = createAsyncThunk(
  'executive-report/activity/barging/forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingForecast = createAsyncThunk(
  'executive-report/activity/barging/forecast/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteBargingForecast = createAsyncThunk(
  'executive-report/activity/barging/forecast/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/forecast/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);


// weather
export const getWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual/${params.id}`);

    return response?.data;
  }
);

export const getWeatherActualList = createAsyncThunk(
  'executive-report/activity/production/weather-actual-list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/update',
  (param) => {
    const { id, object } = param
    if (!id || !object) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-actual/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getWeatherActualDetailList = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail',
  async (params) => {
    const { weather_actual_id } = params
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual-detail?weather_actual_id=${weather_actual_id}`);

    return response?.data;
  }
);

export const handleCreateWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual-detail/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleFileUpload = createAsyncThunk(
  'executive-report/activity/file/upload',
  async (param) => {
    if (!param) return;
    const requestOptions = {
      method: "POST",
      body: param,
      'Content-Type': 'multipart/form-data'
    };
    try {
      const fetchResponse = await fetch(`${apiUrls.executiveReport}/file/upload`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          return result
        })
        .catch((e) => {
          return e;
        });
      return fetchResponse;
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getWeatherForecastBeforeReportDate = createAsyncThunk(
  'executive-report/activity/production/weather-forecast/before-report-date',
  async (param) => {
    let dt = new Date(param?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(1, 'days').format("YYYY-MM-DD");

    const response = await get(`${apiUrls.executiveReport}/production/weather-forecast?worked_at=${newDt}`);

    return response?.data;
  }
);

export const getWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-forecast?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast/update',
  (param) => {
    const { id, object } = param
    if (!id || !object) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-forecast/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);


// Transshipment
export const getTransshipmentList = createAsyncThunk(
  'executive-report/activity/transshipment/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

// transshipment actual
export const getTransshipmentActualMTDBLYTDBL = createAsyncThunk(
  'executive-report/activity/transshipment/actual/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActualInRangeMTDBLYTDBL = createAsyncThunk(
  'executive-report/activity/transshipment/actual-in-range/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActualInRangeYearMTDBLYTDBL = createAsyncThunk(
  'executive-report/activity/transshipment/actual-in-range-year/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActualBeforeReportDateMTDBLYTDBL = createAsyncThunk(
  'executive-report/activity/transshipment/actual-before-report-date/mtdbl-ytdbl',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual/mtdbl-ytdbl?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActual = createAsyncThunk(
  'executive-report/activity/transshipment/actual',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActualInRange = createAsyncThunk(
  'executive-report/activity/transshipment/actual-in-range',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
// get yeserday report data
export const getTransshipmentActualBeforeReportDate = createAsyncThunk(
  'executive-report/activity/transshipment/actual/before',
  async (param) => {
    let dt = new Date(param?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(1, 'days').format("YYYY-MM-DD");

    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?worked_at=${newDt}`);

    return response?.data;
  }
);
// get tomorrow report data
export const getTransshipmentActualAfterReportDate = createAsyncThunk(
  'executive-report/activity/transshipment/actual/after',
  async (param) => {
    let dt = new Date(param?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(-1, 'days').format("YYYY-MM-DD");

    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?sort_by=id&sort_mode=desc&worked_at=${newDt}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentActual = createAsyncThunk(
  'executive-report/activity/transshipment/actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentActual = createAsyncThunk(
  'executive-report/activity/transshipment/actual/update',
  (param) => {
    const { object, actual_id } = param
    if (object.length < 1 || !actual_id) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/actual/${actual_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentActual = createAsyncThunk(
  'executive-report/activity/transshipment/actual/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getTransshipmentActualDetailListBeforeReportDate = createAsyncThunk(
  'executive-report/activity/transshipment/actual-detail/before',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual-detail?transshipment_actual_id=${actual_id}`);

    return response?.data;
  }
);


export const getTransshipmentActualDetailList = createAsyncThunk(
  'executive-report/activity/transshipment/actual-detail',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual-detail?transshipment_actual_id=${actual_id}`);

    return response?.data;
  }
);

export const getTransshipmentActualDetailListAfterReportDate = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail/after',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual-detail?transshipment_actual_id=${actual_id}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentActualDetail = createAsyncThunk(
  'executive-report/activity/transshipment/actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentActualDetail = createAsyncThunk(
  'executive-report/activity/transshipment/actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentActualDetail = createAsyncThunk(
  'executive-report/activity/transshipment/actual-detail/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual-detail/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment today
export const getTransshipmentNextList = createAsyncThunk(
  'executive-report/activity/transshipment/next',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/next?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentNext = createAsyncThunk(
  'executive-report/activity/transshipment/next/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/next`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentNext = createAsyncThunk(
  'executive-report/activity/transshipment/next/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/next`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentNext = createAsyncThunk(
  'executive-report/activity/transshipment/next/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/next/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment floating crane
export const getTransshipmentFloatingCraneList = createAsyncThunk(
  'executive-report/activity/transshipment/floating-crane',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/floating-crane?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentFloatingCrane = createAsyncThunk(
  'executive-report/activity/transshipment/floating-crane/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/floating-crane`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentFloatingCrane = createAsyncThunk(
  'executive-report/activity/transshipment/floating-crane/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/floating-crane`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentFloatingCrane = createAsyncThunk(
  'executive-report/activity/transshipment/floating-crane/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/floating-crane/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment forecast
export const getTransshipmentForecastList = createAsyncThunk(
  'executive-report/activity/transshipment/forecast',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/forecast?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentForecast = createAsyncThunk(
  'executive-report/activity/transshipment/forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentForecast = createAsyncThunk(
  'executive-report/activity/transshipment/forecast/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentForecast = createAsyncThunk(
  'executive-report/activity/transshipment/forecast/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/forecast/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// SUMMARY
export const getSummaryCoalChain = createAsyncThunk(
  'executive-report/summary/production/coal-chain-chart',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/coal-chain-chart?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/coal-chain-chart?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// summary weather
// weather actual
export const getSummaryWeatherActual = createAsyncThunk(
  'executive-report/summary/production/weather-actual',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/weather-actual?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryWeatherActualDetail = createAsyncThunk(
  'executive-report/summary/production/weather-actual-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// weather forecast
export const getSummaryWeatherForecastBeforeReportDate = createAsyncThunk(
  'executive-report/summary/production/weather-forecast/before-report-date',
  async (params) => {
    let dt = new Date(params?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(1, 'days').format("YYYY-MM-DD");

    const response = await get(`${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=null&worked_at=${newDt}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryWeatherForecast = createAsyncThunk(
  'executive-report/summary/production/weather-forecast',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/weather-forecast?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryCoalToMine = createAsyncThunk(
  'executive-report/summary/production/coal-to-mine',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/coal-to-mine?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPitToRom = createAsyncThunk(
  'executive-report/summary/production/pit-to-rom',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/pit-to-rom?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/pit-to-rom?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPitToPort = createAsyncThunk(
  'executive-report/summary/production/pit-to-port',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/pit-to-port?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/pit-to-port?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryRomToPort = createAsyncThunk(
  'executive-report/summary/production/rom-to-port',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/rom-to-port?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/rom-to-port?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getFleetFromPit = createAsyncThunk(
  'executive-report/production/fleet-from-pit',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/fleet-from-pit?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryFleetFromPit = createAsyncThunk(
  'executive-report/summary/production/fleet-from-pit',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/fleet-from-pit?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryHaulingFleet = createAsyncThunk(
  'executive-report/summary/production/hauling-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/hauling-fleet?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPlanCoalFleet = createAsyncThunk(
  'executive-report/summary/production/plan-coal-fleet',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/production/plan-coal-fleet?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryRomStockpile = createAsyncThunk(
  'executive-report/summary/stockpile/rom',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/stockpile/rom?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryPortStockpile = createAsyncThunk(
  'executive-report/summary/stockpile/port',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/stockpile/port?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeActual = createAsyncThunk(
  'executive-report/summary/barging/actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/barging/actual?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/barging/actual?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargePlan = createAsyncThunk(
  'executive-report/summary/barging/plan',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/plan?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryBargePlanDetail = createAsyncThunk(
  'executive-report/summary/barging/plan-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/plan-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeStatus = createAsyncThunk(
  'executive-report/summary/barging/barge-status',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/barge-status?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryBargeLoadProgress = createAsyncThunk(
  'executive-report/summary/barging/barge-progress-load',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/barging/barge-progress-load?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentActual = createAsyncThunk(
  'executive-report/summary/transshipment/actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/transshipment/actual?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/transshipment/actual?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentActualDetail = createAsyncThunk(
  'executive-report/summary/transshipment/actual-detail',
  async (params) => {
    const requestUrl = (params?.start_date && params?.end_date) ?
      `${apiUrls.executiveReport}/summary/transshipment/actual-detail?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/transshipment/actual-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`;

    const response = await get(requestUrl).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentNext = createAsyncThunk(
  'executive-report/summary/transshipment/next',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/next?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentNextDetail = createAsyncThunk(
  'executive-report/summary/transshipment/next-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/next-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentFloatingCrane = createAsyncThunk(
  'executive-report/summary/transshipment/floating-crane',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/floating-crane?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentFloatingCraneDetail = createAsyncThunk(
  'executive-report/summary/transshipment/floating-crane-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/floating-crane-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryTransshipmentForecast = createAsyncThunk(
  'executive-report/summary/transshipment/forecast',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/forecast?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);
export const getSummaryTransshipmentForecastDetail = createAsyncThunk(
  'executive-report/summary/transshipment/forecast-detail',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/summary/transshipment/forecast-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// MTD
export const getSummaryMTDWeatherActual = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-actual',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual?is_published=null&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getSummaryMTDWeatherActualDetail = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-actual-detail',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/summary/production/weather-actual-detail?is_published=null&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// weather forecast
export const getSummaryMTDWeatherForecast = createAsyncThunk(
  'executive-report/summary/mtd/production/weather-forecast',
  async (params) => {
    const requestUrl = (params.start_date && params.end_date) ?
      `${apiUrls.executiveReport}/production/weather-forecast?is_published=null&start_worked_at=${params.start_date}&end_worked_at=${params.end_date}` :
      `${apiUrls.executiveReport}/production/weather-forecast?is_published=null&worked_at=${params?.date ?? yesterdayDate}`

    const response = await get(`${requestUrl}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

export const getReport = createAsyncThunk(
  'executive-report/report',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/report?report_date=${params?.date ?? yesterdayDate}`).catch((e) => {
      if (e.data.error) {
        handleErrorMessage(e.data.error);
      }
    });
    return response?.data;
  }
);

// END SUMMARY

const executiveReportSlice = createSlice({
  name: 'EXECUTIVE_REPORT_REDUCER',
  initialState,
  reducers: {
    onChangeSelectedDate(state, action) {
      const {
        date,
      } = action.payload;
      state.selectedDate = date;
    },
    onResetSelectedDate(state, action) {
      state.selectedDate = "";
    },
    onChangeStockpileRomList(state, action) {
      const {
        idItem,
        indexItem,
        updatedKey,
        updatedValue
      } = action.payload;

      const updateItem = state.stockpileRomList.records.map((item, index) => {
        if ((idItem !== undefined && item.id === idItem) || (idItem === undefined && index === indexItem)) {
          return {
            ...item,
            [updatedKey]: updatedValue
          };
        }

        return item;
      });

      console.log('😒 ♾️ file: reducer.js:1543 ♾️ onChangeStockpileRomList:', action);
      state.stockpileRomList.records = updateItem;
    },
    onChangeStockpilePortList(state, action) {
      const {
        idItem,
        indexItem,
        updatedKey,
        updatedValue
      } = action.payload;

      const updateItem = state.stockpilePortList.records.map((item, index) => {
        if ((idItem !== undefined && item.id === idItem) || (idItem === undefined && index === indexItem)) {
          return {
            ...item,
            [updatedKey]: updatedValue
          };
        }

        return item;
      });

      console.log('😒 ♾️ file: reducer.js:1547 ♾️ onChangeStockpilePortList:', action);
      state.stockpilePortList.records = updateItem;
    },
    onAddStockpileRomList(state, action) {
      const newData = {
        "rom": null,
        "upper_bed": null,
        "stock": null,
        "notes": '',
        "worked_at": yesterdayDate
      }

      state.stockpileRomList.records.push(newData);
      console.log('😒 ♾️ file: reducer.js:1551 ♾️ onAddStockpileRomList:', action);
      // state.stockpileRomList.push(action.payload);
    },
    onAddStockpilePortList(state, action) {
      const newData = {
        "calorie_type": null,
        "port": null,
        "rc": null,
        "cc": null,
        "gantungan": null,
        "notes": '',
        "worked_at": yesterdayDate
      }

      state.stockpilePortList.records.push(newData);
      console.log('😒 ♾️ file: reducer.js:1555 ♾️ onAddStockpilePortList:', action);
      // state.stockpilePortList.push(action.payload);
    },
    onDelStockpileRomList(state, action) {
      const { idItem, indexItem } = action.payload;

      console.log('😒 ♾️ file: reducer.js:1559 ♾️ onDelStockpileRomList:', action);
      state.stockpileRomList.records = state.stockpileRomList.records.filter((item, index) => (item.id !== idItem) || (index !== indexItem));
    },
    onDelStockpilePortList(state, action) {
      const { idItem, indexItem } = action.payload;

      console.log('😒 ♾️ file: reducer.js:1563 ♾️ onDelStockpilePortList:', action);
      state.stockpilePortList.records = state.stockpilePortList.records.filter((item, index) => (item.id !== idItem) || (index !== indexItem));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMDPit.pending, (state, action) => {
      state.mdPit.loading = true;
    });
    builder.addCase(getMDPit.fulfilled, (state, action) => {
      state.mdPit.records = action.payload;
      state.mdPit.loading = false;
    });
    builder.addCase(getMDPit.rejected, (state, action) => {
      state.mdPit.loading = false;
      state.mdPit.error = 'Invalid get data';
      state.mdPit.records = [];
    });
    builder.addCase(getMDContractor.pending, (state, action) => {
      state.mdContractor.loading = true;
    });
    builder.addCase(getMDContractor.fulfilled, (state, action) => {
      state.mdContractor.records = action.payload;
      state.mdContractor.loading = false;
    });
    builder.addCase(getMDContractor.rejected, (state, action) => {
      state.mdContractor.loading = false;
      state.mdContractor.error = 'Invalid get data';
      state.mdContractor.records = [];
    });
    builder.addCase(getMDPort.pending, (state, action) => {
      state.mdPort.loading = true;
    });
    builder.addCase(getMDPort.fulfilled, (state, action) => {
      state.mdPort.records = action.payload;
      state.mdPort.loading = false;
    });
    builder.addCase(getMDPort.rejected, (state, action) => {
      state.mdPort.loading = false;
      state.mdPort.error = 'Invalid get data';
      state.mdPort.records = [];
    });
    builder.addCase(getMDRom.pending, (state, action) => {
      state.mdRom.loading = true;
    });
    builder.addCase(getMDRom.fulfilled, (state, action) => {
      state.mdRom.records = action.payload;
      state.mdRom.loading = false;
    });
    builder.addCase(getMDRom.rejected, (state, action) => {
      state.mdRom.loading = false;
      state.mdRom.error = 'Invalid get data';
      state.mdRom.records = [];
    });
    builder.addCase(getMDFloatingCrane.pending, (state, action) => {
      state.mdFloatingCrane.loading = true;
    });
    builder.addCase(getMDFloatingCrane.fulfilled, (state, action) => {
      state.mdFloatingCrane.records = action.payload;
      state.mdFloatingCrane.loading = false;
    });
    builder.addCase(getMDFloatingCrane.rejected, (state, action) => {
      state.mdFloatingCrane.loading = false;
      state.mdFloatingCrane.error = 'Invalid get data';
      state.mdFloatingCrane.records = [];
    });
    builder.addCase(getProductionList.pending, (state, action) => {
      state.productionList.loading = true;
    });
    builder.addCase(getProductionList.fulfilled, (state, action) => {
      state.productionList.records = action.payload;
      state.productionList.loading = false;
    });
    builder.addCase(getProductionList.rejected, (state, action) => {
      state.productionList.loading = false;
    });
    builder.addCase(getPitToPortList.pending, (state, action) => {
      state.pitToPortList.loading = true;
    });
    builder.addCase(getPitToPortList.fulfilled, (state, action) => {
      const ptpList = action.payload || [];
      let pitcontractorUnique = [];
      ptpList.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].port.push({
            id: v.id,
            port: v.port,
            tonase: thousandSeparator(v.tonase)
          })
        } else {
          pitcontractorUnique.push({
            pit: v.pit,
            contractor: v.contractor,
            port: [
              {
                id: v.id,
                port: v.port,
                tonase: thousandSeparator(v.tonase)
              }
            ]
          });
        }
      });
      state.pitToPortList.records = pitcontractorUnique;
      state.pitToPortList.loading = false;
    });
    builder.addCase(getPitToPortList.rejected, (state, action) => {
      state.pitToPortList.loading = false;
    });

    builder.addCase(getPitToRomList.pending, (state, action) => {
      state.pitToRomList.loading = true;
    });
    builder.addCase(getPitToRomList.fulfilled, (state, action) => {
      const ptr = action.payload || [];
      let pitcontractorUnique = [];
      ptr.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].rom.push({
            id: v.id,
            rom: v.rom,
            tonase: thousandSeparator(v.tonase)
          })
        } else {
          pitcontractorUnique.push({
            pit: v.pit,
            contractor: v.contractor,
            rom: [
              {
                id: v.id,
                rom: v.rom,
                tonase: thousandSeparator(v.tonase)
              }
            ]
          });
        }
      });
      state.pitToRomList.records = pitcontractorUnique;
      state.pitToRomList.loading = false;
    });
    builder.addCase(getPitToRomList.rejected, (state, action) => {
      state.pitToRomList.loading = false;
    });

    builder.addCase(getRomToPortList.pending, (state, action) => {
      state.romToPortList.loading = true;
    });
    builder.addCase(getRomToPortList.fulfilled, (state, action) => {
      const ptr = action.payload || [];
      let romToContractorUnique = [];
      ptr.forEach(function (v) {
        const findromToContractorUniqueIndex = romToContractorUnique.findIndex((vFind) => vFind.rom === v.rom && vFind.contractor === v.contractor)
        if (findromToContractorUniqueIndex > -1) {
          romToContractorUnique[findromToContractorUniqueIndex].port.push({
            id: v.id,
            port: v.port,
            tonase: v.tonase
          })
        } else {
          romToContractorUnique.push({
            rom: v.rom,
            contractor: v.contractor,
            port: [
              {
                id: v.id,
                port: v.port,
                tonase: v.tonase
              }
            ]
          });
        }
      });
      state.romToPortList.records = romToContractorUnique;
      state.romToPortList.loading = false;
    });
    builder.addCase(getRomToPortList.rejected, (state, action) => {
      state.romToPortList.loading = false;
    });

    builder.addCase(getCoalToMineList.pending, (state, action) => {
      state.coalToMineList.loading = true;
    });
    builder.addCase(getCoalToMineList.fulfilled, (state, action) => {
      state.coalToMineList.records = action.payload || [];
      state.coalToMineList.loading = false;
    });
    builder.addCase(getCoalToMineList.rejected, (state, action) => {
      state.coalToMineList.loading = false;
    });
    // actual coal fleet
    // fleet from pit
    builder.addCase(getFleetFromPitList.pending, (state, action) => {
      state.fleetFromPitList.loading = true;
    });
    builder.addCase(getFleetFromPitList.fulfilled, (state, action) => {
      state.fleetFromPitList.records = action.payload || null;
      state.fleetFromPitList.loading = false;
    });
    builder.addCase(getFleetFromPitList.rejected, (state, action) => {
      state.fleetFromPitList.loading = false;
    });
    // hauling fleet
    builder.addCase(getHaulingFleet.pending, (state, action) => {
      state.haulingFleet.loading = true;
    });
    builder.addCase(getHaulingFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.haulingFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.haulingFleet.loading = false;
    });
    builder.addCase(getHaulingFleet.rejected, (state, action) => {
      state.haulingFleet.loading = false;
    });
    // weather
    builder.addCase(getWeatherActual.pending, (state, action) => {
      state.weatherActual.loading = true;
    });
    builder.addCase(getWeatherActual.fulfilled, (state, action) => {
      state.weatherActual.records = action.payload || null;
      state.weatherActual.loading = false;
    });
    builder.addCase(getWeatherActual.rejected, (state, action) => {
      state.weatherActual.loading = false;
    });

    builder.addCase(getWeatherActualList.pending, (state, action) => {
      state.weatherActualList.loading = true;
    });
    builder.addCase(getWeatherActualList.fulfilled, (state, action) => {
      state.weatherActualList.records = action.payload || null;
      state.weatherActualList.loading = false;
    });
    builder.addCase(getWeatherActualList.rejected, (state, action) => {
      state.weatherActualList.loading = false;
    });
    builder.addCase(getWeatherActualDetailList.pending, (state, action) => {
      state.weatherActualDetail.loading = true;
    });
    builder.addCase(getWeatherActualDetailList.fulfilled, (state, action) => {
      const dataList = action.payload || [];
      let pitcontractorUnique = [];
      dataList.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].weather.push({
            id: v.id,
            rain_start: v.rain_start ? new Date(v.rain_start) : null,
            rain_end: v.rain_end ? new Date(v.rain_end) : null,
            slippery_start: v.slippery_start ? new Date(v.slippery_start) : null,
            slippery_end: v.slippery_end ? new Date(v.slippery_end) : null
          })
        } else {
          pitcontractorUnique.push({
            id: v.id || "",
            pit: v.pit || "",
            contractor: v.contractor || "",
            weather: [
              {
                id: v.id,
                rain_start: v.rain_start ? new Date(v.rain_start) : null,
                rain_end: v.rain_end ? new Date(v.rain_end) : null,
                slippery_start: v.slippery_start ? new Date(v.slippery_start) : null,
                slippery_end: v.slippery_end ? new Date(v.slippery_end) : null
              }
            ]
          });
        }
      });
      state.weatherActualDetail.records = pitcontractorUnique;
      state.weatherActualDetail.loading = false;
    });
    builder.addCase(getWeatherActualDetailList.rejected, (state, action) => {
      state.weatherActualDetail.loading = false;
    });
    // plan coal fleet
    builder.addCase(getPlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(getPlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action.payload.length < 1 ? [] : action.payload[action.payload.length - 1];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(getPlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleCreatePlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(handleCreatePlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action?.payload?.data || [];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleCreatePlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleUpdatePlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(handleUpdatePlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action?.payload?.data || [];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleUpdatePlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });

    // stockpile
    builder.addCase(getStockpileList.pending, (state, action) => {
      state.stockpileList.loading = true;
    });
    builder.addCase(getStockpileList.fulfilled, (state, action) => {
      state.stockpileList.records = action.payload;
      state.stockpileList.loading = false;
    });
    builder.addCase(getStockpileList.rejected, (state, action) => {
      state.stockpileList.loading = false;
    });

    builder.addCase(getStockpileRomList.pending, (state, action) => {
      state.stockpileRomList.loading = true;
    });
    builder.addCase(getStockpileRomList.fulfilled, (state, action) => {
      state.stockpileRomList.records = action.payload || [];
      state.stockpileRomList.loading = false;
    });
    builder.addCase(getStockpileRomList.rejected, (state, action) => {
      state.stockpileRomList.loading = false;
    });

    builder.addCase(getStockpilePortList.pending, (state, action) => {
      state.stockpilePortList.loading = true;
    });
    builder.addCase(getStockpilePortList.fulfilled, (state, action) => {
      state.stockpilePortList.records = action.payload || [];
      state.stockpilePortList.loading = false;
    });
    builder.addCase(getStockpilePortList.rejected, (state, action) => {
      state.stockpilePortList.loading = false;
    });

    // file upload
    builder.addCase(handleFileUpload.pending, (state, action) => {
      state.fileUpload.loading = true;
    });
    builder.addCase(handleFileUpload.fulfilled, (state, action) => {
      state.fileUpload.records = action.payload || null;
      state.fileUpload.loading = false;
    });
    builder.addCase(handleFileUpload.rejected, (state, action) => {
      state.fileUpload.loading = false;
    });

    // WEATHER FORECAST
    builder.addCase(getWeatherForecastBeforeReportDate.pending, (state, action) => {
      state.weatherForecastBeforeReportDate.loading = true;
    });
    builder.addCase(getWeatherForecastBeforeReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.weatherForecastBeforeReportDate.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.weatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getWeatherForecastBeforeReportDate.rejected, (state, action) => {
      state.weatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getWeatherForecast.pending, (state, action) => {
      state.weatherForecast.loading = true;
    });
    builder.addCase(getWeatherForecast.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.weatherForecast.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.weatherForecast.loading = false;
    });
    builder.addCase(getWeatherForecast.rejected, (state, action) => {
      state.weatherForecast.loading = false;
    });

    // barging
    builder.addCase(getBargingList.pending, (state, action) => {
      state.bargingList.loading = true;
    });
    builder.addCase(getBargingList.fulfilled, (state, action) => {
      state.bargingList.records = action.payload;
      state.bargingList.loading = false;
    });
    builder.addCase(getBargingList.rejected, (state, action) => {
      state.bargingList.loading = false;
    });
    // barging plan
    builder.addCase(getBargingPlan.pending, (state, action) => {
      state.bargingPlan.loading = true;
    });
    builder.addCase(getBargingPlan.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingPlan.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingPlan.loading = false;
    });
    builder.addCase(getBargingPlan.rejected, (state, action) => {
      state.bargingPlan.loading = false;
    });
    builder.addCase(getBargingPlanDetailList.pending, (state, action) => {
      state.bargingPlanDetail.loading = true;
    });
    builder.addCase(getBargingPlanDetailList.fulfilled, (state, action) => {
      state.bargingPlanDetail.records = action.payload;
      state.bargingPlanDetail.loading = false;
    });
    builder.addCase(getBargingPlanDetailList.rejected, (state, action) => {
      state.bargingPlanDetail.loading = false;
    });
    // barging actual
    builder.addCase(getBargingActual.pending, (state, action) => {
      state.bargingActual.loading = true;
    });
    builder.addCase(getBargingActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingActual.loading = false;
    });
    builder.addCase(getBargingActual.rejected, (state, action) => {
      state.bargingActual.loading = false;
    });
    builder.addCase(getBargingActualDetailList.pending, (state, action) => {
      state.bargingActualDetail.loading = true;
    });
    builder.addCase(getBargingActualDetailList.fulfilled, (state, action) => {
      state.bargingActualDetail.records = action.payload;
      state.bargingActualDetail.loading = false;
    });
    builder.addCase(getBargingActualDetailList.rejected, (state, action) => {
      state.bargingActualDetail.loading = false;
    });

    // barging status and load progress
    builder.addCase(getBargingStatusList.pending, (state, action) => {
      state.bargingStatus.loading = true;
    });
    builder.addCase(getBargingStatusList.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingStatus.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingStatus.loading = false;
    });
    builder.addCase(getBargingStatusList.rejected, (state, action) => {
      state.bargingStatus.loading = false;
    });
    builder.addCase(getBargingProgressLoadList.pending, (state, action) => {
      state.bargingProgressLoad.loading = true;
    });
    builder.addCase(getBargingProgressLoadList.fulfilled, (state, action) => {
      state.bargingProgressLoad.records = action.payload;
      state.bargingProgressLoad.loading = false;
    });
    builder.addCase(getBargingProgressLoadList.rejected, (state, action) => {
      state.bargingProgressLoad.loading = false;
    });

    // barging forecast
    builder.addCase(getBargingForecastList.pending, (state, action) => {
      state.bargingForecast.loading = true;
    });
    builder.addCase(getBargingForecastList.fulfilled, (state, action) => {
      state.bargingForecast.records = action.payload;
      state.bargingForecast.loading = false;
    });
    builder.addCase(getBargingForecastList.rejected, (state, action) => {
      state.bargingForecast.loading = false;
    });

    // transshipment
    builder.addCase(getTransshipmentList.pending, (state, action) => {
      state.transshipmentList.loading = true;
    });
    builder.addCase(getTransshipmentList.fulfilled, (state, action) => {
      state.transshipmentList.records = action.payload;
      state.transshipmentList.loading = false;
    });
    builder.addCase(getTransshipmentList.rejected, (state, action) => {
      state.transshipmentList.loading = false;
    });
    // transshipment actual
    // MTDBL YTDBL
    builder.addCase(getTransshipmentActualMTDBLYTDBL.pending, (state, action) => {
      state.transshipmentActualMTDBLYTDBL.loading = true;
      state.transshipmentActualMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualMTDBLYTDBL.fulfilled, (state, action) => {
      state.transshipmentActualMTDBLYTDBL.records = action?.payload ?? null;
      state.transshipmentActualMTDBLYTDBL.loading = false;
    });
    builder.addCase(getTransshipmentActualMTDBLYTDBL.rejected, (state, action) => {
      state.transshipmentActualMTDBLYTDBL.loading = false;
      state.transshipmentActualMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualInRangeMTDBLYTDBL.pending, (state, action) => {
      state.transshipmentActualInRangeMTDBLYTDBL.loading = true;
      state.transshipmentActualInRangeMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualInRangeMTDBLYTDBL.fulfilled, (state, action) => {
      state.transshipmentActualInRangeMTDBLYTDBL.records = action?.payload ?? null;
      state.transshipmentActualInRangeMTDBLYTDBL.loading = false;
    });
    builder.addCase(getTransshipmentActualInRangeMTDBLYTDBL.rejected, (state, action) => {
      state.transshipmentActualInRangeMTDBLYTDBL.loading = false;
      state.transshipmentActualInRangeMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualInRangeYearMTDBLYTDBL.pending, (state, action) => {
      state.transshipmentActualInRangeYearMTDBLYTDBL.loading = true;
      state.transshipmentActualInRangeYearMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualInRangeYearMTDBLYTDBL.fulfilled, (state, action) => {
      state.transshipmentActualInRangeYearMTDBLYTDBL.records = action?.payload ?? null;
      state.transshipmentActualInRangeYearMTDBLYTDBL.loading = false;
    });
    builder.addCase(getTransshipmentActualInRangeYearMTDBLYTDBL.rejected, (state, action) => {
      state.transshipmentActualInRangeYearMTDBLYTDBL.loading = false;
      state.transshipmentActualInRangeYearMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualBeforeReportDateMTDBLYTDBL.pending, (state, action) => {
      state.transshipmentActualBeforeMTDBLYTDBL.loading = true;
      state.transshipmentActualBeforeMTDBLYTDBL.records = null;
    });
    builder.addCase(getTransshipmentActualBeforeReportDateMTDBLYTDBL.fulfilled, (state, action) => {
      state.transshipmentActualBeforeMTDBLYTDBL.records = action?.payload ?? null;
      state.transshipmentActualBeforeMTDBLYTDBL.loading = false;
    });
    builder.addCase(getTransshipmentActualBeforeReportDateMTDBLYTDBL.rejected, (state, action) => {
      state.transshipmentActualBeforeMTDBLYTDBL.loading = false;
      state.transshipmentActualBeforeMTDBLYTDBL.records = null;
    });
    // END MTDBL YTDBL
    builder.addCase(getTransshipmentActualBeforeReportDate.pending, (state, action) => {
      state.transshipmentActualBefore.loading = true;
      state.transshipmentActualBefore.records = null;
      state.transshipmentActualDetailBefore.records = [];
    });
    builder.addCase(getTransshipmentActualBeforeReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.transshipmentActualBefore.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.transshipmentActualBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualBeforeReportDate.rejected, (state, action) => {
      state.transshipmentActualBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.pending, (state, action) => {
      state.transshipmentActualDetailBefore.loading = true;
      state.transshipmentActualDetailBefore.records = [];
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.fulfilled, (state, action) => {
      state.transshipmentActualDetailBefore.records = action.payload;
      state.transshipmentActualDetailBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.rejected, (state, action) => {
      state.transshipmentActualDetailBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualAfterReportDate.pending, (state, action) => {
      state.transshipmentActualAfter.loading = true;
      state.transshipmentActualAfter.records = null;
      state.transshipmentActualDetailAfter.records = [];
    });
    builder.addCase(getTransshipmentActualAfterReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.transshipmentActualAfter.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.transshipmentActualAfter.loading = false;
    });
    builder.addCase(getTransshipmentActualAfterReportDate.rejected, (state, action) => {
      state.transshipmentActualAfter.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListAfterReportDate.pending, (state, action) => {
      state.transshipmentActualDetailAfter.loading = true;
      state.transshipmentActualDetailAfter.records = [];
    });
    builder.addCase(getTransshipmentActualDetailListAfterReportDate.fulfilled, (state, action) => {
      state.transshipmentActualDetailAfter.records = action.payload;
      state.transshipmentActualDetailAfter.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListAfterReportDate.rejected, (state, action) => {
      state.transshipmentActualDetailAfter.loading = false;
    });
    builder.addCase(getTransshipmentActual.pending, (state, action) => {
      state.transshipmentActual.loading = true;
      state.transshipmentActual.records = null;
      state.transshipmentActualDetail.records = [];
    });
    builder.addCase(getTransshipmentActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.transshipmentActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.transshipmentActual.loading = false;
    });
    builder.addCase(getTransshipmentActual.rejected, (state, action) => {
      state.transshipmentActual.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailList.pending, (state, action) => {
      state.transshipmentActualDetail.loading = true;
      state.transshipmentActualDetail.records = [];
    });
    builder.addCase(getTransshipmentActualDetailList.fulfilled, (state, action) => {
      state.transshipmentActualDetail.records = action.payload;
      state.transshipmentActualDetail.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailList.rejected, (state, action) => {
      state.transshipmentActualDetail.loading = false;
    });
    builder.addCase(getTransshipmentActualInRange.pending, (state, action) => {
      state.transshipmentActualInRange.loading = true;
      state.transshipmentActualInRange.records = [];
    });
    builder.addCase(getTransshipmentActualInRange.fulfilled, (state, action) => {
      state.transshipmentActualInRange.records = action.payload;
      state.transshipmentActualInRange.loading = false;
    });
    builder.addCase(getTransshipmentActualInRange.rejected, (state, action) => {
      state.transshipmentActualInRange.loading = false;
    });
    // transshipment today
    builder.addCase(getTransshipmentNextList.pending, (state, action) => {
      state.transshipmentNextList.loading = true;
    });
    builder.addCase(getTransshipmentNextList.fulfilled, (state, action) => {
      state.transshipmentNextList.records = action.payload;
      state.transshipmentNextList.loading = false;
    });
    builder.addCase(getTransshipmentNextList.rejected, (state, action) => {
      state.transshipmentNextList.loading = false;
    });
    // transshipment Floating Crane
    builder.addCase(getTransshipmentFloatingCraneList.pending, (state, action) => {
      state.transshipmentFloatingCraneList.loading = true;
    });
    builder.addCase(getTransshipmentFloatingCraneList.fulfilled, (state, action) => {
      state.transshipmentFloatingCraneList.records = action.payload;
      state.transshipmentFloatingCraneList.loading = false;
    });
    builder.addCase(getTransshipmentFloatingCraneList.rejected, (state, action) => {
      state.transshipmentFloatingCraneList.loading = false;
    });
    // transshipment forecast
    builder.addCase(getTransshipmentForecastList.pending, (state, action) => {
      state.transshipmentForecast.loading = true;
    });
    builder.addCase(getTransshipmentForecastList.fulfilled, (state, action) => {
      state.transshipmentForecast.records = action.payload;
      state.transshipmentForecast.loading = false;
    });
    builder.addCase(getTransshipmentForecastList.rejected, (state, action) => {
      state.transshipmentForecast.loading = false;
    });

    // SUMMARY
    builder.addCase(getSummaryCoalChain.pending, (state, action) => {
      state.summaryCoalChain.loading = true;
    });
    builder.addCase(getSummaryCoalChain.fulfilled, (state, action) => {
      state.summaryCoalChain.records = action.payload || [];
      state.summaryCoalChain.loading = false;
    });
    builder.addCase(getSummaryCoalChain.rejected, (state, action) => {
      state.summaryCoalChain.loading = false;
    });
    // weather
    // weather actual
    builder.addCase(getSummaryWeatherActual.pending, (state, action) => {
      state.summaryWeatherActual.loading = true;
    });
    builder.addCase(getSummaryWeatherActual.fulfilled, (state, action) => {
      state.summaryWeatherActual.records = action.payload || [];
      state.summaryWeatherActual.loading = false;
    });
    builder.addCase(getSummaryWeatherActual.rejected, (state, action) => {
      state.summaryWeatherActual.loading = false;
    });
    builder.addCase(getSummaryWeatherActualDetail.pending, (state, action) => {
      state.summaryWeatherActualDetail.loading = true;
    });
    builder.addCase(getSummaryWeatherActualDetail.fulfilled, (state, action) => {
      state.summaryWeatherActualDetail.records = action.payload || [];
      state.summaryWeatherActualDetail.loading = false;
    });
    builder.addCase(getSummaryWeatherActualDetail.rejected, (state, action) => {
      state.summaryWeatherActualDetail.loading = false;
    });
    // weather forecast
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.pending, (state, action) => {
      state.summaryWeatherForecastBeforeReportDate.loading = true;
    });
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryWeatherForecastBeforeReportDate.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryWeatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getSummaryWeatherForecastBeforeReportDate.rejected, (state, action) => {
      state.summaryWeatherForecastBeforeReportDate.loading = false;
    });
    builder.addCase(getSummaryWeatherForecast.pending, (state, action) => {
      state.summaryWeatherForecast.loading = true;
    });
    builder.addCase(getSummaryWeatherForecast.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryWeatherForecast.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryWeatherForecast.loading = false;
    });
    builder.addCase(getSummaryWeatherForecast.rejected, (state, action) => {
      state.summaryWeatherForecast.loading = false;
    });
    // coal to mine
    builder.addCase(getSummaryCoalToMine.pending, (state, action) => {
      state.summaryCoalToMine.loading = true;
    });
    builder.addCase(getSummaryCoalToMine.fulfilled, (state, action) => {
      state.summaryCoalToMine.records = action.payload || [];
      state.summaryCoalToMine.loading = false;
    });
    builder.addCase(getSummaryCoalToMine.rejected, (state, action) => {
      state.summaryCoalToMine.loading = false;
    });
    // pit to Rom
    builder.addCase(getSummaryPitToRom.pending, (state, action) => {
      state.summaryPitToRom.loading = true;
    });
    builder.addCase(getSummaryPitToRom.fulfilled, (state, action) => {
      state.summaryPitToRom.records = action.payload || [];
      state.summaryPitToRom.loading = false;
    });
    builder.addCase(getSummaryPitToRom.rejected, (state, action) => {
      state.summaryPitToRom.loading = false;
    });
    // pit to port
    builder.addCase(getSummaryPitToPort.pending, (state, action) => {
      state.summaryPitToPort.loading = true;
    });
    builder.addCase(getSummaryPitToPort.fulfilled, (state, action) => {
      state.summaryPitToPort.records = action.payload || [];
      state.summaryPitToPort.loading = false;
    });
    builder.addCase(getSummaryPitToPort.rejected, (state, action) => {
      state.summaryPitToPort.loading = false;
    });
    // rom to port
    builder.addCase(getSummaryRomToPort.pending, (state, action) => {
      state.summaryRomToPort.loading = true;
    });
    builder.addCase(getSummaryRomToPort.fulfilled, (state, action) => {
      state.summaryRomToPort.records = action.payload || [];
      state.summaryRomToPort.loading = false;
    });
    builder.addCase(getSummaryRomToPort.rejected, (state, action) => {
      state.summaryRomToPort.loading = false;
    });

    // Actual Coal Fleet
    // fleet from pit
    builder.addCase(getFleetFromPit.pending, (state, action) => {
      state.fleetFromPit.loading = true;
    });
    builder.addCase(getFleetFromPit.fulfilled, (state, action) => {
      state.fleetFromPit.records = action.payload || [];
      state.fleetFromPit.loading = false;
    });
    builder.addCase(getFleetFromPit.rejected, (state, action) => {
      state.fleetFromPit.loading = false;
    });
    builder.addCase(getSummaryFleetFromPit.pending, (state, action) => {
      state.summaryFleetFromPit.loading = true;
    });
    builder.addCase(getSummaryFleetFromPit.fulfilled, (state, action) => {
      state.summaryFleetFromPit.records = action.payload || [];
      state.summaryFleetFromPit.loading = false;
    });
    builder.addCase(getSummaryFleetFromPit.rejected, (state, action) => {
      state.summaryFleetFromPit.loading = false;
    });
    // hauling fleet
    builder.addCase(getSummaryHaulingFleet.pending, (state, action) => {
      state.summaryHaulingFleet.loading = true;
    });
    builder.addCase(getSummaryHaulingFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryHaulingFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryHaulingFleet.loading = false;
    });
    builder.addCase(getSummaryHaulingFleet.rejected, (state, action) => {
      state.summaryHaulingFleet.loading = false;
    });

    // plan coal fleet
    builder.addCase(getSummaryPlanCoalFleet.pending, (state, action) => {
      state.summaryPlanCoalFleet.loading = true;
    });
    builder.addCase(getSummaryPlanCoalFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryPlanCoalFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryPlanCoalFleet.loading = false;
    });
    builder.addCase(getSummaryPlanCoalFleet.rejected, (state, action) => {
      state.summaryPlanCoalFleet.loading = false;
    });
    // stockpile
    // rom stockpile
    builder.addCase(getSummaryRomStockpile.pending, (state, action) => {
      state.summaryRomStockpile.loading = true;
    });
    builder.addCase(getSummaryRomStockpile.fulfilled, (state, action) => {
      state.summaryRomStockpile.records = action.payload || [];
      state.summaryRomStockpile.loading = false;
    });
    builder.addCase(getSummaryRomStockpile.rejected, (state, action) => {
      state.summaryRomStockpile.loading = false;
    });
    // port stockpile
    builder.addCase(getSummaryPortStockpile.pending, (state, action) => {
      state.summaryPortStockpile.loading = true;
    });
    builder.addCase(getSummaryPortStockpile.fulfilled, (state, action) => {
      state.summaryPortStockpile.records = action.payload || [];
      state.summaryPortStockpile.loading = false;
    });
    builder.addCase(getSummaryPortStockpile.rejected, (state, action) => {
      state.summaryPortStockpile.loading = false;
    });
    // barge
    // barge actual
    builder.addCase(getSummaryBargeActual.pending, (state, action) => {
      state.summaryBargeActual.loading = true;
    });
    builder.addCase(getSummaryBargeActual.fulfilled, (state, action) => {
      state.summaryBargeActual.records = action.payload || [];
      state.summaryBargeActual.loading = false;
    });
    builder.addCase(getSummaryBargeActual.rejected, (state, action) => {
      state.summaryBargeActual.loading = false;
    });
    // barge plan
    builder.addCase(getSummaryBargePlan.pending, (state, action) => {
      state.summaryBargePlan.loading = true;
    });
    builder.addCase(getSummaryBargePlan.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryBargePlan.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryBargePlan.loading = false;
    });
    builder.addCase(getSummaryBargePlan.rejected, (state, action) => {
      state.summaryBargePlan.loading = false;
    });
    builder.addCase(getSummaryBargePlanDetail.pending, (state, action) => {
      state.summaryBargePlanDetail.loading = true;
    });
    builder.addCase(getSummaryBargePlanDetail.fulfilled, (state, action) => {
      state.summaryBargePlanDetail.records = action.payload || [];
      state.summaryBargePlanDetail.loading = false;
    });
    builder.addCase(getSummaryBargePlanDetail.rejected, (state, action) => {
      state.summaryBargePlanDetail.loading = false;
    });
    // barge status & load progress
    builder.addCase(getSummaryBargeStatus.pending, (state, action) => {
      state.summaryBargeStatus.loading = true;
    });
    builder.addCase(getSummaryBargeStatus.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryBargeStatus.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryBargeStatus.loading = false;
    });
    builder.addCase(getSummaryBargeStatus.rejected, (state, action) => {
      state.summaryBargeStatus.loading = false;
    });
    builder.addCase(getSummaryBargeLoadProgress.pending, (state, action) => {
      state.summaryBargeLoadProgress.loading = true;
    });
    builder.addCase(getSummaryBargeLoadProgress.fulfilled, (state, action) => {
      state.summaryBargeLoadProgress.records = action.payload || [];
      state.summaryBargeLoadProgress.loading = false;
    });
    builder.addCase(getSummaryBargeLoadProgress.rejected, (state, action) => {
      state.summaryBargeLoadProgress.loading = false;
    });
    // transshipment
    builder.addCase(getSummaryTransshipmentActual.pending, (state, action) => {
      state.summaryTransshipmentActual.loading = true;
    });
    builder.addCase(getSummaryTransshipmentActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentActual.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActual.rejected, (state, action) => {
      state.summaryTransshipmentActual.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.pending, (state, action) => {
      state.summaryTransshipmentActualDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentActualDetail.records = action.payload || [];
      state.summaryTransshipmentActualDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentActualDetail.rejected, (state, action) => {
      state.summaryTransshipmentActualDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNext.pending, (state, action) => {
      state.summaryTransshipmentNext.loading = true;
    });
    builder.addCase(getSummaryTransshipmentNext.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentNext.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentNext.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNext.rejected, (state, action) => {
      state.summaryTransshipmentNext.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.pending, (state, action) => {
      state.summaryTransshipmentNextDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentNextDetail.records = action.payload || [];
      state.summaryTransshipmentNextDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentNextDetail.rejected, (state, action) => {
      state.summaryTransshipmentNextDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.pending, (state, action) => {
      state.summaryTransshipmentFloatingCrane.loading = true;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.summaryTransshipmentFloatingCrane.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.summaryTransshipmentFloatingCrane.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCrane.rejected, (state, action) => {
      state.summaryTransshipmentFloatingCrane.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.pending, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.records = action.payload || [];
      state.summaryTransshipmentFloatingCraneDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentFloatingCraneDetail.rejected, (state, action) => {
      state.summaryTransshipmentFloatingCraneDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecast.pending, (state, action) => {
      state.summaryTransshipmentForecast.loading = true;
    });
    builder.addCase(getSummaryTransshipmentForecast.fulfilled, (state, action) => {
      state.summaryTransshipmentForecast.records = action.payload || [];
      state.summaryTransshipmentForecast.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecast.rejected, (state, action) => {
      state.summaryTransshipmentForecast.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.pending, (state, action) => {
      state.summaryTransshipmentForecastDetail.loading = true;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.fulfilled, (state, action) => {
      state.summaryTransshipmentForecastDetail.records = action.payload || [];
      state.summaryTransshipmentForecastDetail.loading = false;
    });
    builder.addCase(getSummaryTransshipmentForecastDetail.rejected, (state, action) => {
      state.summaryTransshipmentForecastDetail.loading = false;
    });

    // MTD
    builder.addCase(getSummaryMTDWeatherActual.pending, (state, action) => {
      state.summaryMTDWeatherActual.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherActual.fulfilled, (state, action) => {
      state.summaryMTDWeatherActual.records = action.payload || [];
      state.summaryMTDWeatherActual.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActual.rejected, (state, action) => {
      state.summaryMTDWeatherActual.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.pending, (state, action) => {
      state.summaryMTDWeatherActualDetail.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.fulfilled, (state, action) => {
      state.summaryMTDWeatherActualDetail.records = action.payload || [];
      state.summaryMTDWeatherActualDetail.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherActualDetail.rejected, (state, action) => {
      state.summaryMTDWeatherActualDetail.loading = false;
    });
    // weather forecast
    builder.addCase(getSummaryMTDWeatherForecast.pending, (state, action) => {
      state.summaryMTDWeatherForecast.loading = true;
    });
    builder.addCase(getSummaryMTDWeatherForecast.fulfilled, (state, action) => {
      state.summaryMTDWeatherForecast.records = action.payload || [];
      state.summaryMTDWeatherForecast.loading = false;
    });
    builder.addCase(getSummaryMTDWeatherForecast.rejected, (state, action) => {
      state.summaryMTDWeatherForecast.loading = false;
    });
    builder.addCase(getReport.pending, (state, action) => {
      state.report.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.report.records = action.payload || [];
      state.report.loading = false;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.report.loading = false;
    });
    // END SUMMARY
  },
});

export const {
  onChangeSelectedDate,
  onResetSelectedDate,
  onAddStockpileRomList,
  onDelStockpileRomList,
  onDelStockpilePortList,
  onAddStockpilePortList,
  onChangeStockpileRomList,
  onChangeStockpilePortList,
} = executiveReportSlice.actions;

export const reportDateSeelctor = (state) => state.executiveReportReducer.selectedDate;

export const productionSelector = (state) => state.executiveReportReducer.productionList;
export const pitToPortSelector = (state) => state.executiveReportReducer.pitToPortList;
export const mdPitSelector = (state) => state.executiveReportReducer.mdPit;
export const mdContractorSelector = (state) => state.executiveReportReducer.mdContractor;
export const mdPortSelector = (state) => state.executiveReportReducer.mdPort;
export const mdRomSelector = (state) => state.executiveReportReducer.mdRom;
export const mdFloatingCraneSelector = (state) => state.executiveReportReducer.mdFloatingCrane;
export const pitToRomSelector = (state) => state.executiveReportReducer.pitToRomList;
export const romToPortSelector = (state) => state.executiveReportReducer.romToPortList;
export const coalToMineSelector = (state) => state.executiveReportReducer.coalToMineList;
// actual coal fleet
// fleet from pit
export const fleetFromPitListSelector = (state) => state.executiveReportReducer.fleetFromPitList;
// hauling fleet
export const haulingFleetSelector = (state) => state.executiveReportReducer.haulingFleet;
// plan coal fleet
export const planCoalFleetSelector = (state) => state.executiveReportReducer.planCoalFleet;
// production weather
export const weatherActualSelector = (state) => state.executiveReportReducer.weatherActual;
export const weatherActualListSelector = (state) => state.executiveReportReducer.weatherActualList;
export const weatherActualDetailSelector = (state) => state.executiveReportReducer.weatherActualDetail;
export const weatherForecastBeforeReportDateSelector = (state) => state.executiveReportReducer.weatherForecastBeforeReportDate;
export const weatherForecastSelector = (state) => state.executiveReportReducer.weatherForecast;
// file upload
export const fileUploadSelector = (state) => state.executiveReportReducer.fileUpload;

// stockpile
export const stockpileSelector = (state) => state.executiveReportReducer.stockpileList;
export const stockpileRomSelector = (state) => state.executiveReportReducer.stockpileRomList;
export const stockpilePortSelector = (state) => state.executiveReportReducer.stockpilePortList;

// barging
export const bargingSelector = (state) => state.executiveReportReducer.bargingList;
export const BargingPlanSelector = (state) => state.executiveReportReducer.bargingPlan;
export const BargingPlanDetailSelector = (state) => state.executiveReportReducer.bargingPlanDetail;
export const BargingActualSelector = (state) => state.executiveReportReducer.bargingActual;
export const BargingActualDetailSelector = (state) => state.executiveReportReducer.bargingActualDetail;
export const bargingStatusSelector = (state) => state.executiveReportReducer.bargingStatus;
export const bargingProgressLoadSelector = (state) => state.executiveReportReducer.bargingProgressLoad;
export const BargingForecastSelector = (state) => state.executiveReportReducer.bargingForecast;

// transshipment
export const transshipmentSelector = (state) => state.executiveReportReducer.transshipmentList;
// transshipment actual
export const transshipmentActualMTDBLYTDBLSelector = (state) => state.executiveReportReducer.transshipmentActualMTDBLYTDBL;
export const transshipmentActualInRangeMTDBLYTDBLSelector = (state) => state.executiveReportReducer.transshipmentActualInRangeMTDBLYTDBL;
export const transshipmentActualInRangeYearMTDBLYTDBLSelector = (state) => state.executiveReportReducer.transshipmentActualInRangeYearMTDBLYTDBL;
export const transshipmentActualBeforeMTDBLYTDBLSelector = (state) => state.executiveReportReducer.transshipmentActualBeforeMTDBLYTDBL;
export const transshipmentActualBeforeSelector = (state) => state.executiveReportReducer.transshipmentActualBefore;
export const transshipmentActualSelector = (state) => state.executiveReportReducer.transshipmentActual;
export const transshipmentActualAfterSelector = (state) => state.executiveReportReducer.transshipmentActualAfter;
export const transshipmentActualInRangeSelector = (state) => state.executiveReportReducer.transshipmentActualInRange;
export const transshipmentActualDetailBeforeSelector = (state) => state.executiveReportReducer.transshipmentActualDetailBefore;
export const transshipmentActualDetailAfterSelector = (state) => state.executiveReportReducer.transshipmentActualDetailAfter;
export const transshipmentActualDetailSelector = (state) => state.executiveReportReducer.transshipmentActualDetail;
// transshipment today
export const transshipmentNextSelector = (state) => state.executiveReportReducer.transshipmentNextList;
// transshipment Floating Crane
export const transshipmentFloatingCraneSelector = (state) => state.executiveReportReducer.transshipmentFloatingCraneList;
// transshipment Forecast
export const transshipmentForecastSelector = (state) => state.executiveReportReducer.transshipmentForecast;

// SUMMARY

// coal chain chart
export const summaryCoalChainSelector = (state) => state.executiveReportReducer.summaryCoalChain;
// production weather
export const summaryWeatherActualSelector = (state) => state.executiveReportReducer.summaryWeatherActual;
export const summaryWeatherActualDetailSelector = (state) => state.executiveReportReducer.summaryWeatherActualDetail;
export const summaryWeatherForecastBeforeReportDateSelector = (state) => state.executiveReportReducer.summaryWeatherForecastBeforeReportDate;
export const summaryWeatherForecastSelector = (state) => state.executiveReportReducer.summaryWeatherForecast;
export const summaryCoalToMineSelector = (state) => state.executiveReportReducer.summaryCoalToMine;
export const summaryPitToRomSelector = (state) => state.executiveReportReducer.summaryPitToRom;
export const summaryPitToPortSelector = (state) => state.executiveReportReducer.summaryPitToPort;
export const summaryRomToPortSelector = (state) => state.executiveReportReducer.summaryRomToPort;
// actual coal fleet
export const fleetFromPitSelector = (state) => state.executiveReportReducer.fleetFromPit;
export const summaryFleetFromPitSelector = (state) => state.executiveReportReducer.summaryFleetFromPit;
export const summaryHaulingFleetSelector = (state) => state.executiveReportReducer.summaryHaulingFleet;
// end actual coal fleet
// plan coal fleet
export const summaryPlanCoalFleetSelector = (state) => state.executiveReportReducer.summaryPlanCoalFleet;
// end plan coal fleet
// stockpile
export const summaryRomStockpileSelector = (state) => state.executiveReportReducer.summaryRomStockpile;
export const summaryPortStockpileSelector = (state) => state.executiveReportReducer.summaryPortStockpile;
// end stockpile
// barge
export const summaryBargeActualSelector = (state) => state.executiveReportReducer.summaryBargeActual;
export const summaryBargePlanSelector = (state) => state.executiveReportReducer.summaryBargePlan;
export const summaryBargePlanDetailSelector = (state) => state.executiveReportReducer.summaryBargePlanDetail;
export const summaryBargeStatusSelector = (state) => state.executiveReportReducer.summaryBargeStatus;
export const summaryBargeLoadProgressSelector = (state) => state.executiveReportReducer.summaryBargeLoadProgress;
// end barge
// transsipment
export const summaryTransshipmentActualSelector = (state) => state.executiveReportReducer.summaryTransshipmentActual;
export const summaryTransshipmentActualDetailSelector = (state) => state.executiveReportReducer.summaryTransshipmentActualDetail;
export const summaryTransshipmentNextSelector = (state) => state.executiveReportReducer.summaryTransshipmentNext;
export const summaryTransshipmentNextDetailSelector = (state) => state.executiveReportReducer.summaryTransshipmentNextDetail;
export const summaryTransshipmentFloatingCraneSelector = (state) => state.executiveReportReducer.summaryTransshipmentFloatingCrane;
export const summaryTransshipmentFloatingCraneDetailSelector = (state) => state.executiveReportReducer.summaryTransshipmentFloatingCraneDetail;
export const summaryTransshipmentForecastSelector = (state) => state.executiveReportReducer.summaryTransshipmentForecast;
export const summaryTransshipmentForecastDetailSelector = (state) => state.executiveReportReducer.summaryTransshipmentForecastDetail;
// end transshipment

// MTD
export const summaryMTDWeatherActualSelector = (state) => state.executiveReportReducer.summaryMTDWeatherActual;
export const summaryMTDWeatherActualDetailSelector = (state) => state.executiveReportReducer.summaryMTDWeatherActualDetail;
export const summaryMTDWeatherForecastSelector = (state) => state.executiveReportReducer.summaryMTDWeatherForecast;

// report
export const reportSelector = (state) => state.executiveReportReducer.report;
// END SUMMARY

export default executiveReportSlice.reducer;
