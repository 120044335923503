import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiUrls, get, post } from "utils/request";
import { sum, cloneDeep, sumBy, round } from "lodash";
import { } from "../MidPlanning/reducer";
import { DATASOURCE_VALUE } from "utils/helper";
import { shortMonthListEN as shortMonthList } from "utils/constants";

import moment from "moment";
import {
  defaultMonthlyData,
  defaultDailyData,
  mapDataMonthly,
  transformUploadMonthlyData,
  sumYear,
} from "./STPDefaultData";

export const initialState = {
  shortTermPlanning: {
    loading: false,
    records: [],
    recordsTotal: "",
    error: "",
  },
  shortTermPlanningDetail: {
    loading: false,
    records: [],
    documentList: [],
    error: "",
    version: 1,
    defaultTable: [
      { code: "waste", header: "Waste Removal", unit: "Bcm", isHeader: true },
      { code: "waste", header: "OB Inpit", unit: "Bcm" },
      { code: "waste", header: "OB Outpit", unit: "Bcm" },
      { code: "waste", header: "Mud", unit: "Bcm" },
      {
        code: "haul",
        header: "Waste Haul Distance All",
        unit: "m",
        isHeader: true,
      },
      { code: "haul", header: "Waste Haul Distance Inpit", unit: "m" },
      { code: "haul", header: "Waste Haul Distance Outpit", unit: "m" },
      { code: "coal", header: "Coal Getting", unit: "Tonnes", isHeader: true },
      { code: "coal", header: "Coal Exposed", unit: "Tonnes" },
      { code: "coal", header: "Opening Inventory", unit: "Tonnes" },
      { code: "coal", header: "Capacity Unit Coal", unit: "Tonnes" },
      { code: "coal", header: "Closing Inventory", unit: "Tonnes" },
      { code: "sr", header: "SR All", unit: "Bcm/T", isHeader: true },
      { code: "sr", header: "SR Inpit", unit: "Bcm/T" },
      { code: "sr", header: "SR Exposed", unit: "Bcm/T" },
      { code: "quality", header: "Quality", unit: "", isHeader: true },
      { code: "quality", header: "ASH", unit: "%" },
      { code: "quality", header: "CV_ADB", unit: "kcal/kg" },
      { code: "quality", header: "CV_GAR", unit: "kcal/kg" },
      { code: "quality", header: "FC", unit: "%" },
      { code: "quality", header: "IM", unit: "%" },
      { code: "quality", header: "RD_IS", unit: "ton/bcm" },
      { code: "quality", header: "TM", unit: "%" },
      { code: "quality", header: "TS", unit: "%" },
      { code: "quality", header: "VM", unit: "%" },
    ],
    workingHour: {
      availableDays: [],
      workingHours: [],
      otherDelayHours: [],
    },
    dailyData: [],
    year: "",
    data: {
      // dataInpit: [
      //   { wasteInpit: 'Soil', unit: 'bcm' },
      //   { wasteInpit: 'Rehandle', unit: 'bcm' },
      //   { wasteInpit: 'OB Removal', unit: 'bcm' },
      //   { wasteInpit: 'OB Haul Distance', unit: 'm' },
      // ],
      // dataOutpit: [
      //   { wasteOutpit: 'Soil', unit: 'bcm' },
      //   { wasteOutpit: 'Rehandle', unit: 'bcm' },
      //   { wasteOutpit: 'OB Removal', unit: 'bcm' },
      //   { wasteOutpit: 'OB Haul Distance', unit: 'm' },
      // ],
      // dataWasteCoal: [{ wasteCoal: 'Coal Getting', unit: '' }],
      // dataWasteCoalExpose: [
      //   { wasteCoalExpose: 'Coal Getting Expose', unit: '' },
      // ],
      // dataWasteCoalHaul: [
      //   { wasteCoalHaul: 'Coal Haul Disctance (PIT to ROM)', unit: 'm' },
      // ],
      // dataWasteQuality: [
      //   { wasteQuality: 'ASH', unit: '%' },
      //   { wasteQuality: 'CV_ADB', unit: 'kcal/kg' },
      //   { wasteQuality: 'CV_GAR', unit: 'kcal/kg' },
      //   { wasteQuality: 'FC', unit: '%' },
      //   { wasteQuality: 'IM', unit: '%' },
      //   { wasteQuality: 'RD_IS', unit: 'ton/bcm' },
      //   { wasteQuality: 'TM', unit: '%' },
      //   { wasteQuality: 'TS', unit: '%' },
      //   { wasteQuality: 'VM', unit: '%' },
      // ],
      midTermPlanning: {
        dataInpit: [
          { wasteInpit: "Soil", unit: "bcm" },
          { wasteInpit: "Rehandle", unit: "bcm" },
          { wasteInpit: "OB Removal", unit: "bcm" },
          { wasteInpit: "OB Haul Distance", unit: "m" },
        ],
        dataOutpit: [
          { wasteOutpit: "Soil", unit: "bcm" },
          { wasteOutpit: "Rehandle", unit: "bcm" },
          { wasteOutpit: "OB Removal", unit: "bcm" },
          { wasteOutpit: "OB Haul Distance", unit: "m" },
        ],
        dataWasteCoal: [{ wasteCoal: "Coal Getting", unit: "" }],
        dataWasteCoalExpose: [
          { wasteCoalExpose: "Coal Getting Expose", unit: "" },
        ],
        dataWasteCoalHaul: [
          { wasteCoalHaul: "Coal Haul Disctance (PIT to ROM)", unit: "m" },
        ],
        dataWasteQuality: [
          { wasteQuality: "ASH", unit: "%" },
          { wasteQuality: "CV_ADB", unit: "kcal/kg" },
          { wasteQuality: "CV_GAR", unit: "kcal/kg" },
          { wasteQuality: "FC", unit: "%" },
          { wasteQuality: "IM", unit: "%" },
          { wasteQuality: "RD_IS", unit: "ton/bcm" },
          { wasteQuality: "TM", unit: "%" },
          { wasteQuality: "TS", unit: "%" },
          { wasteQuality: "VM", unit: "%" },
        ],
      },
    },
  },
  shortTermPlanningDetailColumn: {
    defaultColumn: [],
    dailyColumn: [],
  },
  versionList: {
    loading: false,
    versionList: [1],
    error: "",
  },
  currentObject: {},
  contractorByArea: {
    records: [],
    loading: false,
    error: "",
  },
  pitListContractor: { contractor: [], pitList: [], contractorPit: [] },

  dataTable: {
    monthly: defaultMonthlyData,
    daily: defaultDailyData(6, 2024),
  },
  dataTableAgreed: {
    monthly: defaultMonthlyData,
    daily: defaultDailyData(6, 2024),
  },
  dataTableRKAB: {
    monthly: defaultMonthlyData,
    daily: defaultDailyData(6, 2024),
  },
  dataTableBudget: {
    monthly: defaultMonthlyData,
    daily: defaultDailyData(6, 2024),
  },
};

export const getShortTermPlanning = createAsyncThunk(
  "shortTermPlanning/get",
  async (params) => {
    const response = await post(`${apiUrls.shortTermPlannings}/filter`, {
      columns: [
        {
          data: "reportId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.reportId || "",
          searchable: true,
        },
        {
          data: "createdAt",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.date || "",
          searchable: true,
        },
        // {
        //   data: 'month',
        //   orderable: true,
        //   search: {
        //     regex: false,
        //     value: '',
        //   },
        //   searchValue: params.month || '',
        //   searchable: true,
        // },
        {
          data: "year",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.year || "",
          searchable: true,
        },
        {
          data: "version",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.version || "",
          searchable: true,
        },
        {
          data: "contractor",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: params.status === DATASOURCE_VALUE.waitingApproval || false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },

        {
          data: "locationCode",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.locationCode || "",
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params.dataLength ? params.dataLength : -1,
      // order: params?.orderBy
      //   ? [
      //       {
      //         column: 0,
      //         dir: "desc",
      //       },
      //     ]
      //   : [],
      // search: {
      //   regex: true,
      //   value: params.query || "",
      // },
      order: [
        {
          column: 1,
          dir: "desc",
        },
      ],
      search: {
        regex: true,
        value: params.query || "",
      },
    });

    return response?.data;
  }
);

export const getShortTermPlanningDaily = createAsyncThunk(
  "shortTermPlanning/getDaily",
  async (params) => {
    const response = await post(`${apiUrls.shortTermPlanningsDaily}/filter`, {
      columns: [
        {
          data: "reportId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.reportId || "",
          searchable: true,
        },
        {
          data: "createdAt",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.date || "",
          searchable: true,
        },
        // {
        //   data: 'month',
        //   orderable: true,
        //   search: {
        //     regex: false,
        //     value: '',
        //   },
        //   searchValue: params.month || '',
        //   searchable: true,
        // },
        {
          data: "year",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.year || "",
          searchable: true,
        },
        {
          data: "version",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.version || "",
          searchable: true,
        },
        {
          data: "contractor",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: params.status === DATASOURCE_VALUE.waitingApproval || false,
            value: "",
          },
          searchValue: params.status || "",
          searchable: true,
        },
        {
          data: "contractor",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.contractor || "",
          searchable: true,
        },
        {
          data: "locationCode",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: params.locationCode || "",
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params.dataLength ? params.dataLength : -1,
      order: params?.orderBy
        ? [
          {
            column: 0,
            dir: "desc",
          },
        ]
        : [],
      search: {
        regex: true,
        value: params.query || "",
      },
    });
    return response?.data;
  }
);

export const getShortTermPlanningDailyById = createAsyncThunk(
  "shortTermPlanning/getById",
  async (params) => {
    const response = await post(
      `${apiUrls.shortTermPlanningsDaily}/list-id`,
      params
    );
    return response?.data;
  }
);

export const getShortTermPlanningDetail = createAsyncThunk(
  "shortTermPlanning/detail",
  async (params) => {
    // console.log("param reducer", params);
    // console.log("😒 ♾️ file: reducer.js:251 ♾️ params:", params);
    const response = await get(`${apiUrls.shortTermPlannings}/${params}`);
    return response?.data;
    return { tab: params.tab, data: response?.data };
  }
);

export const getShortTermPlanningById = createAsyncThunk(
  "shortTermPlanning/getById",
  async (params) => {
    const response = await post(
      `${apiUrls.shortTermPlannings}/list-id`,
      params
    );
    return response?.data;
  }
);

export const getVersionList = createAsyncThunk(
  "shortTermPlanning/versionlist",
  async (reportId) => {
    const response = await get(
      `${apiUrls.shortTermPlannings}/${reportId}/versions`
    );
    return response?.data;
  }
);

export const getByVersion = createAsyncThunk(
  "shortTermPlanning/getByVersion",
  async (params) => {
    const response = await get(
      `${apiUrls.shortTermPlannings}/${params.reportId}/versions/${params.version}`
    );
    return response?.data;
  }
);

export const getPitList = createAsyncThunk(
  "waste-removal/pit-list",
  async (params) => {
    const responseLocation = await post(
      `${apiUrls.masterData}/locations/filter`,
      {
        columns: [
          {
            data: "area.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "Pit",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 1,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 3,
            searchable: true,
          },
        ],
        length: -1,
        start: 0,
      }
    );

    const responseContractor = await post(
      `${apiUrls.masterData}/contractor-locations/filter`,
      {
        columns: [
          {
            data: "contractor.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            //searchValue: params.contractor,
            searchValue: "",
            searchable: true,
          },
          {
            data: "entityStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 1,
            searchable: true,
          },
          {
            data: "dataStatus",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: 3,
            searchable: true,
          },
        ],
        length: -1,
        start: 0,
      }
    );

    // const fetchContractorByArea = await get(
    //   `${apiUrls.masterData}/areas/names/${area}/contractors`
    // );
    // if (contractorCodeToken !== "BIB") {
    //   const filteredContractors = response?.data.filter(
    //     (contractor) => contractor.code === contractorCodeToken
    //   );

    //   return filteredContractors;
    // }

    // return response?.data;

    // console.log("response responseContractor", responseContractor);
    const pitList = responseLocation?.data?.list || [];
    const contractorList = responseContractor?.data?.list || [];
    let contractorPitList = [];

    pitList.map((e) => {
      contractorList.map((val) => {
        if (e.id === val.location.id)
          return contractorPitList.push(val.location);
      });
    });

    return { contractor: contractorList, pitList: pitList };
    return contractorPitList;
  }
);

export const getLatestAvailableDay = createAsyncThunk(
  "shortTermPlanning/getLatestAvailableDay",
  async (params) => {
    const { contractorCode, isAll } = params;
    const urlSearchAll = isAll ? "/all" : `?contractorCode=${contractorCode}`;
    const response = await get(
      `${apiUrls.slipperyWasteRemoval}/latest-available-day${urlSearchAll}`
    );
    return response?.data;
  }
);

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area, contractorCodeToken } = params;
    const response = await get(
      `${apiUrls.masterData}/areas/names/${area}/contractors`
    );
    if (contractorCodeToken !== "BIB") {
      const filteredContractors = response?.data.filter(
        (contractor) => contractor.code === contractorCodeToken
      );

      return filteredContractors;
    }
    return response?.data;
  }
);

export const intervensiShortTermPlanning = createAsyncThunk(
  "shortTermPlanning/intervensi",
  async (params) => {
    let response;
    try {
      response = await post(
        `${apiUrls.intervensiMinePlan}/user-role?moduleName=ShortTermPlan`,
        params
      );
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

export const getLatestDetailData = createAsyncThunk(
  "shortTermPlanning/latest-detail",
  async (params) => {
    const { contractorCode, pitCode } = params;
    const response = await get(
      `${apiUrls.shortTermPlannings}/contractor-code/${contractorCode}/pit-code/${pitCode}`
    );
    return response?.data;
  }
);

const SHORT_TERM_PLANNING = "SHORT_TERM_PLANNING";

const shortTermPlanning = createSlice({
  name: SHORT_TERM_PLANNING,
  initialState,
  reducers: {
    onChangeDocument: (state, action) => {
      const payload = action.payload;
      // state.documentList.push(payload);
      state.shortTermPlanningDetail.documentList = payload;
    },

    onResetDocument: (state, action) => {
      state.shortTermPlanningDetail.documentList = [];
    },

    onChangeDataTableMonthly: (state, action) => {
      const payload = action.payload;

      // console.log(
      //   "😒 ♾️ file: reducer.js:345 ♾️ onChangeDataTableMonthly:",
      //   payload
      // );
      const monthlyData = [...current(state[payload.tableName].monthly)];

      payload.total.forEach((payloadItem) => {
        if (!payloadItem) return;

        const { arrIndex, ...payloadItemWithoutArrIndex } = payloadItem;
        const monthlyDataItem = monthlyData[arrIndex];
        // const totalYear = sumYear([ payloadItemWithoutArrIndex ]);

        if (
          monthlyDataItem &&
          monthlyDataItem.code === payloadItem.code &&
          monthlyDataItem.header === payloadItem.header &&
          monthlyDataItem.unit === payloadItem.unit
        ) {
          monthlyData[arrIndex] = {
            ...monthlyData[arrIndex],
            ...payloadItemWithoutArrIndex,
          };
        }
      });

      //state.dataTable.monthly = sumYear(monthlyData);
      state[payload.tableName].monthly = sumYear(monthlyData);
    },
    uploadDataTableMonthly: (state, action) => {
      const data = transformUploadMonthlyData(action.payload);
      //state.dataTable.monthly = mapDataMonthly(data);
      state.dataTableAgreed.monthly = mapDataMonthly(data.data);
      state.dataTableRKAB.monthly = mapDataMonthly(data.rkab);
      state.dataTableBudget.monthly = mapDataMonthly(data.budget);
    },
    replaceDefaultTable: (state, action) => {
      const payload = action.payload || [];
      if (
        payload.length === state.shortTermPlanningDetail.defaultTable.length
      ) {
        state.shortTermPlanningDetailColumn.defaultData = payload;
      }
    },
    clearShortTermPlanningDetail: (state, action) => {
      state.shortTermPlanningDetail = {
        loading: false,
        records: [],
        error: "",
        defaultTable: [
          {
            code: "waste",
            header: "Waste Removal",
            unit: "Bcm",
            isHeader: true,
          },
          { code: "waste", header: "OB Inpit", unit: "Bcm" },
          { code: "waste", header: "OB Outpit", unit: "Bcm" },
          { code: "waste", header: "Mud", unit: "Bcm" },
          {
            code: "haul",
            header: "Waste Haul Distance All",
            unit: "m",
            isHeader: true,
          },
          { code: "haul", header: "Waste Haul Distance Inpit", unit: "m" },
          { code: "haul", header: "Waste Haul Distance Outpit", unit: "m" },
          {
            code: "coal",
            header: "Coal Getting",
            unit: "Tonnes",
            isHeader: true,
          },
          { code: "coal", header: "Coal Exposed", unit: "Tonnes" },
          { code: "coal", header: "Opening Inventory", unit: "Tonnes" },
          { code: "coal", header: "Capacity Unit Coal", unit: "Tonnes" },
          { code: "coal", header: "Closing Inventory", unit: "Tonnes" },
          { code: "sr", header: "SR All", unit: "Bcm/T", isHeader: true },
          { code: "sr", header: "SR Inpit", unit: "Bcm/T" },
          { code: "sr", header: "SR Exposed", unit: "Bcm/T" },
          { code: "quality", header: "Quality", unit: "", isHeader: true },
          { code: "quality", header: "ASH", unit: "%" },
          { code: "quality", header: "CV_ADB", unit: "kcal/kg" },
          { code: "quality", header: "CV_GAR", unit: "kcal/kg" },
          { code: "quality", header: "FC", unit: "%" },
          { code: "quality", header: "IM", unit: "%" },
          { code: "quality", header: "RD_IS", unit: "ton/bcm" },
          { code: "quality", header: "TM", unit: "%" },
          { code: "quality", header: "TS", unit: "%" },
          { code: "quality", header: "VM", unit: "%" },
        ],
        workingHour: {
          availableDays: [],
          workingHours: [],
          otherDelayHours: [],
        },
        dailyData: [],
      };
    },
    onChangeTableWasteInpit: (state, action) => {
      const payload = action.payload;
      // console.log('😒 ♾️ file: reducer.js:386 ♾️ payload:', payload);

      let val = parseInt(payload.value, 10);
      // allow to sent decimal
      if (payload.value?.includes("."))
        val = payload.value.split(".").join(",");

      state.shortTermPlanningDetail.data[payload.tableType][
        payload.tableIndexRow
      ][payload.tableRowName] = val;
    },
    createColumnShortPlanningDetail: (state, action) => {
      const payload = action.payload;
      // console.log('😒 ♾️ file: reducer.js:399 ♾️ payload:', payload);
      state.shortTermPlanningDetailColumn[payload.tableName] = payload.data;
    },
    onChangeCounterTableHeader: (state, action) => {
      const payload = action.payload;
      // console.log('😒 ♾️ file: reducer.js:404 ♾️ payload:', payload);

      let counterHeader = 0,
        month1 = 0,
        month2 = 0,
        month3 = 0,
        month4 = 0;

      const dataTableDetail = current(
        state.shortTermPlanningDetail.data[payload.tableType]
      );
      /**
       * Todo: mapping data detail for create counter   header
       */
      dataTableDetail.map((data, index) => {
        if (data[payload.tableRowName]) {
          counterHeader +=
            data.unit !== "m" ? parseInt(data[payload.tableRowName]) : 0;
        }

        // blocks of possible dead code
        if (index === 0) {
          const cloneData = cloneDeep(data);
          delete cloneData["month1"];
          delete cloneData["month2"];
          delete cloneData["month3"];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === "number"
          );

          month1 = sum(onlyNumbers);
        }

        if (index === 1) {
          const cloneData = cloneDeep(data);
          delete cloneData["month1"];
          delete cloneData["month2"];
          delete cloneData["month3"];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === "number"
          );
          month2 = sum(onlyNumbers);
        }

        if (index === 2) {
          const cloneData = cloneDeep(data);
          delete cloneData["month1"];
          delete cloneData["month2"];
          delete cloneData["month3"];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === "number"
          );
          month3 = sum(onlyNumbers);
        }

        if (index === 3) {
          const cloneData = cloneDeep(data);
          delete cloneData["month1"];
          delete cloneData["month2"];
          delete cloneData["month3"];
          const getDataSoil = Object.values(cloneData);
          const onlyNumbers = getDataSoil.filter(
            (element) => typeof element === "number"
          );
          month4 = sum(onlyNumbers);
        }
        // end blocks of possible dead code
      });

      /**
       * Todo: get detail table for column
       */
      const dataTableColumn = current(
        state.shortTermPlanningDetailColumn[payload.tableType]
      );

      /**
       * Todo: get index table detail column
       */
      const findIndex = dataTableColumn.findIndex(
        (x) => x.accessor == payload.tableRowName
      );
      const findIndexMonth1 = dataTableColumn.findIndex(
        (x) => x.accessor == "month1"
      );
      /**
       * Todo: parsing counter data header table column
       */
      const isQualityTable = payload.tableType === "dataWasteQuality";
      const isInputMonth = payload.tableRowName.includes("month");
      const isCoalTable = payload.tableType.includes("Coal");

      // possible deadcode
      if (!isQualityTable)
        state.shortTermPlanningDetailColumn[payload.tableType][findIndex][
          "Header"
        ] = counterHeader;

      const { data: detailData } = current(state.shortTermPlanningDetail);
      const {
        dataInpit: wasteRemovalInpitColumns,
        dataOutpit: wasteRemovalOutpitColumns,
      } = current(state.shortTermPlanningDetailColumn);

      const sumWasteRemovalInpit = sum(
        wasteRemovalInpitColumns
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(b.Header ?? 0), [])
      );
      const sumWasteRemovalOutpit = sum(
        wasteRemovalOutpitColumns
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(b.Header ?? 0), [])
      );
      const sumTotalWasteRemoval = sumWasteRemovalInpit + sumWasteRemovalOutpit;

      const [coalHaul] = current(
        state.shortTermPlanningDetail.data.dataWasteCoalHaul
      );
      const [coalGetting] = current(
        state.shortTermPlanningDetail.data.dataWasteCoal
      );
      const [coalExpose] = current(
        state.shortTermPlanningDetail.data.dataWasteCoalExpose
      );

      // sum based column (payload.tableRowName)
      const sumDataInpit =
        state.shortTermPlanningDetailColumn.dataInpit[findIndex]["Header"];
      const sumDataOutpit =
        state.shortTermPlanningDetailColumn.dataOutpit[findIndex]["Header"];

      if (isQualityTable || payload.tableType === "dataWasteCoal") {
        const quality = current(
          state.shortTermPlanningDetail.data.dataWasteQuality
        );

        quality.map((item, idx) => {
          function toNumber(input) {
            let tempNum = input || 0;
            if (typeof tempNum === "string") {
              if (tempNum.includes(",")) {
                tempNum = tempNum.replace(",", ".");
              }
            }
            return Number(tempNum);
          }

          let sumDay = 0;
          let sumCoalGetting = 0;
          Object.keys(item)
            .filter((key) => key.includes("day"))
            .forEach((key) => {
              sumDay += (toNumber(item[key]) || 0) * (coalGetting[key] || 0);
              sumCoalGetting += coalGetting[key] || 0;
            });
          state.shortTermPlanningDetail.data.dataWasteQuality[idx].month1 =
            round(sumDay / sumCoalGetting, 2);
        });
      } else {
        // blocks of possible dead code
        if (!isInputMonth) {
          state.shortTermPlanningDetail.data[payload.tableType][0]["month1"] =
            month1;

          if (!isCoalTable) {
            state.shortTermPlanningDetail.data[payload.tableType][1]["month1"] =
              month2;
            state.shortTermPlanningDetail.data[payload.tableType][2]["month1"] =
              month3;
            state.shortTermPlanningDetail.data[payload.tableType][3]["month1"] =
              month4;
          }
        }
        // end blocks of possible dead code
      }

      // blocks of possible dead code
      const currentSumMonth1 = sumBy(
        current(state.shortTermPlanningDetail.data[payload.tableType]),
        "month1"
      );
      if (!isInputMonth && !isQualityTable)
        state.shortTermPlanningDetailColumn[payload.tableType][findIndexMonth1][
          "Header"
        ] = currentSumMonth1 || 0;
      // end blocks of possible dead code

      // set formula per column SR Coal Getting
      if (
        payload.tableType === "dataWasteCoal" ||
        payload.tableType === "dataInpit" ||
        payload.tableType === "dataOutpit"
      ) {
        const val =
          payload.tableType === "dataWasteCoal"
            ? +payload.value
            : +(detailData.dataWasteCoal?.[0]?.[payload.tableRowName] || 0);
        const totalWasteRemoval =
          parseInt(sumDataInpit || 0, 10) + parseInt(sumDataOutpit || 0, 10);

        const calc = totalWasteRemoval / val;
        state.shortTermPlanningDetailColumn["dataWasteCoal"][findIndex][
          "Header"
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // set formula per column SR Coal Expose
      if (
        payload.tableType === "dataWasteCoalExpose" ||
        payload.tableType === "dataInpit" ||
        payload.tableType === "dataOutpit"
      ) {
        const val =
          payload.tableType === "dataWasteCoalExpose"
            ? +payload.value
            : +(
              detailData.dataWasteCoalExpose?.[0]?.[payload.tableRowName] || 0
            );
        const totalWasteRemoval =
          parseInt(sumDataInpit || 0, 10) + parseInt(sumDataOutpit || 0, 10);

        const calc = totalWasteRemoval / val;
        state.shortTermPlanningDetailColumn["dataWasteCoalExpose"][findIndex][
          "Header"
        ] = val === 0 ? 0 : round(calc, 2);
      }

      // Waste Removal Inpit Formula
      const haulDistanceInpit = detailData.dataInpit.find(
        (i) => i.wasteInpit === "OB Haul Distance"
      );
      if (!isInputMonth) {
        const target = current(state.shortTermPlanningDetail.data.dataInpit);
        const idx = target.findIndex(
          (i) => i.wasteInpit === haulDistanceInpit.wasteInpit
        );

        const array2 =
          current(state.shortTermPlanningDetailColumn.dataInpit) ?? [];
        const arr2 = array2
          .filter((i) => i.accessor.includes("day"))
          .map((i) => i.Header);
        const arr1 = array2
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(target[idx]?.[b.accessor] ?? 0), []);

        // fisrt set sum of Waste Removal Inpit on month1
        // then make it as divider
        const sumTarget = sumBy(target, "month1");
        const sumMonth1 = sumTarget - target[idx].month1;

        const sumProduct = arr1.reduce((r, a, i) => r + a * arr2[i], 0);
        const month1SumProduct = Math.round(sumProduct / sumMonth1);

        // set haul distance on month 1 based on calculation
        state.shortTermPlanningDetail.data.dataInpit[idx].month1 =
          month1SumProduct;
      }

      // Waste Removal Outpit Formula
      const haulDistanceOutpit = detailData.dataOutpit.find(
        (i) => i.wasteOutpit === "OB Haul Distance"
      );
      if (!isInputMonth) {
        const target = current(state.shortTermPlanningDetail.data.dataOutpit);
        const idx = target.findIndex(
          (i) => i.wasteOutpit === haulDistanceOutpit.wasteOutpit
        );

        const array2 =
          current(state.shortTermPlanningDetailColumn.dataOutpit) ?? [];
        const arr2 = array2
          .filter((i) => i.accessor.includes("day"))
          .map((i) => i.Header);
        const arr1 = array2
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(target[idx]?.[b.accessor] ?? 0), []);

        // fisrt set sum of Waste Removal Inpit on month1
        // then make it as divider
        const sumTarget = sumBy(target, "month1");
        const sumMonth1 = sumTarget - target[idx].month1;

        const sumProduct = arr1.reduce((r, a, i) => r + a * arr2[i], 0);
        const month1SumProduct = Math.round(sumProduct / sumMonth1);

        // set haul distance on month 1 based on calculation
        state.shortTermPlanningDetail.data.dataOutpit[idx].month1 =
          month1SumProduct;
      }

      // set Total Waste Removal month 1 sum
      state.shortTermPlanningDetailColumn.dataInpit[findIndexMonth1]["Header"] =
        sumWasteRemovalInpit;
      state.shortTermPlanningDetailColumn.dataOutpit[findIndexMonth1][
        "Header"
      ] = sumWasteRemovalOutpit;

      if (!isQualityTable) {
        // Coal Formula
        const columns =
          current(state.shortTermPlanningDetailColumn.dataWasteCoalHaul) ?? [];
        const arrCoalGetting = columns
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(coalGetting?.[b.accessor] ?? 0), []);
        const arrCoalHaul = columns
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(coalHaul?.[b.accessor] ?? 0), []);
        const arrCoalExpose = columns
          .filter((i) => i.accessor.includes("day"))
          .reduce((a, b) => a.concat(coalExpose?.[b.accessor] ?? 0), []);

        const sumProduct = arrCoalHaul.reduce(
          (r, a, i) => r + a * arrCoalGetting[i],
          0
        );
        const month1SumProduct = Math.round(sumProduct / sum(arrCoalGetting));

        // set haul distance on month 1 based on calculation
        state.shortTermPlanningDetail.data.dataWasteCoal[0].month1 =
          sum(arrCoalGetting);
        state.shortTermPlanningDetail.data.dataWasteCoalExpose[0].month1 =
          sum(arrCoalExpose);
        state.shortTermPlanningDetail.data.dataWasteCoalHaul[0].month1 =
          month1SumProduct; // SUMPRODUCT formulan Coal Haul Distance month1

        // SR Coal Expose Month1
        state.shortTermPlanningDetailColumn.dataWasteCoalExpose[
          findIndexMonth1
        ]["Header"] =
          coalExpose.month1 !== 0
            ? round(sumTotalWasteRemoval / coalExpose.month1, 2) || 0
            : 0;

        // SR Coal Getting Month1
        state.shortTermPlanningDetailColumn.dataWasteCoal[findIndexMonth1][
          "Header"
        ] =
          coalGetting.month1 !== 0
            ? round(sumTotalWasteRemoval / coalGetting.month1, 2) || 0
            : 0;
      }
    },
    onChangeYear: (state, action) => {
      const payload = action.payload;
      // state.shortTermPlanningDetail.data.midTermPlanning = payload;
      state.shortTermPlanningDetail.year = payload;
    },

    onChangeTableMonthly: (state, action) => {
      const payload = action.payload;
      // console.log('😒 ♾️ file: reducer.js:755 ♾️ payload:', payload);

      let val = parseInt(payload.value, 10);
      // allow to sent decimal
      if (payload.value?.includes("."))
        // val = payload.value.split('.').join(',');
        val = parseFloat(payload.value);

      state.shortTermPlanningDetail[payload.tableName][payload.tableIndexRow][
        payload.tableRowName
      ] = val || 0;

      if (payload.tableType === "waste" || payload.tableType === "coal") {
        const cloneRow = cloneDeep(
          state.shortTermPlanningDetail[payload.tableName][
          payload.tableIndexRow
          ]
        );
        // console.log('😒 ♾️ file: reducer.js:767 ♾️ cloneRow:', cloneRow);
        delete cloneRow["code"];
        delete cloneRow["header"];
        delete cloneRow["unit"];
        delete cloneRow["year"];
        const dataRow = Object.values(cloneRow).map((dt) =>
          parseFloat(dt.toString().replace(",", "."))
        );
        const rowFilterNumber = dataRow.filter(Number);
        let yearRow = sum(rowFilterNumber);

        state.shortTermPlanningDetail[payload.tableName][payload.tableIndexRow][
          "year"
        ] = yearRow || 0;
      }
    },

    counterDataTable: (state, action) => {
      const payload = action.payload;
      // console.log('😒 ♾️ file: reducer.js:783 ♾️ payload:', payload);

      // function to get sumproduct
      const SUMPRODUCT = (callback, ar1, ar2) => {
        if (ar1.length !== ar2.length) throw new RangeError();

        let sum = 0;

        for (let i = 0; i < ar1.length; i++) {
          if (callback(ar1[i], ar2[i])) sum += ar1[i] * ar2[i];
        }

        return sum;
      };

      if (payload.tableType === "waste") {
        const dtWaste = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "waste" && !el.isHeader
        );
        let countHeader = sumBy(dtWaste, function (o) {
          return parseFloat(
            o[payload.tableRowName]?.toString().replace(",", ".") || 0
          );
        });
        state.shortTermPlanningDetail.defaultTable[0][payload.tableRowName] =
          countHeader || 0;

        const cloneRowWaste = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[0]
        );
        delete cloneRowWaste["code"];
        delete cloneRowWaste["header"];
        delete cloneRowWaste["unit"];
        delete cloneRowWaste["year"];
        const dataRowHeadWaste = Object.values(cloneRowWaste).map((dt) =>
          parseFloat(dt.toString().replace(",", "."))
        );
        const countWasteHeader = dataRowHeadWaste.filter(Number);
        let yearRowWaste = sum(countWasteHeader);
        state.shortTermPlanningDetail.defaultTable[0]["year"] =
          yearRowWaste || 0;
      }

      if (payload.tableType === "waste" || payload.tableType === "haul") {
        const dtWaste = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "waste" && !el.isHeader
        );
        const headDtWaste = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "waste" && el.isHeader
        );
        const dtHaul = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "haul" && !el.isHeader
        );
        let counterHeader =
          (parseFloat(dtHaul[0][payload.tableRowName]) *
            (parseFloat(dtWaste[0][payload.tableRowName]) +
              parseFloat(dtWaste[2][payload.tableRowName])) +
            parseFloat(dtHaul[1][payload.tableRowName]) *
            parseFloat(dtWaste[1][payload.tableRowName])) /
          parseFloat(headDtWaste[0][payload.tableRowName]);
        state.shortTermPlanningDetail.defaultTable[4][payload.tableRowName] =
          Math.round(counterHeader) || 0;

        // Column Year on Waste Haul Distance Inpit Formula
        const cloneRowHaulInpit = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[5]
        );
        const cloneRowObInpit = cloneDeep(dtWaste[0]);
        const cloneRowMud = cloneDeep(dtWaste[2]);
        let valRowHaulInpit = [];
        let sumMudObInpit = [];
        for (let i = 0; i < 12; i++) {
          valRowHaulInpit[i] = cloneRowHaulInpit[shortMonthList[i]] || 0;
          sumMudObInpit[i] =
            (cloneRowObInpit[shortMonthList[i]] || 0) +
            (cloneRowMud[shortMonthList[i]] || 0);
        }

        let totalMudObInpit = sum(sumMudObInpit);

        let yearHaulInpit =
          SUMPRODUCT(
            (valRowHaulInpit, sumMudObInpit) => valRowHaulInpit > 0,
            valRowHaulInpit,
            sumMudObInpit
          ) / totalMudObInpit;
        state.shortTermPlanningDetail.defaultTable[5]["year"] =
          Number(yearHaulInpit.toFixed(3)) || 0;

        // Column Year on Waste Haul Distance Outpit Formula
        let cloneRowHaulOutpit = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[6]
        );
        let cloneRowObOutpit = cloneDeep(dtWaste[1]);
        let valRowHaulOutpit = [];
        let valRowObOutpit = [];
        for (let i = 0; i < 12; i++) {
          valRowHaulOutpit[i] = cloneRowHaulOutpit[shortMonthList[i]] || 0;
          valRowObOutpit[i] = cloneRowObOutpit[shortMonthList[i]] || 0;
        }

        let totalObOutpit = sum(valRowObOutpit);
        let yearHaulOutpit =
          SUMPRODUCT(
            (valRowHaulOutpit, valRowObOutpit) => valRowHaulOutpit > 0,
            valRowHaulOutpit,
            valRowObOutpit
          ) / totalObOutpit;
        state.shortTermPlanningDetail.defaultTable[6]["year"] =
          Number(yearHaulOutpit.toFixed(3)) || 0;

        // Column Year on Waste Haul Distance All Formula
        let yearRowHaulDistance =
          (parseFloat(yearHaulInpit || 0) *
            (parseFloat(
              state.shortTermPlanningDetail.defaultTable[1]["year"] || 0
            ) +
              parseFloat(
                state.shortTermPlanningDetail.defaultTable[3]["year"] || 0
              )) +
            parseFloat(yearHaulOutpit || 0) *
            parseFloat(
              state.shortTermPlanningDetail.defaultTable[2]["year"] || 0
            )) /
          parseFloat(
            state.shortTermPlanningDetail.defaultTable[0]["year"] || 0
          );
        state.shortTermPlanningDetail.defaultTable[4]["year"] =
          Math.round(yearRowHaulDistance) || 0;
      }

      if (payload.tableType === "coal") {
        const dtCoal = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "coal" && !el.isHeader
        );
        shortMonthList.map((month, idx) => {
          let counterHeaderCoal = Math.min(
            (parseFloat(dtCoal[0][month]?.toString().replace(",", ".")) || 0) +
            (parseFloat(dtCoal[1][month]?.toString().replace(",", ".")) || 0),
            parseFloat(dtCoal[2][month]?.toString().replace(",", ".")) || 0
          );
          state.shortTermPlanningDetail.defaultTable[7][month] =
            counterHeaderCoal || 0;

          let countClosingInv =
            dtCoal[0][month] +
            (dtCoal[1][month] || 0) -
            (counterHeaderCoal || 0);
          state.shortTermPlanningDetail.defaultTable[11][month] =
            countClosingInv || 0;

          let nextMonth = shortMonthList[idx + 1];
          if (nextMonth !== undefined) {
            state.shortTermPlanningDetail.defaultTable[9][nextMonth] =
              countClosingInv || 0;
          }
        });

        const cloneRowCoal = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[7]
        );
        delete cloneRowCoal["code"];
        delete cloneRowCoal["header"];
        delete cloneRowCoal["unit"];
        delete cloneRowCoal["year"];
        const dataRowHeadCoal = Object.values(cloneRowCoal).map((dt) =>
          parseFloat(dt.toString().replace(",", "."))
        );
        const countCoalHeader = dataRowHeadCoal.filter(Number);
        let yearRowCoal = sum(countCoalHeader);
        state.shortTermPlanningDetail.defaultTable[7]["year"] =
          yearRowCoal || 0;

        const cloneRowOpeningInv = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[9]
        );
        delete cloneRowOpeningInv["code"];
        delete cloneRowOpeningInv["header"];
        delete cloneRowOpeningInv["unit"];
        delete cloneRowOpeningInv["year"];
        const dataRowHeadOpeningInv = Object.values(cloneRowOpeningInv).map(
          (dt) => parseFloat(dt.toString().replace(",", "."))
        );
        const countOpeningInvHeader = dataRowHeadOpeningInv.filter(Number);
        let yearRowOpeningInv = sum(countOpeningInvHeader);
        state.shortTermPlanningDetail.defaultTable[9]["year"] =
          yearRowOpeningInv || 0;

        const cloneRowClosingInv = cloneDeep(
          state.shortTermPlanningDetail.defaultTable[11]
        );
        delete cloneRowClosingInv["code"];
        delete cloneRowClosingInv["header"];
        delete cloneRowClosingInv["unit"];
        delete cloneRowClosingInv["year"];
        const dataRowHeadClosingInv = Object.values(cloneRowClosingInv).map(
          (dt) => parseFloat(dt.toString().replace(",", "."))
        );
        const countClosingInvHeader = dataRowHeadClosingInv.filter(Number);
        let yearRowClosingInv = sum(countClosingInvHeader);
        state.shortTermPlanningDetail.defaultTable[11]["year"] =
          yearRowClosingInv || 0;
      }

      if (payload.tableType === "waste" || payload.tableType === "coal") {
        const headDtWaste = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "waste" && el.isHeader
        );
        const dtWaste = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "waste" && !el.isHeader
        );
        const headDtCoal = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "coal" && el.isHeader
        );
        const dtCoal = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "coal" && !el.isHeader
        );

        let countHeadSr =
          parseFloat(headDtWaste[0][payload.tableRowName]) /
          parseFloat(headDtCoal[0][payload.tableRowName]);
        state.shortTermPlanningDetail.defaultTable[12][payload.tableRowName] =
          isFinite(countHeadSr) ? Number(countHeadSr.toFixed(3)) || 0 : 0;

        let countSrInpit =
          ((parseFloat(
            dtWaste[0][payload.tableRowName]?.toString().replace(",", ".")
          ) || 0) +
            (parseFloat(
              dtWaste[2][payload.tableRowName]?.toString().replace(",", ".")
            ) || 0)) /
          (parseFloat(
            headDtCoal[0][payload.tableRowName]?.toString().replace(",", ".")
          ) || 0);
        state.shortTermPlanningDetail.defaultTable[13][payload.tableRowName] =
          isFinite(countSrInpit) ? Number(countSrInpit.toFixed(3)) || 0 : 0;

        let countSrExposed =
          ((parseFloat(
            dtWaste[0][payload.tableRowName]?.toString().replace(",", ".")
          ) || 0) +
            (parseFloat(
              dtWaste[2][payload.tableRowName]?.toString().replace(",", ".")
            ) || 0)) /
          (parseFloat(
            dtCoal[0][payload.tableRowName]?.toString().replace(",", ".")
          ) || 0);
        state.shortTermPlanningDetail.defaultTable[14][payload.tableRowName] =
          isFinite(countSrExposed) ? Number(countSrExposed.toFixed(3)) || 0 : 0;

        let yearSr =
          parseFloat(headDtWaste[0]["year"] || 0) /
          parseFloat(headDtCoal[0]["year"] || 0);
        state.shortTermPlanningDetail.defaultTable[12]["year"] = isFinite(
          yearSr
        )
          ? Number(yearSr.toFixed(3)) || 0
          : 0;

        let yearSrInpit =
          (parseFloat(dtWaste[0]["year"]?.toString().replace(",", ".") || 0) +
            parseFloat(dtWaste[2]["year"]?.toString().replace(",", ".") || 0)) /
          parseFloat(headDtCoal[0]["year"]?.toString().replace(",", ".") || 0);
        state.shortTermPlanningDetail.defaultTable[13]["year"] = isFinite(
          yearSrInpit
        )
          ? Number(yearSrInpit.toFixed(3)) || 0
          : 0;

        let yearSrExposed =
          (parseFloat(dtWaste[0]["year"]?.toString().replace(",", ".") || 0) +
            parseFloat(dtWaste[2]["year"]?.toString().replace(",", ".") || 0)) /
          parseFloat(dtCoal[0]["year"]?.toString().replace(",", ".") || 0);
        state.shortTermPlanningDetail.defaultTable[14]["year"] = isFinite(
          yearSrExposed
        )
          ? Number(yearSrExposed.toFixed(3)) || 0
          : 0;
      }

      if (payload.tableType === "quality" || payload.tableType === "coal") {
        const dtCoal = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "coal" && !el.isHeader
        );
        const dtQuality = state.shortTermPlanningDetail.defaultTable.filter(
          (el) => el.code === "quality" && !el.isHeader
        );

        const cloneRowExposed = cloneDeep(dtCoal[0]);
        const cloneRowOpening = cloneDeep(dtCoal[1]);
        let sumMudExposedOpening = [];
        for (let i = 0; i < 12; i++) {
          sumMudExposedOpening[i] =
            (cloneRowExposed[shortMonthList[i]] || 0) +
            (cloneRowOpening[shortMonthList[i]] || 0);
        }

        let totalExposedOpening = sum(sumMudExposedOpening);

        let cloneRowAsh = cloneDeep(dtQuality[0]);
        let cloneRowCvAdb = cloneDeep(dtQuality[1]);
        let cloneRowCvGar = cloneDeep(dtQuality[2]);
        let cloneRowFc = cloneDeep(dtQuality[3]);
        let cloneRowIm = cloneDeep(dtQuality[4]);
        let cloneRowRdIs = cloneDeep(dtQuality[5]);
        let cloneRowTm = cloneDeep(dtQuality[6]);
        let cloneRowTs = cloneDeep(dtQuality[7]);
        let cloneRowVm = cloneDeep(dtQuality[8]);
        let valRowAsh = [],
          valRowCvAdb = [],
          valRowCvGar = [],
          valRowFc = [],
          valRowIm = [],
          valRowRdIs = [],
          valRowTm = [],
          valRowTs = [],
          valRowVm = [];

        for (let i = 0; i < 12; i++) {
          valRowAsh[i] = cloneRowAsh[shortMonthList[i]] || 0;
          valRowCvAdb[i] = cloneRowCvAdb[shortMonthList[i]] || 0;
          valRowCvGar[i] = cloneRowCvGar[shortMonthList[i]] || 0;
          valRowFc[i] = cloneRowFc[shortMonthList[i]] || 0;
          valRowIm[i] = cloneRowIm[shortMonthList[i]] || 0;
          valRowRdIs[i] = cloneRowRdIs[shortMonthList[i]] || 0;
          valRowTm[i] = cloneRowTm[shortMonthList[i]] || 0;
          valRowTs[i] = cloneRowTs[shortMonthList[i]] || 0;
          valRowVm[i] = cloneRowVm[shortMonthList[i]] || 0;
        }

        let yearAsh =
          SUMPRODUCT(
            (valRowAsh, sumMudExposedOpening) => valRowAsh > 0,
            valRowAsh,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[16]["year"] =
          Number(yearAsh.toFixed(4)) || 0;

        let yearCvAdb =
          SUMPRODUCT(
            (valRowCvAdb, sumMudExposedOpening) => valRowCvAdb > 0,
            valRowCvAdb,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[17]["year"] =
          Number(yearCvAdb.toFixed(4)) || 0;

        let yearCvGar =
          SUMPRODUCT(
            (valRowCvGar, sumMudExposedOpening) => valRowCvGar > 0,
            valRowCvGar,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[18]["year"] =
          Number(yearCvGar.toFixed(4)) || 0;

        let yearFc =
          SUMPRODUCT(
            (valRowFc, sumMudExposedOpening) => valRowFc > 0,
            valRowFc,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[19]["year"] =
          Number(yearFc.toFixed(4)) || 0;

        let yearIm =
          SUMPRODUCT(
            (valRowIm, sumMudExposedOpening) => valRowIm > 0,
            valRowIm,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[20]["year"] =
          Number(yearIm.toFixed(4)) || 0;

        let yearRdIs =
          SUMPRODUCT(
            (valRowRdIs, sumMudExposedOpening) => valRowRdIs > 0,
            valRowRdIs,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[21]["year"] =
          Number(yearRdIs.toFixed(4)) || 0;

        let yearTm =
          SUMPRODUCT(
            (valRowTm, sumMudExposedOpening) => valRowTm > 0,
            valRowTm,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[22]["year"] =
          Number(yearTm.toFixed(4)) || 0;

        let yearTs =
          SUMPRODUCT(
            (valRowTs, sumMudExposedOpening) => valRowTs > 0,
            valRowTs,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[23]["year"] =
          Number(yearTs.toFixed(4)) || 0;

        let yearVm =
          SUMPRODUCT(
            (valRowVm, sumMudExposedOpening) => valRowVm > 0,
            valRowVm,
            sumMudExposedOpening
          ) / totalExposedOpening;
        state.shortTermPlanningDetail.defaultTable[24]["year"] =
          Number(yearVm.toFixed(4)) || 0;
      }
    },

    addUploadDataTableMonthly: (state, action) => {
      const payload = action.payload;
      const mappingData = (data, code, header) => {
        let fData = [];
        fData = data.map((dt) => {
          return {
            code: code,
            header: dt.itemsName,
            unit: dt.unit,
            isHeader: dt.itemsName === header,
            Jan: dt.itemsName === header ? 0 : dt.jan,
            Feb: dt.itemsName === header ? 0 : dt.feb,
            Mar: dt.itemsName === header ? 0 : dt.mar,
            Apr: dt.itemsName === header ? 0 : dt.apr,
            May: dt.itemsName === header ? 0 : dt.may,
            Jun: dt.itemsName === header ? 0 : dt.jun,
            Jul: dt.itemsName === header ? 0 : dt.jul,
            Aug: dt.itemsName === header ? 0 : dt.aug,
            Sep: dt.itemsName === header ? 0 : dt.sep,
            Oct: dt.itemsName === header ? 0 : dt.oct,
            Nov: dt.itemsName === header ? 0 : dt.nov,
            Dec: dt.itemsName === header ? 0 : dt.dec,
            year: dt.itemsName === header ? 0 : dt.total,
          };
        });
        if (code === "quality") {
          fData.unshift({
            code: "quality",
            header: "Quality",
            unit: "",
            isHeader: true,
          });
        }
        return fData;
      };
      let dataWaste = mappingData(
        payload.itemsWasteRemoval,
        "waste",
        "Waste Removal"
      );
      let dataHaul = mappingData(
        payload.itemsWasteHaulRemoval,
        "haul",
        "Waste Haul Distance All"
      );
      let dataCoal = mappingData(
        payload.itemsCoalGetting,
        "coal",
        "Coal Getting"
      );
      let dataSr = mappingData(payload.itemsSRAll, "sr", "SR All");
      let dataQuality = mappingData(payload.itemsQuality, "quality", "Quality");

      // function to get sumproduct
      const SUMPRODUCT = (callback, ar1, ar2) => {
        if (ar1.length !== ar2.length) throw new RangeError();

        let sum = 0;

        for (let i = 0; i < ar1.length; i++) {
          if (callback(ar1[i], ar2[i])) sum += ar1[i] * ar2[i];
        }
        return sum;
      };

      // WASTE
      const dtWaste = dataWaste.filter(
        (el) => el.code === "waste" && !el.isHeader
      );
      shortMonthList.map((month) => {
        let countHeader = sumBy(dtWaste, function (o) {
          return parseFloat(o[month]?.toString().replace(",", ".") || 0);
        });
        dataWaste[0][month] = countHeader || 0;
      });

      const cloneRowWaste = cloneDeep(dataWaste[0]);
      delete cloneRowWaste["code"];
      delete cloneRowWaste["header"];
      delete cloneRowWaste["unit"];
      delete cloneRowWaste["year"];
      const dataRowHeadWaste = Object.values(cloneRowWaste).map((dt) =>
        parseFloat(dt.toString().replace(",", "."))
      );
      const countWasteHeader = dataRowHeadWaste.filter(Number);
      let yearRowWaste = sum(countWasteHeader);
      dataWaste[0]["year"] = yearRowWaste || 0;

      //HAUL
      const headDtWaste = dataWaste.filter(
        (el) => el.code === "waste" && el.isHeader
      );
      const dtHaul = dataHaul.filter(
        (el) => el.code === "haul" && !el.isHeader
      );
      shortMonthList.map((month) => {
        let countHeader =
          (parseFloat(dtHaul[0][month]) *
            (parseFloat(dtWaste[0][month]) + parseFloat(dtWaste[2][month])) +
            parseFloat(dtHaul[1][month]) * parseFloat(dtWaste[1][month])) /
          parseFloat(headDtWaste[0][month]);
        dataHaul[0][month] = Math.round(countHeader) || 0;
      });

      // Column Year on Waste Haul Distance Inpit Formula
      const cloneRowHaulInpit = cloneDeep(dataHaul[1]);
      const cloneRowObInpit = cloneDeep(dtWaste[0]);
      const cloneRowMud = cloneDeep(dtWaste[2]);
      let valRowHaulInpit = [];
      let sumMudObInpit = [];
      for (let i = 0; i < 12; i++) {
        valRowHaulInpit[i] = cloneRowHaulInpit[shortMonthList[i]] || 0;
        sumMudObInpit[i] =
          (cloneRowObInpit[shortMonthList[i]] || 0) +
          (cloneRowMud[shortMonthList[i]] || 0);
      }

      let totalMudObInpit = sum(sumMudObInpit);

      let yearHaulInpit =
        SUMPRODUCT(
          (valRowHaulInpit, sumMudObInpit) => valRowHaulInpit > 0,
          valRowHaulInpit,
          sumMudObInpit
        ) / totalMudObInpit;
      dataHaul[1]["year"] = Number(yearHaulInpit.toFixed(3)) || 0;

      // Column Year on Waste Haul Distance Outpit Formula
      let cloneRowHaulOutpit = cloneDeep(dataHaul[2]);
      let cloneRowObOutpit = cloneDeep(dtWaste[1]);
      let valRowHaulOutpit = [];
      let valRowObOutpit = [];
      for (let i = 0; i < 12; i++) {
        valRowHaulOutpit[i] = cloneRowHaulOutpit[shortMonthList[i]] || 0;
        valRowObOutpit[i] = cloneRowObOutpit[shortMonthList[i]] || 0;
      }

      let totalObOutpit = sum(valRowObOutpit);
      let yearHaulOutpit =
        SUMPRODUCT(
          (valRowHaulOutpit, valRowObOutpit) => valRowHaulOutpit > 0,
          valRowHaulOutpit,
          valRowObOutpit
        ) / totalObOutpit;
      dataHaul[2]["year"] = Number(yearHaulOutpit.toFixed(3)) || 0;

      // Column Year on Waste Haul Distance All Formula
      let yearRowHaulDistance =
        (parseFloat(yearHaulInpit || 0) *
          (parseFloat(dataWaste[1]["year"] || 0) +
            parseFloat(dataWaste[3]["year"] || 0)) +
          parseFloat(yearHaulOutpit || 0) *
          parseFloat(dataWaste[2]["year"] || 0)) /
        parseFloat(dataWaste[0]["year"] || 0);
      dataHaul[0]["year"] = Math.round(yearRowHaulDistance) || 0;

      // COAL
      shortMonthList.map((month, idx) => {
        let counterHeaderCoal = Math.min(
          (parseFloat(dataCoal[1][month]?.toString().replace(",", ".")) || 0) +
          (parseFloat(dataCoal[2][month]?.toString().replace(",", ".")) || 0),
          parseFloat(dataCoal[3][month]?.toString().replace(",", ".")) || 0
        );
        dataCoal[0][month] = counterHeaderCoal || 0;

        let countClosingInv =
          dataCoal[1][month] +
          (dataCoal[2][month] || 0) -
          (counterHeaderCoal || 0);
        dataCoal[4][month] = countClosingInv || 0;

        let nextMonth = shortMonthList[idx + 1];
        if (nextMonth !== undefined) {
          dataCoal[2][nextMonth] = countClosingInv || 0;
        }
      });

      const cloneRowCoal = cloneDeep(dataCoal[0]);
      delete cloneRowCoal["code"];
      delete cloneRowCoal["header"];
      delete cloneRowCoal["unit"];
      delete cloneRowCoal["year"];
      const dataRowHeadCoal = Object.values(cloneRowCoal).map((dt) =>
        parseFloat(dt.toString().replace(",", "."))
      );
      const countCoalHeader = dataRowHeadCoal.filter(Number);
      let yearRowCoal = sum(countCoalHeader);
      dataCoal[0]["year"] = yearRowCoal || 0;

      const cloneRowOpeningInv = cloneDeep(dataCoal[2]);
      delete cloneRowOpeningInv["code"];
      delete cloneRowOpeningInv["header"];
      delete cloneRowOpeningInv["unit"];
      delete cloneRowOpeningInv["year"];
      const dataRowHeadOpeningInv = Object.values(cloneRowOpeningInv).map(
        (dt) => parseFloat(dt.toString().replace(",", "."))
      );
      const countOpeningInvHeader = dataRowHeadOpeningInv.filter(Number);
      let yearRowOpeningInv = sum(countOpeningInvHeader);
      dataCoal[2]["year"] = yearRowOpeningInv || 0;

      const cloneRowClosingInv = cloneDeep(dataCoal[4]);
      delete cloneRowClosingInv["code"];
      delete cloneRowClosingInv["header"];
      delete cloneRowClosingInv["unit"];
      delete cloneRowClosingInv["year"];
      const dataRowHeadClosingInv = Object.values(cloneRowClosingInv).map(
        (dt) => parseFloat(dt.toString().replace(",", "."))
      );
      const countClosingInvHeader = dataRowHeadClosingInv.filter(Number);
      let yearRowClosingInv = sum(countClosingInvHeader);
      dataCoal[4]["year"] = yearRowClosingInv || 0;

      // SR
      shortMonthList.map((month) => {
        let countHeadSr =
          parseFloat(dataWaste[0][month]) / parseFloat(dataCoal[0][month]);
        dataSr[0][month] = isFinite(countHeadSr)
          ? Number(countHeadSr.toFixed(3)) || 0
          : 0;

        let countSrInpit =
          ((parseFloat(dataWaste[1][month]?.toString().replace(",", ".")) ||
            0) +
            (parseFloat(dataWaste[3][month]?.toString().replace(",", ".")) ||
              0)) /
          (parseFloat(dataCoal[0][month]?.toString().replace(",", ".")) || 0);
        dataSr[1][month] = isFinite(countSrInpit)
          ? Number(countSrInpit.toFixed(3)) || 0
          : 0;

        let countSrExposed =
          ((parseFloat(dataWaste[1][month]?.toString().replace(",", ".")) ||
            0) +
            (parseFloat(dataWaste[3][month]?.toString().replace(",", ".")) ||
              0)) /
          (parseFloat(dataCoal[1][month]?.toString().replace(",", ".")) || 0);
        dataSr[2][month] = isFinite(countSrExposed)
          ? Number(countSrExposed.toFixed(3)) || 0
          : 0;
      });

      let yearSr =
        parseFloat(dataWaste[0]["year"] || 0) /
        parseFloat(dataCoal[0]["year"] || 0);
      dataSr[0]["year"] = isFinite(yearSr) ? Number(yearSr.toFixed(3)) || 0 : 0;

      let yearSrInpit =
        (parseFloat(dataWaste[1]["year"]?.toString().replace(",", ".") || 0) +
          parseFloat(dataWaste[3]["year"]?.toString().replace(",", ".") || 0)) /
        parseFloat(dataCoal[0]["year"]?.toString().replace(",", ".") || 0);
      dataSr[1]["year"] = isFinite(yearSrInpit)
        ? Number(yearSrInpit.toFixed(3)) || 0
        : 0;

      let yearSrExposed =
        (parseFloat(dataWaste[1]["year"]?.toString().replace(",", ".") || 0) +
          parseFloat(dataWaste[3]["year"]?.toString().replace(",", ".") || 0)) /
        parseFloat(dataCoal[1]["year"]?.toString().replace(",", ".") || 0);
      dataSr[2]["year"] = isFinite(yearSrExposed)
        ? Number(yearSrExposed.toFixed(3)) || 0
        : 0;

      // QUALITY
      const cloneRowExposed = cloneDeep(dataCoal[1]);
      const cloneRowOpening = cloneDeep(dataCoal[2]);
      let sumMudExposedOpening = [];
      for (let i = 0; i < 12; i++) {
        sumMudExposedOpening[i] =
          (cloneRowExposed[shortMonthList[i]] || 0) +
          (cloneRowOpening[shortMonthList[i]] || 0);
      }

      let totalExposedOpening = sum(sumMudExposedOpening);

      let cloneRowAsh = cloneDeep(dataQuality[1]);
      let cloneRowCvAdb = cloneDeep(dataQuality[2]);
      let cloneRowCvGar = cloneDeep(dataQuality[3]);
      let cloneRowFc = cloneDeep(dataQuality[4]);
      let cloneRowIm = cloneDeep(dataQuality[5]);
      let cloneRowRdIs = cloneDeep(dataQuality[6]);
      let cloneRowTm = cloneDeep(dataQuality[7]);
      let cloneRowTs = cloneDeep(dataQuality[8]);
      let cloneRowVm = cloneDeep(dataQuality[9]);
      let valRowAsh = [],
        valRowCvAdb = [],
        valRowCvGar = [],
        valRowFc = [],
        valRowIm = [],
        valRowRdIs = [],
        valRowTm = [],
        valRowTs = [],
        valRowVm = [];

      for (let i = 0; i < 12; i++) {
        valRowAsh[i] = cloneRowAsh[shortMonthList[i]] || 0;
        valRowCvAdb[i] = cloneRowCvAdb[shortMonthList[i]] || 0;
        valRowCvGar[i] = cloneRowCvGar[shortMonthList[i]] || 0;
        valRowFc[i] = cloneRowFc[shortMonthList[i]] || 0;
        valRowIm[i] = cloneRowIm[shortMonthList[i]] || 0;
        valRowRdIs[i] = cloneRowRdIs[shortMonthList[i]] || 0;
        valRowTm[i] = cloneRowTm[shortMonthList[i]] || 0;
        valRowTs[i] = cloneRowTs[shortMonthList[i]] || 0;
        valRowVm[i] = cloneRowVm[shortMonthList[i]] || 0;
      }

      let yearAsh =
        SUMPRODUCT(
          (valRowAsh, sumMudExposedOpening) => valRowAsh > 0,
          valRowAsh,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[1]["year"] = Number(yearAsh.toFixed(4)) || 0;

      let yearCvAdb =
        SUMPRODUCT(
          (valRowCvAdb, sumMudExposedOpening) => valRowCvAdb > 0,
          valRowCvAdb,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[2]["year"] = Number(yearCvAdb.toFixed(4)) || 0;

      let yearCvGar =
        SUMPRODUCT(
          (valRowCvGar, sumMudExposedOpening) => valRowCvGar > 0,
          valRowCvGar,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[3]["year"] = Number(yearCvGar.toFixed(4)) || 0;

      let yearFc =
        SUMPRODUCT(
          (valRowFc, sumMudExposedOpening) => valRowFc > 0,
          valRowFc,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[4]["year"] = Number(yearFc.toFixed(4)) || 0;

      let yearIm =
        SUMPRODUCT(
          (valRowIm, sumMudExposedOpening) => valRowIm > 0,
          valRowIm,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[5]["year"] = Number(yearIm.toFixed(4)) || 0;

      let yearRdIs =
        SUMPRODUCT(
          (valRowRdIs, sumMudExposedOpening) => valRowRdIs > 0,
          valRowRdIs,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[6]["year"] = Number(yearRdIs.toFixed(4)) || 0;

      let yearTm =
        SUMPRODUCT(
          (valRowTm, sumMudExposedOpening) => valRowTm > 0,
          valRowTm,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[7]["year"] = Number(yearTm.toFixed(4)) || 0;

      let yearTs =
        SUMPRODUCT(
          (valRowTs, sumMudExposedOpening) => valRowTs > 0,
          valRowTs,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[8]["year"] = Number(yearTs.toFixed(4)) || 0;

      let yearVm =
        SUMPRODUCT(
          (valRowVm, sumMudExposedOpening) => valRowVm > 0,
          valRowVm,
          sumMudExposedOpening
        ) / totalExposedOpening;
      dataQuality[9]["year"] = Number(yearVm.toFixed(4)) || 0;

      let dtTable = (dataWaste || [])
        .concat(dataHaul || [])
        .concat(dataCoal || [])
        .concat(dataSr || [])
        .concat(dataQuality || []);
      // console.log("😒 ♾️ file: reducer.js:1271 ♾️ dtTable:", dtTable);
      state.shortTermPlanningDetail.defaultTable = dtTable;
      state.shortTermPlanningDetail.year = payload.year;
    },

    createDailyData: (state, action) => {
      const payload = action.payload;
      let sumShift = payload?.day * 2 || 0;

      let finalData = [];
      let tempData = state.shortTermPlanningDetail.defaultTable.map((dt) => {
        return {
          code: dt.code,
          header: dt.header,
          isHeader: dt.isHeader,
          unit: dt.unit,
          value: dt[shortMonthList[payload.month?.value - 1]],
        };
      });
      // console.log("😒 ♾️ file: reducer.js:1289 ♾️ tempData:", tempData);

      let workingHours =
        state.shortTermPlanningDetail?.workingHour?.workingHours || [];

      let listHoliday = [];
      let whd = workingHours.map((wh) => {
        let dt = { ...wh };
        if (wh.startDate) {
          let val = wh.holidayDuration;
          let durDays =
            parseInt(moment(wh.endDate).format("D")) -
            parseInt(moment(wh.startDate).format("D"));
          let day = moment(wh.startDate);
          Array(durDays === 0 ? 1 : durDays + 1)
            .fill("")
            .map((e, i) => {
              e = day.format("D/M/yyyy");
              dt[e] = val - 2 >= 0 ? 2 : val;
              listHoliday.push({
                date: e,
                value: val - 2 >= 0 ? 2 : val,
              });
              val = val - 2;
              day = day.add(1, "days");
            });
        }
        return {
          ...dt,
          month: wh.startDate ? parseInt(moment(wh.startDate).format("M")) : 0,
        };
      });

      let totalShift =
        sumShift -
        (whd.length > 0
          ? sumBy(
            whd.filter((el) => el.month === payload.month.value),
            "holidayDuration"
          )
          : 0);

      finalData = tempData.map((dt) => {
        let nd = { ...dt, month: dt.value };
        Array(payload?.day)
          .fill("")
          .map((e, i) => {
            e = i + 1;
            let countShift = 2;
            let holiday = listHoliday.find(
              (dt) => dt.date === `${e}/${payload.month.value}/${payload.year}`
            );
            if (holiday) {
              countShift = countShift - holiday.value;
            }
            if (dt.code === "haul" || dt.code === "quality") {
              nd[`${e}/${payload.month.value}/${payload.year}`] = dt.value;
            } else if (dt.code === "coal") {
              nd[`${e}/${payload.month.value}/${payload.year}`] = Number(
                ((dt.value / totalShift) * countShift).toFixed(3)
              );
            } else {
              nd[`${e}/${payload.month.value}/${payload.year}`] = Number(
                ((dt.value / totalShift) * countShift).toFixed(3)
              );
            }
          });
        return nd;
      });

      state.shortTermPlanningDetail.dailyData = finalData;

      const dtCoal = state.shortTermPlanningDetail.dailyData.filter(
        (el) => el.code === "coal" && !el.isHeader
      );
      Array(payload?.day)
        .fill("")
        .map((e, idx) => {
          e = idx + 1;
          // Count data coal
          if (e === 1) {
            state.shortTermPlanningDetail.dailyData[9][
              `${e}/${payload.month.value}/${payload.year}`
            ] = round(dtCoal[1].value);
          } else if (e === 2) {
            state.shortTermPlanningDetail.dailyData[9][
              `${e}/${payload.month.value}/${payload.year}`
            ] = round(dtCoal[3].value);
          }

          let counterHeaderCoal = Math.min(
            (parseFloat(
              dtCoal[0][`${e}/${payload.month.value}/${payload.year}`]
                ?.toString()
                .replace(",", ".")
            ) || 0) +
            (parseFloat(
              dtCoal[1][`${e}/${payload.month.value}/${payload.year}`]
                ?.toString()
                .replace(",", ".")
            ) || 0),
            parseFloat(
              dtCoal[2][`${e}/${payload.month.value}/${payload.year}`]
                ?.toString()
                .replace(",", ".")
            ) || 0
          );
          state.shortTermPlanningDetail.dailyData[7][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(counterHeaderCoal) || 0;

          let countClosingInv =
            parseFloat(
              dtCoal[0][`${e}/${payload.month.value}/${payload.year}`]
            ) +
            (parseFloat(
              dtCoal[1][`${e}/${payload.month.value}/${payload.year}`]
            ) || 0) -
            (counterHeaderCoal || 0);
          state.shortTermPlanningDetail.dailyData[11][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(countClosingInv) || 0;

          let nextDay = e + 1;
          if (nextDay <= payload?.day) {
            state.shortTermPlanningDetail.dailyData[9][
              `${nextDay}/${payload.month.value}/${payload.year}`
            ] = countClosingInv || 0;
          }

          // Count data sr
          let countHeadSr =
            parseFloat(
              state.shortTermPlanningDetail.dailyData[0][
              `${e}/${payload.month.value}/${payload.year}`
              ] || 0
            ) /
            parseFloat(
              state.shortTermPlanningDetail.dailyData[7][
              `${e}/${payload.month.value}/${payload.year}`
              ] || 0
            );
          state.shortTermPlanningDetail.dailyData[12][
            `${e}/${payload.month.value}/${payload.year}`
          ] = isFinite(countHeadSr) ? Number(countHeadSr.toFixed(3)) || 0 : 0;

          let countSrInpit =
            ((parseFloat(
              state.shortTermPlanningDetail.dailyData[1][
                `${e}/${payload.month.value}/${payload.year}`
              ]
                ?.toString()
                .replace(",", ".")
            ) || 0) +
              (parseFloat(
                state.shortTermPlanningDetail.dailyData[3][
                  `${e}/${payload.month.value}/${payload.year}`
                ]
                  ?.toString()
                  .replace(",", ".")
              ) || 0)) /
            (parseFloat(
              state.shortTermPlanningDetail.dailyData[7][
                `${e}/${payload.month.value}/${payload.year}`
              ]
                ?.toString()
                .replace(",", ".")
            ) || 0);
          state.shortTermPlanningDetail.dailyData[13][
            `${e}/${payload.month.value}/${payload.year}`
          ] = isFinite(countSrInpit) ? Number(countSrInpit.toFixed(3)) || 0 : 0;

          let countSrExposed =
            ((parseFloat(
              state.shortTermPlanningDetail.dailyData[1][
                `${e}/${payload.month.value}/${payload.year}`
              ]
                ?.toString()
                .replace(",", ".")
            ) || 0) +
              (parseFloat(
                state.shortTermPlanningDetail.dailyData[3][
                  `${e}/${payload.month.value}/${payload.year}`
                ]
                  ?.toString()
                  .replace(",", ".")
              ) || 0)) /
            (parseFloat(
              state.shortTermPlanningDetail.dailyData[8][
                `${e}/${payload.month.value}/${payload.year}`
              ]
                ?.toString()
                .replace(",", ".")
            ) || 0);
          state.shortTermPlanningDetail.dailyData[14][
            `${e}/${payload.month.value}/${payload.year}`
          ] = isFinite(countSrExposed)
              ? Number(countSrExposed.toFixed(3)) || 0
              : 0;

          let monthSr =
            parseFloat(
              state.shortTermPlanningDetail.dailyData[0]["month"] || 0
            ) /
            parseFloat(
              state.shortTermPlanningDetail.dailyData[7]["month"] || 0
            );
          state.shortTermPlanningDetail.dailyData[12]["month"] = isFinite(
            monthSr
          )
            ? Number(monthSr.toFixed(3)) || 0
            : 0;

          let monthSrInpit =
            (parseFloat(
              state.shortTermPlanningDetail.dailyData[1]["month"]
                ?.toString()
                .replace(",", ".") || 0
            ) +
              parseFloat(
                state.shortTermPlanningDetail.dailyData[3]["month"]
                  ?.toString()
                  .replace(",", ".") || 0
              )) /
            parseFloat(
              state.shortTermPlanningDetail.dailyData[7]["month"]
                ?.toString()
                .replace(",", ".") || 0
            );
          state.shortTermPlanningDetail.dailyData[13]["month"] = isFinite(
            monthSrInpit
          )
            ? Number(monthSrInpit.toFixed(3)) || 0
            : 0;

          let monthSrExposed =
            (parseFloat(
              state.shortTermPlanningDetail.dailyData[1]["month"]
                ?.toString()
                .replace(",", ".") || 0
            ) +
              parseFloat(
                state.shortTermPlanningDetail.dailyData[3]["month"]
                  ?.toString()
                  .replace(",", ".") || 0
              )) /
            parseFloat(
              state.shortTermPlanningDetail.dailyData[8]["month"]
                ?.toString()
                .replace(",", ".") || 0
            );
          state.shortTermPlanningDetail.dailyData[14]["month"] = isFinite(
            monthSrExposed
          )
            ? Number(monthSrExposed.toFixed(3)) || 0
            : 0;
        });

      state.shortTermPlanningDetail.dailyData[9]["month"] =
        state.shortTermPlanningDetail.dailyData[11][
        `${payload?.day}/${payload.month.value}/${payload.year}`
        ];
      state.shortTermPlanningDetail.dailyData[11]["month"] =
        parseFloat(state.shortTermPlanningDetail.dailyData[8]["month"]) +
        parseFloat(state.shortTermPlanningDetail.dailyData[9]["month"] || 0) -
        parseFloat(state.shortTermPlanningDetail.dailyData[7]["month"] || 0);

      Array(payload?.day)
        .fill("")
        .map((e, idx) => {
          e = idx + 1;
          state.shortTermPlanningDetail.dailyData[7][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(
            state.shortTermPlanningDetail.dailyData[7][
            `${e}/${payload.month.value}/${payload.year}`
            ]
          );
          state.shortTermPlanningDetail.dailyData[8][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(
            state.shortTermPlanningDetail.dailyData[8][
            `${e}/${payload.month.value}/${payload.year}`
            ]
          );
          state.shortTermPlanningDetail.dailyData[9][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(
            state.shortTermPlanningDetail.dailyData[9][
            `${e}/${payload.month.value}/${payload.year}`
            ]
          );
          state.shortTermPlanningDetail.dailyData[10][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(
            state.shortTermPlanningDetail.dailyData[10][
            `${e}/${payload.month.value}/${payload.year}`
            ]
          );
          state.shortTermPlanningDetail.dailyData[11][
            `${e}/${payload.month.value}/${payload.year}`
          ] = round(
            state.shortTermPlanningDetail.dailyData[11][
            `${e}/${payload.month.value}/${payload.year}`
            ]
          );
        });
    },
    createDailyDataCustom: (state, action) => {
      const payload = action.payload;
      const { data, isSearchAll, workingHour, workingHourAll } = payload;
      const sumShift = payload?.day * 2 || 0;

      let result = [];
      const tempData = data.map((dt) => {
        return {
          code: dt.code,
          header: dt.header,
          isHeader: dt.isHeader,
          unit: dt.unit,
          value: dt[shortMonthList[payload.month?.value - 1]],
        };
      });

      const getWorkingHours = (data) => {
        const workingHours = data.map((item) => {
          return item.workingHours;
        });
        return workingHours.flat();
      };

      const singleWorkingHour = workingHour?.workingHours || [];
      const multipleWorkingHour = getWorkingHours(workingHourAll) || [];
      const workingHours = isSearchAll
        ? multipleWorkingHour
        : singleWorkingHour;

      const listHoliday = [];
      const workingHoursByDate = workingHours.map((wh) => {
        const dt = { ...wh };
        if (wh.startDate) {
          let val = wh.holidayDuration;
          const durDays =
            moment(wh.endDate).diff(moment(wh.startDate), "days") + 1;
          let day = moment(wh.startDate);
          for (let i = 0; i < durDays; i++) {
            const e = day.format("D/M/yyyy");
            const holidayValue = val - 2 >= 0 ? 2 : val;
            dt[e] = holidayValue;
            listHoliday.push({
              date: e,
              value: holidayValue < 0 ? 0 : holidayValue,
            });
            val -= 2;
            day = day.add(1, "days");
          }
        }
        return {
          ...dt,
          month: wh.startDate ? parseInt(moment(wh.startDate).format("M")) : 0,
        };
      });

      const totalShift =
        sumShift -
        (workingHoursByDate.length > 0
          ? sumBy(
            workingHoursByDate.filter(
              (el) => el.month === payload.month.value
            ),
            "holidayDuration"
          )
          : 0);

      result = tempData.map((dt) => {
        const nd = { ...dt, month: dt.value };
        for (let i = 1; i <= payload?.day; i++) {
          let countShift = 2;
          const { month, year } = payload;
          const holiday = listHoliday.find(
            (item) => item.date === `${i}/${month.value}/${year}`
          );
          if (holiday) {
            countShift -= holiday.value;
          }
          const value = dt.value;
          const getMonthYear = (payload) =>
            `${payload.month.value}/${payload.year}`;
          nd[`${i}/${getMonthYear(payload)}`] =
            dt.code === "haul" || dt.code === "quality"
              ? value
              : Number(((value / totalShift) * countShift).toFixed(3));
        }
        return nd;
      });

      state.shortTermPlanningDetail.dailyData = result;
    },

    clearDailyTable: (state, action) => {
      state.shortTermPlanningDetail.dailyData = [];
      state.shortTermPlanningDetail.dailyDataPlan = {}
      state.shortTermPlanningDetailColumn.dailyColumn = [];
    },

    resetShortTermPlanningDetail: (state, action) => {
      const defaultTable = [
        {
          header: "OB Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "OB Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "OB Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Total Waste",
          unit: "BCM",
          code: "waste",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Opening Inventory",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal Expose",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal to Mine",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Cap Unit Coal",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal Getting",
          unit: "tonnes",
          code: "coal",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Closing Inventory",
          unit: "tonnes",
          code: "sr",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "SR Expose",
          unit: "BCM/ton",
          code: "sr",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "SR Inpit(CG)",
          unit: "BCM/ton",
          code: "sr",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "SR All",
          unit: "BCM/ton",
          code: "sr",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Coal",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance OB Inpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Inpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Inpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Inpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance OB Outpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Outpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Outpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Outpit",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance OB Project",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Project",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Project",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Project",
          unit: "meter",
          code: "haul",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance Waste All",
          unit: "meter",
          code: "haul",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "ASH",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "CV_ADB",
          unit: "kcal/kg",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "CV_GAR",
          unit: "kcal/kg",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "FC",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "IM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "RD_IS",
          unit: "ton/bcm",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "TM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "TS",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "VM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "Quality",
          unit: "",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
      ];

      state.shortTermPlanningDetail.defaultTable = defaultTable;
      // state.dataTable.monthly = mapDataMonthly(defaultTable);
      state.dataTable.monthly = defaultMonthlyData;
      state.dataTableAgreed.monthly = defaultMonthlyData;
      state.dataTableRKAB.monthly = defaultMonthlyData;
      state.dataTableBudget.monthly = defaultMonthlyData;
    },

    contractorPitList: (state, action) => {
      const filterContractor = state.pitListContractor.contractor.filter(
        (item) => item.contractor.id === action.payload?.value.id
      );

      let contractorPitList = [];

      state.pitListContractor.pitList.map((e) => {
        filterContractor.map((val) => {
          if (e.id === val.location.id)
            return contractorPitList.push(val.location);
        });
      });
      state.pitListContractor.contractorPit = contractorPitList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShortTermPlanning.pending, (state, action) => {
      state.shortTermPlanning.loading = true;
    });
    builder.addCase(getShortTermPlanning.fulfilled, (state, action) => {
      state.shortTermPlanning.loading = false;
      state.shortTermPlanning.records = action.payload.list;
      state.shortTermPlanning.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getShortTermPlanning.rejected, (state, action) => {
      state.shortTermPlanning.loading = false;
      state.shortTermPlanning.error = "Invalid get data";
    });

    builder.addCase(getShortTermPlanningDetail.pending, (state, action) => {
      state.shortTermPlanningDetail.loading = true;
    });
    builder.addCase(getShortTermPlanningDetail.fulfilled, (state, action) => {

      // console.log("action.payload getShortTermPlanningDetail", action.payload);
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.documentList = action.payload.documentList
      // console.log("tabDataPair[tab]", tabDataPair[tab]);

      // let dtTable = (action.payload?.data.monthlyPlan.dataWasteRemoval || [])
      //   .concat(action.payload?.data.monthlyPlan.dataWasteHaulDistanceAll || [])
      //   .concat(action.payload?.data.monthlyPlan.dataCoalGetting || [])
      //   .concat(action.payload?.data.monthlyPlan.dataSRAll || [])
      //   .concat(action.payload?.data.monthlyPlan.dataQuality || []);

      // Helper function to get concatenated data for a given plan type
      const getTableData = (tab) => {
        const plan = action.payload?.[tab]?.monthlyPlan || {};

        const keys = [
          "dataWasteRemoval",
          "dataWasteHaulDistanceAll",
          "dataCoalGetting",
          "dataSRAll",
          "dataQuality",
        ];

        return keys.reduce((acc, key) => acc.concat(plan[key] || []), []);
      };

      // Using the helper function to generate dtTableAgreed, dtTableRkab, and dtTableBudgetg
      let dtTableAgreed = getTableData("data");
      let dtTableRkab = getTableData("rkab");
      let dtTableBudget = getTableData("budget");
      //  console.log("😒 ♾️ file: reducer.js:1561 ♾️ dtTable:", dtTable);
      // let dataDetail = { ...data, defaultTable: dtTable, agreedTable: dtTable };
      let dataDetail = { ...action.payload };
      // console.log("dataDetail", dataDetail);
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail = dataDetail;
      //  state.dataTable.monthly = mapDataMonthly(dtTable);
      //state[`dataTable${tab}`].monthly = mapDataMonthly(dtTable);
      state.dataTableAgreed.monthly = mapDataMonthly(dtTableAgreed);
      state.dataTableRKAB.monthly = mapDataMonthly(dtTableRkab);
      state.dataTableBudget.monthly = mapDataMonthly(dtTableBudget);
    });
    builder.addCase(getShortTermPlanningDetail.rejected, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.error = "Invalid get data";
    });
    builder.addCase(getPitList.pending, (state, action) => {
      state.loadingPitList = true;
    });
    builder.addCase(getPitList.fulfilled, (state, action) => {
      state.pitListContractor.contractor = action.payload.contractor;
      state.pitListContractor.pitList = action.payload.pitList;
      state.loadingPitList = false;
    });
    builder.addCase(getVersionList.pending, (state, action) => {
      state.versionList.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.versionList.loading = false;
      state.versionList.error = "Invalid get data";
    });

    builder.addCase(getByVersion.pending, (state, action) => {
      state.shortTermPlanningDetail.loading = true;
    });
    builder.addCase(getByVersion.fulfilled, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.versionList = action.payload;
    });
    builder.addCase(getByVersion.rejected, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.error = "Invalid get data";
    });

    builder.addCase(getLatestAvailableDay.pending, (state, action) => {
      state.shortTermPlanningDetail.loading = true;
    });
    builder.addCase(getLatestAvailableDay.fulfilled, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.workingHour = action.payload;
    });
    builder.addCase(getLatestAvailableDay.rejected, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.error = "Invalid get data";
    });
    builder.addCase(fetchContractorByArea.pending, (state, action) => {
      state.contractorByArea.loading = true;
    });
    builder.addCase(fetchContractorByArea.fulfilled, (state, action) => {
      state.contractorByArea.records = action.payload;
      //state.pitList = action.payload;
    });
    builder.addCase(fetchContractorByArea.rejected, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    });
    builder.addCase(getLatestDetailData.pending, (state, action) => {
      state.shortTermPlanningDetail.loading = true;
    });
    builder.addCase(getLatestDetailData.fulfilled, (state, action) => {
      const defData = [
        {
          header: "OB Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Inpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "OB Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Outpit",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "OB Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Soil Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Mud Original Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Total Waste Project",
          unit: "BCM",
          code: "waste",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Total Waste",
          unit: "BCM",
          code: "waste",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Opening Inventory",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal Expose",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal to Mine",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Cap Unit Coal",
          unit: "tonnes",
          code: "coal",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Coal Getting",
          unit: "tonnes",
          code: "coal",
          isHeader: true,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Closing Inventory",
          unit: "tonnes",
          code: "sr",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "SR Expose",
          unit: "BCM/ton",
          code: "sr",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "SR Inpit(CG)",
          unit: "BCM/ton",
          code: "sr",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "SR All",
          unit: "BCM/ton",
          code: "sr",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Coal",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance OB Inpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Inpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Inpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Inpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance OB Outpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Outpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Outpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Outpit",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance OB Project",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Soil Project",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Mud Original Project",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          header: "Distance Waste Project",
          unit: "meter",
          code: "distance",
          isHeader: false,
          isSubHeader: true,
          disabledRow: false,
        },
        {
          header: "Distance Waste All",
          unit: "meter",
          code: "distance",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "ASH",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "CV_ADB",
          unit: "kcal/kg",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "CV_GAR",
          unit: "kcal/kg",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "FC",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "IM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "RD_IS",
          unit: "ton/bcm",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "TM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "TS",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "VM",
          unit: "%",
          isHeader: false,
          isSubHeader: false,
          disabledRow: false,
        },
        {
          code: "quality",
          header: "Quality",
          unit: "",
          isHeader: true,
          isSubHeader: false,
          disabledRow: false,
        },
      ];

      let dtTable = (
        action.payload?.data?.monthlyPlan?.dataWasteRemoval || defData
      )
        .concat(
          action.payload?.data?.monthlyPlan?.dataWasteHaulDistanceAll || []
        )
        .concat(action.payload?.data?.monthlyPlan?.dataCoalGetting || [])
        .concat(action.payload?.data?.monthlyPlan?.dataSRAll || [])
        .concat(action.payload?.data?.monthlyPlan?.dataQuality || []);

      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.defaultTable = dtTable;
      state.dataTable.monthly = mapDataMonthly(dtTable);
    });
    builder.addCase(getLatestDetailData.rejected, (state, action) => {
      state.shortTermPlanningDetail.loading = false;
      state.shortTermPlanningDetail.error = "Invalid get data";
    });
  },
});

export const {
  clearShortTermPlanningDetail,
  onChangeDataTableMonthly,
  uploadDataTableMonthly,
  onChangeTableWasteInpit,
  createColumnShortPlanningDetail,
  onChangeCounterTableHeader,
  onChangeYear,
  onChangeTableMonthly,
  counterDataTable,
  createDailyData,
  createDailyDataCustom,
  clearDailyTable,
  addUploadDataTableMonthly,
  resetShortTermPlanningDetail,
  replaceDefaultTable,
  contractorPitList,
  onChangeDocument,
  onResetDocument
} = shortTermPlanning.actions;

export const shortTermPlanningSelector = (state) =>
  state.shortTermPlanning.shortTermPlanning;
export const shortTermPlanningDetailSelector = (state) =>
  state.shortTermPlanning.shortTermPlanningDetail;
export const shortTermPlanningDetailColumn = (state) =>
  state.shortTermPlanning.shortTermPlanningDetailColumn;
export const versionListSelector = (state) =>
  state.shortTermPlanning.versionList;
export const contractorByArea = (state) => state.payload.contractorByArea;

export const dataTableSelector = (state) => state.shortTermPlanning.dataTable;
export const dataTableAgreedSelector = (state) =>
  state.shortTermPlanning.dataTableAgreed;
export const dataTableRKABSelector = (state) =>
  state.shortTermPlanning.dataTableRKAB;

export const dataTableBudgetSelector = (state) =>
  state.shortTermPlanning.dataTableBudget;
export const pitListSelector = (state) =>
  state.shortTermPlanning.pitListContractor.contractorPit;
export default shortTermPlanning.reducer;
