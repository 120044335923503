import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrls, get } from "utils/request";

const initialState = {
  dashboardProduction: [],
  delayStatistic: {
    crushingDelayStatisticList: [],
    delayStatisticList: []
  },
  isLoading: false,
  error: null,
  errorMessages: '',
};

export const getProductionJetty = createAsyncThunk(
  'DashboardProductionJetty/getProductionJetty',
  async (params) => {
    const { month, year } = params

    const response = await get(
      `${apiUrls.actualPort}/admin/loading-jetty/dashboard/daily-barging?month=${month}&year=${year}`
    );
    return response?.data;
  }
);

export const getDelayStatistic = createAsyncThunk(
  'DashboardProductionJetty/getDelayStatistic',
  async (params) => {
    const { endDate, startDate, shift } = params

    const response = await get(
      `${apiUrls.actualPortCrushing}/admin/delay-statistic?endDate=${endDate}&startDate=${startDate}&shift=${shift}&`
    );
    return response?.data;
  }
);

const DashboardProductionJettySlice = createSlice({
  name: 'DashboardProductionJetty',
  initialState,
  reducers: {
    resetState: (state) => {
      state.dashboardProduction = []
      state.delayStatistic = {
        crushingDelayStatisticList: [],
        delayStatisticList: []
      }
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
  },
  extraReducers: {
    [getProductionJetty.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getProductionJetty.fulfilled]: (state, action) => {
      let payload = action?.payload || [];

      state.dashboardProduction = payload;
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getProductionJetty.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },

    [getDelayStatistic.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getDelayStatistic.fulfilled]: (state, action) => {
      state.delayStatistic = action.payload;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    [getDelayStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
  }
});

export const { actions } = DashboardProductionJettySlice;
export const { resetState, resetDate, setDate } = actions;
export const dashboardProductionJettySelector = (state) => state.dashboardProductionJetty;
export default DashboardProductionJettySlice.reducer;
