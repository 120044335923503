import _, { isInteger, isNull, isObject, isString, isUndefined } from "lodash";
import isArray from "lodash/isArray";
import moment from "moment-timezone";

export const breakString = (str, lineLength) => {
  let result = "";
  for (let i = 0; i < str.length; i += lineLength) {
    result += str.slice(i, i + lineLength) + "\n";
  }
  return result;
};

export const handleTimeValidation = (
  data,
  validation,
  rejectSubmit,
  jetty = false,
  dataDetail,
  isRom = false
) => {
  if (jetty) {
    const checker = activityChecker(data);
    if (
      !checker?.arrivalPort ||
      !checker?.startJetty ||
      !checker?.sideJetty ||
      !checker?.initialDraft
    ) {
      validation = false;
      rejectSubmit("Please insert all pre loading activity");
    }

    const postLoading = data.findIndex(
      (val) => val.activity === "Post Loading"
    );
    if (postLoading !== -1) {
      if (
        !checker?.completeLoading ||
        !checker?.finalDraft ||
        !checker?.castOfJetty
      ) {
        validation = false;
        rejectSubmit("Please insert all post loading activity");
      }
    }
  }

  data.every((row, index) => {
    const dataPayload = {
      startTime: row?.startTime,
      date: row?.date,
      activity: row?.activity,
      detail: row?.detail,
    };

    let timeCheck = false;

    if (row.detail !== "Complete Cast Off") {
      const otherActivity = data.filter((val, i) => i !== index);
      if (otherActivity?.length > 0) {
        const checker = timeFormatChecker(
          otherActivity,
          dataPayload.startTime,
          dataPayload.date,
          dataPayload
        );
        if (!checker) {
          validation = false;
          rejectSubmit("Activity time same as other activity");
          return false;
        }
      }
    }

    if (row.isEdit) {
      if (jetty) {
        const date = new Date();
        const timeOptions = { hour: "2-digit", minute: "2-digit" };
        const timeFormat = date.toLocaleTimeString("id-ID", timeOptions);

        const timeNow = parseInt(timeFormat?.replace(".", ""));
        const timeInt = parseInt(dataPayload?.startTime?.replace(":", ""));

        timeCheck = activityCompare(timeInt, timeNow, rejectSubmit, data, row);

        if (!timeCheck) {
          validation = false;
          return false;
        }

        const dateIsAfter = moment().isAfter(moment(dataPayload.date), "date");

        if (timeInt > timeNow && !dateIsAfter) {
          validation = false;
          rejectSubmit(
            "The duration of this activity should be lesser than current time"
          );
          return false;
        }
      } else {
        const timeInt = parseInt(dataPayload.startTime.replace(":", ""));
        const timeNow = moment().format("HHmm");
        const dateIsAfter = moment().isAfter(moment(dataDetail.date), "date");

        if (timeInt > timeNow && !dateIsAfter) {
          validation = false;
          rejectSubmit(
            "The duration of this activity should be lesser than current time"
          );
          return false;
        }

        const timeCheck = checkTime(dataPayload.startTime, dataDetail, isRom);
        if (!timeCheck) {
          validation = false;
          rejectSubmit("Save Failed, Activity must be within shift time");
          return false;
        }
      }
    }

    return true;
  });

  return validation;
};

export const timeFormatChecker = (
  data,
  time,
  date = false,
  currentData = {}
) => {
  let sameTime = false;

  if (date) {
    sameTime = data.find(
      (val) =>
        (val.timeFormat === time || val.startTime === time) &&
        val.date === date &&
        val.detail !== "Complete Cast Off"
    );

    if (
      currentData?.detail === "Commence Loading" &&
      sameTime?.detail === "Initial Draft"
    )
      sameTime = false;
    else if (
      currentData?.detail === "Initial Draft" &&
      sameTime?.detail === "Commence Loading"
    )
      sameTime = false;

    if (
      currentData?.detail === "Complete Loading" &&
      sameTime?.detail === "Final Draft"
    )
      sameTime = false;
    else if (
      currentData?.detail === "Final Draft" &&
      sameTime?.detail === "Complete Loading"
    )
      sameTime = false;
  } else
    sameTime = data.find(
      (val) => val.timeFormat === time || val.startTime === time
    );

  if (sameTime) return false;

  return true;
};

const parseTime = (time) => {
  return parseInt(time.replace(":", "")) || null;
};

const handleDateValidation = (
  timeFirst,
  timeSecond,
  dateFirst,
  dateSecond,
  mes1,
  mes2,
  rejectSubmit,
  isSame = false
) => {
  if (moment(dateFirst).isSame(moment(dateSecond))) {
    if (!isSame) {
      if (timeFirst >= timeSecond) {
        rejectSubmit(
          `The duration of ${mes1} should be lesser than the ${mes2} activity`
        );
        return false;
      }
    } else {
      if (timeFirst > timeSecond) {
        rejectSubmit(
          `The duration of ${mes1} should be lesser than the ${mes2} activity`
        );
        return false;
      }
    }
  } else if (moment(dateFirst).isAfter(moment(dateSecond))) {
    rejectSubmit(
      `The duration of ${mes1} should be lesser than the ${mes2} activity`
    );
    return false;
  }

  return true;
};

const dateTimeFormatter = (
  time,
  date,
  maxTime = null,
  maxDate = null,
  minTime = null,
  minDate = null
) => {
  if (maxTime !== null || maxTime === 0) {
    if (maxDate === null) {
      maxDate = date;
      maxTime = time;
    } else if (moment(date).isAfter(moment(maxDate))) {
      maxDate = date;
      maxTime = time;
    } else if (moment(date).isSame(moment(maxDate))) {
      if (time > maxTime) maxTime = time;
    }
  }

  if (minTime !== null || minTime === 0) {
    if (minDate === null) {
      minDate = date;
      minTime = time;
    } else if (moment(date).isBefore(moment(minDate))) {
      minDate = date;
      minTime = time;
    } else if (moment(date).isSame(moment(minDate))) {
      if (minTime === 0) minTime = time;
      else if (time < minTime) minTime = time;
    }
  }

  if (minTime === null) return { maxTime, maxDate };
  else if (maxTime === null) return { minTime, minDate };
  else return { maxTime, maxDate, minTime, minDate };
};

export const activityCompare = (
  timeAfter,
  timeNow,
  rejectSubmit,
  activityData,
  newActivity
) => {
  const inputDate = moment(newActivity?.date);
  const dateNow = moment();

  const arrivalPort = activityData.find((e) => e.detail === "Arrival At Port");
  const startJetty = activityData.find(
    (e) => e.detail === "Start to A/Side Jetty"
  );
  const sideJetty = activityData.find((e) => e.detail === "A/Side Jetty");
  const initialDraft = activityData.find((e) => e.detail === "Initial Draft");
  const bargeRejected = activityData.find((e) => e.detail === "Barge Rejected");
  const waitingLoading = activityData.find(
    (e) => e.detail === "Waiting for loading"
  );
  const prepareLoading = activityData.find(
    (e) => e.detail === "Prepare for loading"
  );

  const completeLoading = activityData.find(
    (e) => e.detail === "Complete Loading"
  );
  const finalDraft = activityData.find((e) => e.detail === "Final Draft");
  const bargeGrounded = activityData.find((e) => e.detail === "Barge Grounded");
  const castOffJetty = activityData.find((e) => e.detail === "Cast off Jetty");

  let maxPreLoadingTime = 0,
    maxLoadingTime = 0,
    minLoadingTime = 0,
    minPostLoadingTime = 0;

  let maxPreLoadingDate = null,
    maxLoadingDate = null,
    minLoadingDate = null,
    minPostLoadingDate = null;

  let maxPreLoadingActivity = null,
    minLoadingActivity = null;

  let arrivalPortTime,
    startJettyTime,
    sideJettyTime,
    initialDraftTime,
    bargeRejectedTime,
    waitingLoadingTime,
    prepareLoadingTime;
  let arrivalPortDate,
    startJettyDate,
    sideJettyDate,
    initialDraftDate,
    bargeRejectedDate,
    waitingLoadingDate,
    prepareLoadingDate;

  if (arrivalPort) {
    arrivalPortTime = parseTime(arrivalPort.startTime);
    arrivalPortDate = arrivalPort.date;
  }
  if (startJetty) {
    startJettyTime = parseTime(startJetty.startTime);
    startJettyDate = startJetty.date;
  }
  if (sideJetty) {
    sideJettyTime = parseTime(sideJetty.startTime);
    sideJettyDate = sideJetty.date;
  }
  if (initialDraft) {
    initialDraftTime = parseTime(initialDraft.startTime);
    initialDraftDate = initialDraft.date;
  }
  if (bargeRejected) {
    bargeRejectedTime = parseTime(bargeRejected.startTime);
    bargeRejectedDate = bargeRejected.date;
  }
  if (waitingLoading) {
    waitingLoadingTime = parseTime(waitingLoading.startTime);
    waitingLoadingDate = waitingLoading.date;
  }
  if (prepareLoading) {
    prepareLoadingTime = parseTime(prepareLoading.startTime);
    prepareLoadingDate = prepareLoading.date;
  }

  let completeLoadingTime, finalDraftTime, bargeGroundedTime, castOffJettyTime;
  let completeLoadingDate, finalDraftDate, bargeGroundedDate, castOffJettyDate;

  if (completeLoading) {
    completeLoadingTime = parseTime(completeLoading.startTime);
    completeLoadingDate = completeLoading.date;
  }
  if (finalDraft) {
    finalDraftTime = parseTime(finalDraft.startTime);
    finalDraftDate = finalDraft.date;
  }
  if (bargeGrounded) {
    bargeGroundedTime = parseTime(bargeGrounded.startTime);
    bargeGroundedDate = bargeGrounded.date;
  }
  if (castOffJetty) {
    castOffJettyTime = parseTime(castOffJetty.startTime);
    castOffJettyDate = castOffJetty.date;
  }

  // get max and min existing activity
  for (var val of sortActivity(activityData, true, true)) {
    let time = 0,
      date = null;

    time = parseTime(val.startTime);
    date = val.date;

    if (val.activity === "Pre Loading (Preparation)") {
      const r = dateTimeFormatter(
        time,
        date,
        maxPreLoadingTime,
        maxPreLoadingDate
      );

      maxPreLoadingTime = r.maxTime;
      maxPreLoadingDate = r.maxDate;
      maxPreLoadingActivity = val.detail;
    } else if (val.activity === "Loading") {
      const r = dateTimeFormatter(
        time,
        date,
        maxLoadingTime,
        maxLoadingDate,
        minLoadingTime,
        minLoadingDate
      );

      maxLoadingTime = r.maxTime;
      maxLoadingDate = r.maxDate;
      minLoadingTime = r.minTime;
      minLoadingDate = r.minDate;
      if (!minLoadingActivity) minLoadingActivity = val.detail;
    } else if (val.activity === "Post Loading") {
      const r = dateTimeFormatter(
        time,
        date,
        null,
        null,
        minPostLoadingTime,
        minPostLoadingDate
      );

      minPostLoadingTime = r.minTime;
      minPostLoadingDate = r.minDate;
    }
  }

  if (inputDate.isAfter(dateNow, "days")) {
    rejectSubmit(
      "The duration of this activity should be lesser than current time"
    );
    return false;
  } else if (inputDate.isSame(dateNow, "days")) {
    if (timeAfter > timeNow) {
      rejectSubmit(
        "The duration of this activity should be lesser than current time"
      );
      return false;
    }
  }

  switch (newActivity.activity) {
    case "Pre Loading (Preparation)":
      if (arrivalPort && startJetty) {
        if (
          !handleDateValidation(
            arrivalPortTime,
            startJettyTime,
            arrivalPortDate,
            startJettyDate,
            "Arrival At Port",
            "Start Jetty",
            rejectSubmit
          )
        )
          return false;
      }

      if (startJetty && sideJetty) {
        if (
          !handleDateValidation(
            startJettyTime,
            sideJettyTime,
            startJettyDate,
            sideJettyDate,
            "Start Jetty",
            "Side Jetty",
            rejectSubmit
          )
        )
          return false;
      }

      if (initialDraft) {
        if (
          !handleDateValidation(
            sideJettyTime,
            initialDraftTime,
            sideJettyDate,
            initialDraftDate,
            "Side Jetty",
            "Initial Draft",
            rejectSubmit
          )
        )
          return false;
      }

      if (bargeRejected) {
        if (
          !handleDateValidation(
            initialDraftTime,
            bargeRejectedTime,
            initialDraftDate,
            bargeRejectedDate,
            "Initial Draft",
            "Barge Rejected",
            rejectSubmit
          )
        )
          return false;
      }

      if (waitingLoading) {
        if (bargeRejected) {
          if (
            !handleDateValidation(
              bargeRejectedTime,
              waitingLoadingTime,
              bargeRejectedDate,
              waitingLoadingDate,
              "Barge Rejected",
              "Waiting for Loading",
              rejectSubmit
            )
          )
            return false;
        } else {
          if (
            !handleDateValidation(
              initialDraftTime,
              waitingLoadingTime,
              initialDraftDate,
              waitingLoadingDate,
              "Initial Draft",
              "Waiting for Loading",
              rejectSubmit
            )
          )
            return false;
        }
      }

      if (prepareLoading) {
        if (
          !handleDateValidation(
            waitingLoadingTime,
            prepareLoadingTime,
            waitingLoadingDate,
            prepareLoadingDate,
            "Waiting for Loading",
            "Prepare for Loading",
            rejectSubmit
          )
        )
          return false;
      }

      if (minLoadingTime !== 0) {
        const isCommence =
          maxPreLoadingActivity === "Initial Draft" &&
            minLoadingActivity === "Commence Loading"
            ? true
            : false;

        if (!isCommence) {
          if (
            !handleDateValidation(
              timeAfter,
              minLoadingTime,
              inputDate,
              minLoadingDate,
              "Pre Loading",
              "Loading",
              rejectSubmit
            )
          )
            return false;
        }
      } else if (minPostLoadingTime !== 0) {
        if (
          !handleDateValidation(
            timeAfter,
            minPostLoadingTime,
            inputDate,
            minPostLoadingDate,
            "Pre Loading",
            "Post Loading",
            rejectSubmit
          )
        )
          return false;
      }

      break;
    case "Loading":
      if (maxPreLoadingTime !== 0) {
        const isCommence =
          maxPreLoadingActivity === "Initial Draft" &&
            minLoadingActivity === "Commence Loading"
            ? true
            : false;

        if (!isCommence) {
          if (
            !handleDateValidation(
              maxPreLoadingTime,
              timeAfter,
              maxPreLoadingDate,
              inputDate,
              "Pre Loading",
              "Loading",
              rejectSubmit
            )
          )
            return false;
        }
      }

      if (minPostLoadingTime !== 0) {
        if (
          !handleDateValidation(
            timeAfter,
            minPostLoadingTime,
            inputDate,
            minPostLoadingDate,
            "Loading",
            "Post Loading",
            rejectSubmit
          )
        )
          return false;
      }

      break;
    case "Post Loading":
      if (maxLoadingTime !== 0) {
        if (
          !handleDateValidation(
            maxLoadingTime,
            timeAfter,
            maxLoadingDate,
            inputDate,
            "Loading",
            "Post Loading",
            rejectSubmit
          )
        )
          return false;
      } else if (maxPreLoadingTime !== 0) {
        if (
          !handleDateValidation(
            maxPreLoadingTime,
            timeAfter,
            maxPreLoadingDate,
            inputDate,
            "Pre Loading",
            "Post Loading",
            rejectSubmit
          )
        )
          return false;
      }

      if (completeLoading && finalDraft) {
        if (
          !handleDateValidation(
            completeLoadingTime,
            finalDraftTime,
            completeLoadingDate,
            finalDraftDate,
            "Complete Loading",
            "Final Draft",
            rejectSubmit,
            true
          )
        )
          return false;
      }

      if (bargeGrounded) {
        if (
          !handleDateValidation(
            finalDraftTime,
            bargeGroundedTime,
            finalDraftDate,
            bargeGroundedDate,
            "Final Draft",
            "Barge Grounded",
            rejectSubmit
          )
        )
          return false;
      }

      if (castOffJetty) {
        if (bargeGrounded) {
          if (
            !handleDateValidation(
              bargeGroundedTime,
              castOffJettyTime,
              bargeGroundedDate,
              castOffJettyDate,
              "Barge Grounded",
              "Cast off Jetty",
              rejectSubmit
            )
          )
            return false;
        } else {
          if (
            !handleDateValidation(
              finalDraftTime,
              castOffJettyTime,
              finalDraftDate,
              castOffJettyDate,
              "Final Draft",
              "Cast off Jetty",
              rejectSubmit
            )
          )
            return false;
        }
      }

      break;
  }

  return true;
};

export const activityChecker = (activity) => {
  const obj = {
    arrivalPort: false,
    startJetty: false,
    sideJetty: false,
    initialDraft: false,
    waitingLoading: false,
    prepareLoading: false,
    finalDraft: false,
    castOfJetty: false,
    commenceLoading: false,
    compeleteCastOff: false,
    completeLoading: false,
    bargeGrounded: false,
    bargeRejected: false,
  };

  const arrivalPortIndex = activity.findIndex(
    (e) => e.detail === "Arrival At Port"
  );
  const startJettyIndex = activity.findIndex(
    (e) => e.detail === "Start to A/Side Jetty"
  );
  const sideJettyIndex = activity.findIndex((e) => e.detail === "A/Side Jetty");
  const initialDraftIndex = activity.findIndex(
    (e) => e.detail === "Initial Draft"
  );
  const waitingLoadingIndex = activity.findIndex(
    (e) => e.detail === "Waiting for loading"
  );
  const prepareLoadingIndex = activity.findIndex(
    (e) => e.detail === "Prepare for loading"
  );
  const finalDraftIndex = activity.findIndex((e) => e.detail === "Final Draft");
  const castOfJettyIndex = activity.findIndex(
    (e) => e.detail === "Cast off Jetty"
  );
  const commenceLoadingIndex = activity.findIndex(
    (e) => e.detail === "Commence Loading"
  );
  const compeleteCastOffIndex = activity.findIndex(
    (e) => e.detail === "Complete Cast Off"
  );
  const completeLoadingIndex = activity.findIndex(
    (e) => e.detail === "Complete Loading"
  );
  const bargeGroundedIndex = activity.findIndex(
    (e) => e.detail === "Barge Grounded"
  );
  const bargeRejectedIndex = activity.findIndex(
    (e) => e.detail === "Barge Rejected"
  );

  if (arrivalPortIndex !== -1) obj.arrivalPort = true;
  if (startJettyIndex !== -1) obj.startJetty = true;
  if (sideJettyIndex !== -1) obj.sideJetty = true;
  if (initialDraftIndex !== -1) obj.initialDraft = true;
  if (waitingLoadingIndex !== -1) obj.waitingLoading = true;
  if (prepareLoadingIndex !== -1) obj.prepareLoading = true;
  if (finalDraftIndex !== -1) obj.finalDraft = true;
  if (castOfJettyIndex !== -1) obj.castOfJetty = true;
  if (commenceLoadingIndex !== -1) obj.commenceLoading = true;
  if (compeleteCastOffIndex !== -1) obj.compeleteCastOff = true;
  if (completeLoadingIndex !== -1) obj.completeLoading = true;
  if (bargeGroundedIndex !== -1) obj.bargeGrounded = true;
  if (bargeRejectedIndex !== -1) obj.bargeRejected = true;

  return obj;
};

export const activityMapper = (
  activityDropdown,
  preLoadingList,
  loadingList,
  postLoadingList,
  checker,
  dataDetail
) => {
  const commenceLoadingIndex = loadingList.findIndex(
    (val) => val.label === "Commence Loading"
  );

  if (!checker.arrivalPort && dataDetail?.type === "Direct Barge") {
    activityDropdown[0].show = true;
    preLoadingList[0].show = true;
  } else if (!checker.startJetty) {
    activityDropdown[0].show = true;
    preLoadingList[1].show = true;
  } else if (!checker.sideJetty) {
    activityDropdown[0].show = true;
    preLoadingList[2].show = true;
  } else if (!checker.initialDraft) {
    activityDropdown[0].show = true;
    preLoadingList[3].show = true;
  }

  if (checker.initialDraft) {
    if (!checker.bargeRejected) preLoadingList[4].show = true;
    if (!checker.waitingLoading) preLoadingList[5].show = true;
    if (!checker.prepareLoading && checker.waitingLoading)
      preLoadingList[6].show = true;
    if (!checker.prepareLoading) activityDropdown[0].show = true;

    activityDropdown[1].show = true;
    loadingList.forEach((e) => (e.show = true));
    activityDropdown[2].show = true;

    if (!checker.completeLoading) postLoadingList[0].show = true;
    else if (checker.completeLoading && !checker.finalDraft)
      postLoadingList[1].show = true;
    else if (checker.completeLoading && checker.finalDraft) {
      if (!checker.bargeGrounded) postLoadingList[2].show = true;
      if (!checker.castOfJetty) postLoadingList[3].show = true;
    }

    if (
      checker.completeLoading &&
      checker.finalDraft &&
      checker.castOfJetty &&
      !checker.compeleteCastOff
    )
      postLoadingList[4].show = true;
  }

  if (
    checker.completeLoading &&
    checker.finalDraft &&
    checker.bargeGrounded &&
    checker.castOfJetty &&
    checker.compeleteCastOff
  )
    activityDropdown[2].show = false;

  if (checker.commenceLoading) loadingList[commenceLoadingIndex].show = false;
};

export const checkTime = (time, data, isRom = false) => {
  const timeInt = parseInt(time?.replace(":", ""));

  if (data?.shift === "Night") {
    if (isRom) {
      if (timeInt < 1900 && timeInt >= 800) return false;
    } else {
      if (timeInt < 1800 && timeInt >= 700) return false;
    }
  } else if (data?.shift === "Day") {
    if (isRom) {
      if (timeInt >= 2000 || timeInt < 700) return false;
    } else {
      if (timeInt >= 1900 || timeInt < 600) return false;
    }
  }

  return true;
};

export const checkDuplicateActivity = (data) => {
  const duplicate = [];

  data.map((e, i) => {
    const nextData = data[i + 1];

    if (
      nextData &&
      e.activity === nextData.activity &&
      e.detail === nextData.detail &&
      e?.subDetail === nextData?.subDetail
    ) {
      duplicate.push(nextData.id);
    }
  });

  return duplicate;
};

export const checkApproval = (approval) => {
  if (approval.length > 0 && approval[0]?.userLevelList?.length > 0) {
    const userList = approval[0]?.userLevelList;
    const findUser = userList.findIndex(
      (e) => e.email === getFromToken("email")
    );

    if (findUser !== -1) return true;
  }

  return false;
};

export const getFromToken = (key) => {
  const token = localStorage.getItem("token");

  let val = "";
  if (token) {
    const parsedToken = JSON.parse(token);
    val = parsedToken?.data?.[key];
  }

  return val;
};

export const subDetailOption = (detail, entity = null) => {
  if (entity === "rom") {
    switch (detail) {
      case "Schedule":
        return optionMapper(["Electric Schedule", "Mechanical Schedule"]);
      case "Unschedule":
        return optionMapper(["Electric Unschedule", "Mechanical Unschedule"]);
      default:
        return [];
    }
  } else {
    switch (detail) {
      case "Without Cargo":
        return optionMapper(["Running kosongan"]);
      case "Schedule":
        return optionMapper(["PM 3 jam", "PM 6 jam", "Shutdown"]);
      case "Cargo Unavailable":
        return optionMapper(["Waiting hauling"]);
      case "Bolder (COS)":
        return optionMapper(["COS"]);
      case "Rain (Bad weather)":
        return optionMapper(["Hujan deras"]);
      case "HE/ Equipment Support Problem":
        return optionMapper([
          "A2B breakdown",
          "No operator A2B",
          "Jumlah A2B kurang",
          "A2B telat datang",
        ]);
      case "Dust Suppression Problem":
        return optionMapper([
          "Problem instalasi spraying",
          "Supply air kurang",
        ]);
      case "Operator Crusher Unavailable":
        return optionMapper(["No crew panel CP"]);
      case "System Fault":
        return optionMapper(["BDS", "PWS", "BRS", "MD"]);
      case "Safety Talk":
        return optionMapper(["Weekly safety talk", "General safety talk"]);
      case "Rest/Pray/Meal":
        return optionMapper(["Ishoma", "Sholat Jumát", "Buka Puasa", "Sahur"]);
      case "Crusher Standby":
        return optionMapper([
          "Full space",
          "Maintenance cargo oksidasi",
          "Waiting barge",
          "Shifting barge",
          "Sampling size",
          "Bad weather di laut",
          "Safety device loading plant aktif",
          "Problem loading plant",
          "Unit hauling breakdown depan CP",
          "Libur nasional",
          "Stock opname",
          "Penyekrapan depan hopper",
          "Penyiraman depan hopper",
          "Settingan under",
        ]);
      case "Coal spillage/blocking/cleaning":
        return optionMapper(["Blocking", "Cleaning"]);
      case "Operation's Incident":
        return optionMapper(["DT Hauling Incident", "Breakdown"]);
      default:
        return [];
    }
  }
};

export const formatNumberAlphabet = (num, precision = 0) => {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
};

export const formatNumberAlphabetNew = (num, precision = 0) => {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    let numb = 0;
    if (found.suffix === "T") {
      numb = findValueCurrency(num, 1000000000000);
      if (numb > 1) {
        precision = 0;
      }
    } else if (found.suffix === "B") {
      numb = findValueCurrency(num, 1000000000);
      if (numb > 1) {
        precision = 0;
      }
    } else if (found.suffix === "M") {
      numb = findValueCurrency(num, 1000000);
      if (numb > 1) {
        precision = 0;
      }
    } else if (found.suffix === "K") {
      numb = findValueCurrency(num, 1000);
      if (numb > 1) {
        precision = 0;
      }
    } else {
      precision = 0;
    }
    let formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    if (precision === 1) {
      formatted = Math.floor((num / found.threshold) * 10) / 10 + found.suffix;
    }
    return formatted;
  }

  return num.toString();
};

export const findValueCurrency = (currency, divisor) => {
  return Math.floor(currency / divisor);
};

export const roleListChecker = (data, role) => {
  if (role === "Admin Contractor") {
    return data.filter(
      (val) =>
        val.name !== "Admin BIB" &&
        val.name !== "Super Admin" &&
        val.name !== "Custom"
    );
  } else if (role === "Admin BIB") {
    return data.filter((val) => val.name !== "Super Admin");
  } else if (role === "Admin Mobile") {
    return data.filter(
      (val) => val.name !== "Super Admin" || val.name !== "Admin BIB"
    );
  } else if (
    role === "Creator" ||
    role === "Approver" ||
    role === "Viewer" ||
    role === "Custom"
  ) {
    return data.filter(
      (val) =>
        val.name !== "Super Admin" ||
        val.name !== "Admin BIB" ||
        val.name !== "Admin Contractor" ||
        val.name !== "Admin Mobile"
    );
  }

  return data;
};

export const masterDataChecker = (module) => {
  switch (module) {
    case "general":
      if (permissionChecker(`Master Data : General - Location`, "view", false))
        return "/master-data/loading-dumping";
      else if (
        permissionChecker(`Master Data : General - Equipment`, "view", false)
      )
        return "/master-data/equipment";
      else if (
        permissionChecker(`Master Data : General - Contractor`, "view", false)
      )
        return "/master-data/contractor";
      else if (
        permissionChecker(
          `Master Data : General - Contractor Assigment`,
          "view",
          false
        )
      )
        return "/master-data/assignment-contractor";
      return "";
    case "pit":
      if (permissionChecker(`Master Data : Pit - Sub Location`, "view", false))
        return "/master-data/sub-location";
      else if (
        permissionChecker(`Master Data : Pit - Equipment`, "view", false)
      )
        return "/master-data/detail-equipment";
      return "";
    case "rom":
      if (permissionChecker(`Master Data : ROM - Equipment`, "view", false))
        return "/master-data/rom-equipment";
      return "";
    case "haul":
      if (
        permissionChecker(
          `Master Data : Haul Road - Hauling Equipment`,
          "view",
          false
        )
      )
        return "/master-data/haul-equipment";
      return "";
    case "port":
      if (permissionChecker(`Master Data : Port - Crusher`, "view", false))
        return "/master-data/crusher";
      else if (permissionChecker(`Master Data : Port - BLC`, "view", false))
        return "/master-data/jetty";
      return "";
    default:
      return "";
  }
};

export const permissionChecker = (modulCode, permission, alert = true) => {
  let isAllowed = false;
  const data = JSON.parse(localStorage.getItem("token"));
  if (data) {
    const userPermission = data.data.permission;
    const checkPermission = userPermission.filter(
      (val) => val.name === modulCode
    );

    if (checkPermission.length > 0 && checkPermission[0][permission])
      isAllowed = true;
  }

  if (!isAllowed) {
    if (alert) alert("You do not have permission to do this action");
    return false;
  }
  return true;
};

export const userRoleDataChecker = (
  activeTab,
  activeTabIndex,
  actionRoles,
  checkBox
) => {
  let roles = [];

  const mobileApplication = actionRoles.filter(
    (e) => e.modulApp === "MOBILE_APPLICATION"
  );
  const webApplication = actionRoles.filter(
    (e) => e.modulApp === "WEB_APPLICATION"
  );

  if (activeTab === "mobile") {
    switch (activeTabIndex) {
      case 0: {
        roles = mobileApplication.filter((e) => e.modulCode === "CRUSHING");
        roles.unshift({
          isCheckBox: true,
          modulCode: "CRUSHING",
          name: checkBox.CRUSHING,
        });
        return roles;
      }
      case 1: {
        roles = mobileApplication.filter((e) => e.modulCode === "LOADING");
        roles.unshift({
          isCheckBox: true,
          modulCode: "LOADING",
          name: checkBox.LOADING,
        });
        return roles;
      }
      case 2: {
        roles = mobileApplication.filter((e) => e.modulCode === "BARGING");
        roles.unshift({
          isCheckBox: true,
          modulCode: "BARGING",
          name: checkBox.BARGING,
        });
        return roles;
      }
      case 4: {
        roles = mobileApplication.filter(
          (e) => e.modulCode === "ROM" && e.modulApp === "MOBILE_APPLICATION"
        );
        roles.unshift({
          isCheckBox: true,
          modulCode: "ROM",
          name: checkBox.ROM,
        });
        return roles;
      }
      case 5: {
        roles = mobileApplication.filter(
          (e) => e.modulCode === "REPORT" && e.modulApp === "MOBILE_APPLICATION"
        );
        roles.unshift({
          isCheckBox: true,
          modulCode: "REPORT",
          name: checkBox.REPORT,
        });
        return roles;
      }
      default:
        return [];
    }
  } else if (activeTab === "web") {
    switch (activeTabIndex) {
      case 0: {
        roles = webApplication.filter((e) => e.modulCode === "PIT");
        roles.unshift({
          isCheckBox: true,
          modulCode: "PIT",
          name: checkBox.PIT,
        });
        return roles;
      }
      case 1: {
        roles = webApplication.filter((e) => e.modulCode === "ROM");
        roles.unshift({
          isCheckBox: true,
          modulCode: "ROM",
          name: checkBox.ROM,
        });
        return roles;
      }
      case 2: {
        roles = webApplication.filter((e) => e.modulCode === "HAUL_ROAD");
        roles.unshift({
          isCheckBox: true,
          modulCode: "HAUL_ROAD",
          name: checkBox.HAUL_ROAD,
        });
        return roles;
      }
      case 3: {
        roles = webApplication.filter((e) => e.modulCode === "PORT");
        roles.unshift({
          isCheckBox: true,
          modulCode: "PORT",
          name: checkBox.PORT,
        });
        return roles;
      }
      case 4: {
        roles = webApplication.filter((e) => e.modulCode === "OFFSHORE");
        roles.unshift({
          isCheckBox: true,
          modulCode: "OFFSHORE",
          name: checkBox.OFFSHORE,
        });
        return roles;
      }
      case 5: {
        roles = webApplication.filter((e) => e.modulCode === "SALES");
        roles.unshift({
          isCheckBox: true,
          modulCode: "SALES",
          name: checkBox.SALES,
        });
        return roles;
      }
      case 6: {
        roles = webApplication.filter((e) => e.modulCode === "MASTER_DATA");
        roles.unshift({
          isCheckBox: true,
          modulCode: "MASTER_DATA",
          name: checkBox.MASTER_DATA,
        });
        return roles;
      }
      case 7: {
        roles = webApplication.filter((e) => e.modulCode === "CONFIGURATION");
        roles.unshift({
          isCheckBox: true,
          modulCode: "CONFIGURATION",
          name: checkBox.CONFIGURATION,
        });
        return roles;
      }
      case 8: {
        roles = webApplication.filter((e) => e.modulCode === "REPORT");
        roles.unshift({
          isCheckBox: true,
          modulCode: "REPORT",
          name: checkBox.REPORT,
        });
        return roles;
      }
      default:
        return [];
    }
  }
};

export const getRoleValue = (role) => {
  switch (role) {
    case "Admin BIB":
      return "ADMIN_BIB";
    case "Viewer":
      return "VIEWER";
    case "Creator":
      return "CREATOR";
    case "Approver":
      return "APPROVER";
    case "Admin Contractor":
      return "ADMIN_CONTRACTOR";
    case "Custom":
      return "CUSTOM";
    case "Admin Mobile":
      return "ADMIN_MOBILE";
    case "Super Admin":
      return "ADMIN_SUPER";
    case "Captain Contractor":
      return "ADMIN_MOBILE";
    case "Creator Contractor":
      return "CREATOR";
  }
};
export const checkCompany = () => {
  if (getCompany() === "Borneo Indobara") return true;
  return false;
};

export const numberPattern = /^[0-9]+$/;

export const specialCharacterRegEx = new RegExp(
  /^[\s!@#$%^&*()_+\-=\[\]{};':"\|,.<>\/?]+$/
);

export const getClassByColor = (color) => {
  switch (color) {
    case "red":
      return "danger";
    case "yellow":
      return "warning";
    case "green":
      return "safe";
    case "purple":
      return "purple";
    case "blue":
      return "blue";
  }
};

export const calculatePort = (
  detailPortPlan,
  recordsPortConfig,
  port,
  portType,
  tph = 0,
  ton = 0,
  pa = 0,
  ma = 0,
  ua = 0,
  eu = 0
) => {
  let obj = {
    tph: 0,
    ton: 0,
    pa: 0,
    ma: 0,
    ua: 0,
    eu: 0,
    sumPATPH: 0,
    sumMATPH: 0,
    sumUATPH: 0,
    sumEUTPH: 0,
    tonColor: "red",
    tphColor: "red",
    paColor: "red",
    maColor: "red",
    uaColor: "red",
    euColor: "red",
  };
  let productivityTarget = 0,
    production = 0,
    runningActivity = 0,
    sumTotalPA = 0,
    sumTotalMA = 0,
    sumTotalUA = 0,
    sumTotalEU = 0,
    PAActivity = 0,
    MAActivity = 0,
    UAActivity = 0,
    EUActivity = 0,
    sumPATPH = 0,
    sumMATPH = 0,
    sumUATPH = 0,
    sumEUTPH = 0;

  // let portData = detailPortPlan.id ? detailPortPlan : recordsPortConfig
  let portData = recordsPortConfig;

  if (portType === "crusher" || portType === "rom") {
    // if (detailPortPlan.id) {
    //   portData.planList.map(e => {
    //     sumPATPH += e?.performanceTarget?.pa * e?.production.productivityTarget
    //     sumMATPH += e?.performanceTarget?.ma * e?.production.productivityTarget
    //     sumUATPH += e?.performanceTarget?.ua * e?.production.productivityTarget
    //     sumEUTPH += e?.performanceTarget?.eu * e?.production.productivityTarget
    //   })

    // portData = portData.planList.filter(e => e.crusherPlant.crusher.id === port.id)

    // PAActivity = portData[0]?.performanceTarget?.pa || 0
    // MAActivity = portData[0]?.performanceTarget?.ma || 0
    // UAActivity = portData[0]?.performanceTarget?.ua || 0
    // EUActivity = portData[0]?.performanceTarget?.eu || 0
    // productivityTarget = portData[0]?.production.productivityTarget || 0
    // runningActivity = portData[0]?.crusherActivity.running || 0
    // }
    // else {
    portData.map((e) => {
      sumPATPH += e?.bib?.pa * e?.productivityTarget;
      sumMATPH += e?.bib?.ma * e?.productivityTarget;
      sumUATPH += e?.bib?.ua * e?.productivityTarget;
      sumEUTPH += e?.bib?.eu * e?.productivityTarget;
    });

    portData = portData.filter(
      (e) => (e?.crusher?.id || e?.rom?.id) === port?.id
    );

    PAActivity = portData[0]?.bib?.pa || 0;
    MAActivity = portData[0]?.bib?.ma || 0;
    UAActivity = portData[0]?.bib?.ua || 0;
    EUActivity = portData[0]?.bib?.eu || 0;
    productivityTarget = portData[0]?.productivityTarget || 0;
    // }
  } else {
    // if (detailPortPlan.id) {
    //   production = detailPortPlan.detail.reduce(
    //     (prev, curr) => prev + curr?.production?.total,
    //     0
    //   );

    //   productivityTarget = Math.round((100 * production) / 12) / 100;

    //   detailPortPlan.detail.map(val => {
    //     sumTotalPA += (val?.production?.total * val?.performanceTarget?.pa)
    //     sumTotalMA += (val?.production?.total * val?.performanceTarget?.ma)
    //     sumTotalUA += (val?.production?.total * val?.performanceTarget?.ua)
    //     sumTotalEU += (val?.production?.total * val?.performanceTarget?.eu)
    //   })
    // portData = portData?.detail.filter(e => e.jetty === port.title)

    // PAActivity = portData[0]?.bib?.pa || 0
    // MAActivity = portData[0]?.bib?.ma || 0
    // UAActivity = portData[0]?.bib?.ua || 0
    // EUActivity = portData[0]?.bib?.eu || 0
    // }
    // else {
    recordsPortConfig.map((val) => {
      productivityTarget += val.productivityTarget;
      production += val.productivityTarget * 12 * (val.bib.eu / 100);
      sumTotalPA +=
        val.productivityTarget * 12 * (val.bib.eu / 100) * val.bib.pa;
      sumTotalMA +=
        val.productivityTarget * 12 * (val.bib.eu / 100) * val.bib.ma;
      sumTotalUA +=
        val.productivityTarget * 12 * (val.bib.eu / 100) * val.bib.ua;
      sumTotalEU +=
        val.productivityTarget * 12 * (val.bib.eu / 100) * val.bib.eu;
    });
    portData = portData.filter((e) => e.jettyLoading.id === port.id);

    PAActivity = portData[0]?.bib?.pa || 0;
    MAActivity = portData[0]?.bib?.ma || 0;
    UAActivity = portData[0]?.bib?.ua || 0;
    EUActivity = portData[0]?.bib?.eu || 0;
    // }
  }
  if (portType === "crusher" || portType === "rom") {
    // if (detailPortPlan.id) {
    //   obj.tonColor = calculateColor(ton, productivityTarget * runningActivity);
    //   obj.ton = productivityTarget * runningActivity;
    // }
    // else {
    obj.tonColor = calculateColor(
      ton,
      productivityTarget * 12 * (EUActivity / 100)
    );
    obj.ton = productivityTarget * 12 * (EUActivity / 100);
    // }
  } else {
    obj.tonColor = calculateColor(ton, production);
    obj.ton = production;
  }

  obj.tphColor = calculateColor(tph, productivityTarget);
  obj.tph = productivityTarget;

  obj.paColor = calculateColor(pa, PAActivity, true);
  obj.pa = PAActivity;

  obj.maColor = calculateColor(ma, MAActivity, true);
  obj.ma = MAActivity;

  obj.uaColor = calculateColor(ua, UAActivity, true);
  obj.ua = UAActivity;

  obj.euColor = calculateColor(eu, EUActivity, true);
  obj.eu = EUActivity;

  obj.sumPATPH = sumPATPH;
  obj.sumMATPH = sumMATPH;
  obj.sumUATPH = sumUATPH;
  obj.sumEUTPH = sumEUTPH;

  return obj;
};

export const calculateColor = (data, dataCompare, activity = false) => {
  if (isNaN(data) || isNaN(dataCompare)) return "red";

  if (data === 0 && dataCompare === 0) return "red";

  if (activity) {
    if (data >= dataCompare) return "green";
    else if (data >= dataCompare * 0.9 && data < dataCompare) return "yellow";
    else if (data < dataCompare * 0.9) return "red";
  }

  if (data >= dataCompare * 1.1) {
    return "purple";
  } else if (data >= dataCompare && data < dataCompare * 1.1) {
    return "green";
  } else if (data >= dataCompare * 0.9 && data < dataCompare) {
    return "yellow";
  } else if (data < dataCompare * 0.9) {
    return "red";
  }
};

export const tabWasteCoal = (path, id, isEdit) => {
  return [
    {
      title: "Rain & Slippery",
      nav: isEdit
        ? `/pit-production/${path}/rain-slipperly/edit/${id}`
        : isNaN(id)
          ? `/pit-production/${path}/rain-slipperly/create-report`
          : `/pit-production/${path}/rain-slipperly/${id}`,
    },
    {
      title: "Production",
      nav: isEdit
        ? `/pit-production/${path}/production/edit/${id}`
        : isNaN(id)
          ? `/pit-production/${path}/production/create-report`
          : `/pit-production/${path}/production/${id}`,
    },
    // {
    //   title: "Maintenance Unit",
    //   nav: isEdit ?
    //     `/pit-production/${path}/maintenance-unit/edit/${id}` :
    //     isNaN(id) ?
    //       `/pit-production/${path}/maintenance-unit/create-report` :
    //       `/pit-production/${path}/maintenance-unit/${id}`
    // },
  ];
};

export const tabMasterData = (type) => {
  let arr = [];

  switch (type) {
    case "general":
      if (permissionChecker(`Master Data : General - Location`, "view", false))
        arr.push({
          title: "Location",
          nav: "/master-data/loading-dumping",
        });
      if (permissionChecker(`Master Data : General - Equipment`, "view", false))
        arr.push({
          title: "Equipment",
          nav: "/master-data/equipment",
        });
      if (
        permissionChecker(`Master Data : General - Contractor`, "view", false)
      )
        arr.push({
          title: "Contractor",
          nav: "/master-data/contractor",
        });
      if (
        permissionChecker(
          `Master Data : General - Contractor Assigment`,
          "view",
          false
        )
      )
        arr.push({
          title: "Contractor Assignment",
          nav: "/master-data/assignment-contractor",
        });

      return arr;
    case "pit":
      if (permissionChecker(`Master Data : Pit - Sub Location`, "view", false))
        arr.push({
          title: "Sub Location",
          nav: "/master-data/sub-location",
        });
      if (permissionChecker(`Master Data : Pit - Equipment`, "view", false))
        arr.push({
          title: "Pit Equipment",
          nav: "/master-data/detail-equipment",
        });

      return arr;
    case "rom":
      if (permissionChecker(`Master Data : ROM - Equipment`, "view", false))
        arr.push({
          title: "ROM Equipment",
          nav: "/master-data/rom-equipment",
        });

      if (permissionChecker(`Master Data : ROM - Equipment`, "view", false))
        arr.push({
          title: "ROM",
          nav: "/master-data/rom-port",
        });

      if (true) {
        // if (permissionChecker(`Master Data : ROM - P2H`, "view", false)) {
        arr.push({
          title: "P2H",
          nav: "/master-data/rom-p2h",
        });
      }

      return arr;
    case "haul":
      if (
        permissionChecker(
          `Master Data : Haul Road - Hauling Equipment`,
          "view",
          false
        )
      )
        arr.push({
          title: "Hauling Equipment",
          nav: "/master-data/haul-equipment",
        });

      return arr;
    case "port":
      if (permissionChecker(`Master Data : Port - Crusher`, "view", false))
        arr.push({
          title: "Crusher",
          nav: "/master-data/crusher",
        });
      if (permissionChecker(`Master Data : Port - BLC`, "view", false))
        arr.push({
          title: "BLC",
          nav: "/master-data/jetty",
        });
      if (permissionChecker(`Master Data : Port - BLC`, "view", false)) {
        arr.push({
          title: "P2H",
          nav: "/master-data/p2h",
        });
      }

      return arr;
    default:
      return [];
  }
};

//export const tabDataPair = { Agreed: "data", RKAB: "rkab", Budget: "budget" };
export const tabShortTermPlanning = (path, id, isEdit, isDetail) => {
  return [
    // {
    //   title: "Monthly Plan",
    //   nav: isEdit
    //     ? `/pit-production/${path}/${id}/monthly-plan/edit`
    //     : isDetail
    //       ? `/pit-production/${path}/${id}/monthly-plan/detail`
    //       : isNaN(id)
    //         ? `/pit-production/${path}/create-report/monthly-plan`
    //         : `/pit-production/${path}/${id}/monthly-plan`,
    // },
    {
      title: "Agreed",
      val: "agreed",
      nav: isEdit
        ? `/pit-production/${path}/${id}/agreed/edit`
        : isDetail
          ? `/pit-production/${path}/${id}/agreed/detail`
          : isNaN(id)
            ? `/pit-production/${path}/create-report/agreed`
            : `/pit-production/${path}/${id}/agreed`,
    },
    {
      title: "RKAB",
      val: "rkab",
      nav: isEdit
        ? `/pit-production/${path}/${id}/rkab/edit`
        : isDetail
          ? `/pit-production/${path}/${id}/rkab/detail`
          : isNaN(id)
            ? `/pit-production/${path}/create-report/rkab`
            : `/pit-production/${path}/${id}/rkab`,
    },
    {
      title: "Budget",
      val: "budget",
      nav: isEdit
        ? `/pit-production/${path}/${id}/budget/edit`
        : isDetail
          ? `/pit-production/${path}/${id}/budget/detail`
          : isNaN(id)
            ? `/pit-production/${path}/create-report/budget`
            : `/pit-production/${path}/${id}/budget`,
    },
    // {
    //   title: "Daily Plan",
    //   nav: isEdit
    //     ? `/pit-production/${path}/${id}/daily-plan/edit`
    //     : isDetail
    //       ? `/pit-production/${path}/${id}/daily-plan/detail`
    //       : isNaN(id)
    //         ? `/pit-production/${path}/create-report/daily-plan`
    //         : `/pit-production/${path}/${id}/daily-plan`,
    // },
  ];
};

export const tabQuickExecutiveReport = () => {
  let arr = []; // modulCode must be changed later after the access management for quick executive rerpot created
  if (permissionChecker(`Master Data : General - Location`, "view", false))
    arr.push({
      title: "Summary",
      nav: "/report/quick-executive-report",
    });
  if (permissionChecker(`Master Data : General - Location`, "view", false))
    arr.push({
      title: "Production",
      nav: "/report/quick-executive-report/production",
    });
  if (permissionChecker(`Master Data : General - Location`, "view", false))
    arr.push({
      title: "Stockpile",
      nav: "/report/quick-executive-report/stockpile",
    });
  if (permissionChecker(`Master Data : General - Location`, "view", false))
    arr.push({
      title: "Barging",
      nav: "/report/quick-executive-report/barging",
    });
  if (permissionChecker(`Master Data : General - Location`, "view", false))
    arr.push({
      title: "Transshipment",
      nav: "/report/quick-executive-report/transshipment",
    });

  return arr;
};

export const thousandSeparator = (number) => {
  // Convert number to string
  let numStr = number.toString().replace(/^0+/, "");
  if (numStr === "" || numStr === "0") {
    return "0"; // Return "0" if the number is effectively "0"
  }
  // Use regex to match digits in groups of three, inserting a comma between them
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numStr;
};

export const removeThousandSeparator = (numberString) => {
  return numberString === undefined || numberString === "" || numberString === '' ? 0 : parseInt(numberString?.toString().replaceAll('.', '') ?? 0);
}

export const replaceDotWithComma = (numberString) => {
  return numberString.toString().replace(".", ",");
};

export const showPlural = (unit, value) => {
  return value > 1 ? unit + 's' : unit
}

export const optionMapperCustomRedestructured = (data, option, defaultAll = true) => {
  if (!data) return [];
  const newData = data
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[option] === value[option])
    )
    .map((e) => ({
      ...e,
      label: e[option],
      value: e[option],
    }))
    .sort((a, b) =>
      String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
        ? -1
        : 1
    );
  if (defaultAll) newData.unshift({ label: "All", value: "" });
  return newData;
};

export const timeDiff = (timeNow, timeNext, date, val, shift, now = false) => {
  const dateNext = moment(date).add(1, "days").format("YYYY-MM-DD");
  const endShift =
    shift === "Night"
      ? moment(`${date} 06:00`).add(1, "day")
      : moment(`${date} 18:00`);

  let start, end;

  if (shift == "Night") {
    if (checkAM(parseInt(timeNow.replace(":", ""))))
      start = moment(`${dateNext} ${timeNow}`);
    else start = moment(`${date} ${timeNow}`);

    if (now) end = moment();
    else {
      if (checkAM(parseInt(timeNext.replace(":", ""))))
        end = moment(`${dateNext} ${timeNext}`);
      else if (!now) end = moment(`${date} ${timeNext}`);
    }

    if (end.isAfter(endShift)) end = endShift;
  } else {
    start = moment(`${date} ${timeNow}`);
    if (now) end = moment();
    else end = moment(`${date} ${timeNext}`);

    if (end.isAfter(endShift)) end = endShift;
  }
  const minutes = moment(end).diff(moment(start), "minutes");
  const hours = `${(minutes / 60) ^ 0}`.slice(-2);
  const minutesParse = ("0" + (minutes % 60)).slice(-2);

  const start2 = moment(`${date} ${timeNow}`);

  if (hours >= 12)
    return {
      ...val,
      minutes,
      start,
      end,
      start2,
      timeDiff: 12 + " Hours 00 Minutes",
    };

  return {
    ...val,
    minutes,
    start,
    end,
    start2,
    timeDiff: hours + " Hours " + minutesParse + " Minutes",
  };
};

export const checkAM = (time) => {
  if (time >= 0 && time <= 600) return true;
  return false;
};

export const checkPM = (time) => {
  if (time >= 1800 && time <= 2359) return true;
  return false;
};

export const sortActivity = (
  dataFetch,
  isJetty = false,
  isActivityList = false
) => {
  let activity = [],
    activityNextArr = [],
    activityNowArr = [],
    activityList = [];

  if (isActivityList && dataFetch.length > 0 && isJetty)
    activityList = dataFetch;
  else activityList = dataFetch?.activityList;

  if (isJetty) {
    if (activityList?.length === 0) return [];

    activity = activityList?.map((data) => {
      var time = data.startTime.split(":");
      return { ...data, timeFormat: `${time[0]}:${time[1]}` };
    });

    activity = _.orderBy(activity, ["date", "startTime"], ["asc", "asc"]).map(
      (data) => {
        return { ...data };
      }
    );

    return activity;
  } else {
    if (dataFetch?.shift == "Day") {
      activity = dataFetch?.activityList?.map((data, index) => {
        var time = data.startTime.split(":")
        return { ...data, timeFormat: `${time[0]}:${time[1]}` }
      })

      activity = _.sortBy(activity, "startTime").map((data) => {
        return { ...data };
      });
    } else if (dataFetch?.shift == "Night") {
      activityNowArr = dataFetch?.activityList.map((data, index) => {
        var time = data.startTime.split(":");
        const timeFormat = `${time[0]}:${time[1]}`;

        if (
          timeFormat.replace(":", "") >= 1800 &&
          timeFormat.replace(":", "") <= 2359
        )
          return { ...data, timeFormat };
      });

      activityNowArr = _.compact(
        _.sortBy(activityNowArr, "startTime").map((data) => {
          if (!isUndefined(data)) return { ...data };
        })
      );

      activityNextArr = dataFetch?.activityList.map((data, index) => {
        var time = data.startTime.split(":");
        const timeFormat = `${time[0]}:${time[1]}`;

        if (
          timeFormat.replace(":", "") >= 0 &&
          timeFormat.replace(":", "") <= 600
        )
          return { ...data, timeFormat };
      });

      activityNextArr = _.compact(
        _.sortBy(activityNextArr, "startTime").map((data) => {
          if (!isUndefined(data)) return { ...data };
        })
      );

      activity = activityNowArr.concat(activityNextArr);
    }

    return activity;
  }
};

export const getTotalFleet = (dataValue, type, quantity, location) => {
  const totalFleet = dataValue
    .filter((item) => item?.equipmentType?.type === type)
    .reduce((tot, curr) => {
      const cycleTime =
        location === "BIB"
          ? curr.cycleTimeBIB
          : location === "BIR"
            ? curr.cycleTimeBIR
            : curr.cycleTimeTIA;

      let result = (curr.payload / cycleTime) * quantity;
      if (isNaN(result) || result == Infinity) return 0;
      return result;
    }, 0);
  return totalFleet;
};

export const getTotalQuantity = (dataValue, type, location) => {
  const totalQuantity = dataValue
    .filter((item) => item?.equipmentType?.type === type)
    .reduce((tot, curr) => {
      const quantity =
        location === "BIB"
          ? curr.quantityBIB
          : location === "BIR"
            ? curr.quantityBIR
            : curr.quantityTIA;

      return tot + (quantity || 0);
    }, 0);
  return totalQuantity;
};

export const searchTable = (data, query, keyList, arr) => {
  data.map((val) => {
    Object.keys(val).map((key) => {
      const firstVal = val[key];

      if (
        !isObject(firstVal) &&
        firstVal &&
        keyList.some((item) => item === key)
      )
        arr = searchKey(val, firstVal, query, arr);
      else if (isObject(firstVal)) {
        Object.keys(firstVal).map((key2) => {
          const secondVal = val[key][key2];

          if (
            !isObject(secondVal) &&
            secondVal &&
            keyList.some((item) => item === key2) &&
            key2 != "contractorNotes"
          )
            arr = searchKey(val, secondVal, query, arr);
          else if (isObject(secondVal)) {
            Object.keys(secondVal).map((key3) => {
              const thirdVal = val[key][key2][key3];

              if (
                !isObject(thirdVal) &&
                thirdVal &&
                keyList.some((item) => item === key3) &&
                key3 != "contractorNotes"
              )
                arr = searchKey(val, thirdVal, query, arr);
            });
          }
        });
      }
    });
  });

  return arr;
};

export const searchKey = (data, objectData, query, arr) => {
  if (objectData.toString().toLowerCase().includes(query.toLowerCase())) {
    if (arr.length == 0) {
      arr.push(data);
      return arr;
    } else {
      var arrayCheck = arr.some((item) => data.id === item.id);
      if (!arrayCheck) {
        arr.push(data);
        return arr;
      }
    }
  }

  return arr;
};

export const jobList = [
  {
    type: "Production",
  },
  {
    type: "Non-Production",
  },
];

export const brandList = [
  {
    type: "Komatsu",
  },
  {
    type: "Caterpillar",
  },
  {
    type: "Kobelco",
  },
  {
    type: "Hitachi",
  },
  {
    type: "Hino",
  },
  {
    type: "Volvo",
  },
  {
    type: "Scania",
  },
  {
    type: "XCMG",
  },
];

export const materialList = [
  {
    name: "Waste",
  },
  {
    name: "Coal",
  },
];

export const wasteName = [
  {
    name: "OB Outpit",
  },
  {
    name: "Top Soil Inpit",
  },
  {
    name: "Top Soil Out",
  },
  {
    name: "Blasting",
  },
  {
    name: "Soft Material",
  },
  {
    name: "Pasir",
  },
  {
    name: "Mud",
  },
  {
    name: "Mud Outpit",
  },
  {
    name: "Mud Original",
  },
  {
    name: "OB Recovery",
  },
];

export const coalName = [
  {
    name: "Coal",
  },
];

export const getUsername = () => {
  const token = localStorage.getItem("token");

  let name = "User";
  if (token) {
    const parsedToken = JSON.parse(token);
    name = parsedToken?.data?.userName;
  }

  return name;
};

export const getToken = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const parsedToken = JSON.parse(token);
    return parsedToken?.token;
  }

  return null;
};

export const getCompany = (code = false) => {
  const token = localStorage.getItem("token");

  let name = "User";
  if (token) {
    const parsedToken = JSON.parse(token);
    if (code) name = parsedToken?.data?.contractorCode;
    else name = parsedToken?.data?.company;
  }

  return name;
};

export const getRole = () => {
  const token = localStorage.getItem("token");

  let name = "User";
  if (token) {
    const parsedToken = JSON.parse(token);
    name = parsedToken?.data?.role;
  }

  return name;
};

export const getUserID = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const parsedToken = JSON.parse(token);
    return parsedToken?.data?.id;
  }

  return false;
};

export const isAdminBIB = () => {
  const role = getRole();
  const adminBIB = "Admin BIB";

  return role === adminBIB;
};

export const adminRoleChecker = () => {
  const name = getRole();

  switch (name) {
    case "Admin Contractor":
    case "Admin BIB":
    case "Super Admin":
      return true;
  }

  return false;
};

export const bibAdminRoleChecker = () => {
  const name = getRole();

  switch (name) {
    case "Admin BIB":
    case "Super Admin":
      return true;
  }

  return false;
};

export const adminContractorChecker = (status) => {
  const name = getRole();

  if (
    name === "Admin Contractor" &&
    (status === DATASOURCE_VALUE.done || status === DATASOURCE_VALUE.rejected)
  )
    return false;

  return true;
};

export const roleValidation = (role, list) => {
  for (var i of list) {
    if (i == role) return true;
  }
  return false;
};

export const formatToDate = (date, format = "DD/MM/YYYY") => {
  if (!date) return "";
  const formated = moment(date).format(format);
  if (formated === "Invalid date") return date.split("/").reverse().join("-");
  return formated;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
export const generageReortID = (prefix) => {
  const date = new Date();
  const year = date.getFullYear().toString().substr(2);
  const month = date.getMonth() + 1;
  const second = date.getSeconds();
  const random = Math.floor(Math.random() * 100);
  return `${prefix}-${year}-${month}-${second}${random}`;
};

export const confirmationStatus = {
  approve: "approve",
  reject: "reject",
  submit: "Submitted",
  upload: "Upload",
};

export const savingState = {
  NOT_SAVED: 0,
  SAVING: 1,
  SAVED: 2,
  FAILED: 3,
};

export const DATASOURCE_TYPE = {
  draft: "DRAFT",
  rejected: "REJECTED",
  waitingApproval: "WAITING_APPROVAL",
  done: "DONE",
};

export const DATASOURCE_VALUE = {
  draft: 1,
  rejected: 4,
  waitingApproval: 2,
  done: 3,
};

export const STATUS_DROPDOWN_LIST = [
  {
    label: "Draft",
    value: DATASOURCE_VALUE.draft,
  },
  {
    label: "Rejected",
    value: DATASOURCE_VALUE.rejected,
  },
  {
    label: "Waiting Approval",
    value: DATASOURCE_VALUE.waitingApproval,
  },
  {
    label: "Done",
    value: DATASOURCE_VALUE.done,
  },
];

const grayText = {
  divBG: "bg-gray",
  textColor: "ccm-text-disable",
};
const orangeText = {
  divBG: "bg-orange-light",
  textColor: "ccm-text-orange-dark",
};
const redText = {
  divBG: "bg-red-light",
  textColor: "ccm-text-red",
};
const purpleText = {
  divBG: "bg-purple-light",
  textColor: "ccm-text-purple",
};
const greenText = {
  divBG: "bg-green-light",
  textColor: "ccm-text-green-light",
};
const blueText = {
  divBG: "bg-blue-light",
  textColor: "ccm-text-blue",
};

export const checkSameLevelApproval = ({ listApproval, dataStatus }) => {
  if (dataStatus < 20 || !dataStatus) return false;
  if (!listApproval || !getFromToken("email")) return false;

  const WAITING_APPROVAL_STATUS = 20;
  const userEmail = getFromToken("email");
  const userLevelApproval =
    listApproval
      .filter((item) => item.email === userEmail)
      .map((item) => item?.level) || [];
  const docLevel = dataStatus - WAITING_APPROVAL_STATUS + 1;

  return userLevelApproval.includes(docLevel);
};

export const getStatusClass = (status) => {
  switch (status) {
    case 0:
    case 1:
    case "draft":
      return {
        ...grayText,
        text: "Draft",
        divText: "Draft",
      };
    case 8:
    case "diarsipkan": {
      return {
        ...grayText,
        text: "Diarsipkan",
        divText: "Diarsipkan",
      };
    }
    case 6:
    case "tidak_ditayangkan":
      return {
        ...orangeText,
        text: "Tidak ditayangkan",
        divText: "",
      };
    case "unpublished":
      return {
        ...orangeText,
        text: "Unpublished",
        divText: "Unpublished",
      };
    case 2:
    case "waiting approval":
    case "waiting_approval":
    case "menunggu_persetujuan":
      return {
        ...orangeText,
        text: "Waiting Approval",
        divText: "Waiting Approval",
      };
    case "diproses":
      return {
        ...orangeText,
        text: "Diprosses",
        divText: "Permintaan sedang Diproses",
      };
    case "rejected":
      return {
        ...redText,
        text: "Rejected",
        divText: "Rejected",
      };
    case "inactive":
      return {
        ...redText,
        text: "Inactive",
        divText: "Inactive",
      };
    case "dibatalkan":
      return {
        ...redText,
        text: "Dibatalkan",
        divText: "Dibatalkan",
      };
    case 4:
    case "ditolak":
      return {
        ...redText,
        text: "Rejected",
        divText: "Rejected",
      };
    case "deleted":
      return {
        ...redText,
        text: "Deleted",
        divText: "Deleted",
      };
    case "terkirim":
      return {
        ...purpleText,
        text: "Terkirim",
        divText: "Terkirim",
      };
    case "active":
      return {
        ...greenText,
        text: "Active",
        divText: "Active",
      };
    case "approved":
      return {
        ...greenText,
        text: "Approved",
        divText: "Approved",
      };
    case "published":
      return {
        ...greenText,
        text: "Published",
        divText: "Published",
      };
    case 3:
    case "disetujui":
      return {
        ...greenText,
        text: "Done",
        divText: "Done",
      };
    case 5:
    case "ditayangkan":
    case "done":
      return {
        ...greenText,
        text: "Done",
        divText: "Done",
      };
    case "selesai":
      return {
        ...greenText,
        text: "Selesai",
        divText: "Selesai",
      };
    case "waiting_request_approval":
      return {
        ...grayText,
        text: "Waiting Request Approval",
        divText: "Waiting Request Approval",
      };
    case "sent":
      return {
        ...blueText,
        text: "Dikirim",
        divText: "Dikirim",
      };
    case "submitted":
      return {
        ...blueText,
        text: "Submitted",
        divText: "Submitted",
      };
    default:
      return status === 2 || status >= 20
        ? {
          ...orangeText,
          text: "Waiting Approval",
          divText: "Waiting Approval",
        }
        : {};
  }
};

export const getCCMStatusClass = (statusData) => {
  let status =
    typeof statusData == "string" ? statusData.toLowerCase() : statusData;
  switch (status) {
    case 0:
      return {
        ...grayText,
        text: "Inactive",
        divText: "Inactive",
      };
    case 1:
      return {
        ...grayText,
        text: "Draft",
        divText: "Draft",
      };
    case 3:
      return {
        ...greenText,
        text: "Done",
        divText: "Done",
      };
    case 4:
      return {
        ...redText,
        text: "Rejected",
        divText: "Rejected",
      };
    default:
      return {
        ...orangeText,
        text: "Waiting Approval",
        divText: "Waiting Approval",
      };
  }
};

export const getActiveTab = (status) => {
  switch (status) {
    case "/coal-getting":
    case "/pit-production/coal-getting":
    case "/waste-removal":
    case "/pit-production/waste-removal":
    case "/pit-production/short-term-planning":
      return {
        index: "0",
      };
    case "/configuration":
    case "/configuration/payload/waste-removal":
    case "/configuration/distance/waste-removal":
    case "/configuration/slipperly/waste-removal":
      return {
        index: "6",
      };
    case "/master-data":
    case "/master-data/area":
    case "/master-data/loading-dumping":
    case "/master-data/sub-location":
    case "/master-data/equipment":
    case "/master-data/detail-equipment":
    case "/master-data/elevation":
    case "/master-data/status":
    case "/master-data/material":
    case "/master-data/contractor":
      return {
        index: "7",
      };
    default:
      return {
        index: "",
      };
  }
};

export const generateQueryString = (params) => {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
};

export const safeParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const monthList = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const formatDate = (date, formatOption) => {
  if (!date) {
    return "";
  }

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  let currDate;

  if (formatOption == "yyyy/mm/dd")
    return (currDate = new Date(date).toLocaleDateString("en-CA", options));

  return (currDate = new Date(date).toLocaleDateString("id-ID", options));
};

export const formatDate2 = (date) => {
  if (!date) {
    return "";
  }
  const currDate = new Date(date);
  const year = currDate.getFullYear();
  const month = currDate.getMonth() + 1;
  const day = currDate.getDate();
  const localDatetime =
    year +
    "-" +
    (month < 10 ? "0" + month.toString() : month) +
    "-" +
    (day < 10 ? "0" + day.toString() : day);

  // format: yyyy-mm-dd
  return localDatetime;
};

export const formatDateTime = (date, wita = false) => {
  if (!date) {
    return "";
  }
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  const currDate = new Date(`${date}Z`).toLocaleDateString("id-ID", options);
  const timeFormat = new Date(`${date}Z`).toLocaleTimeString(
    "id-ID",
    timeOptions
  );

  if (wita) {
    const witaDate = moment(`${currDate} ${timeFormat}`, "DD/MM/YYYY HH.mm")
      .tz("Asia/Kuching")
      .format("DD/MM/YYYY HH.mm");
    return witaDate;
  }

  return `${currDate} ${timeFormat}`;
};

export const formatDateTimeZone = (date) => {
  if (!date) {
    return "";
  }
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "Asia/Makassar",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Asia/Makassar",
  };

  const currDate = new Date(`${date}Z`).toLocaleDateString("id-ID", options);
  const timeFormat = new Date(`${date}Z`).toLocaleTimeString(
    "id-ID",
    timeOptions
  );
  return `${currDate} ${timeFormat} (GMT+8)`;
};

export const formatLastSaved = (date) => {
  if (!date) {
    return "";
  }

  const lastSavedDate = new Date(`${date}Z`);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };
  const dateFormat = lastSavedDate.toLocaleDateString("id-ID", options);
  const timeFormat = lastSavedDate.toLocaleTimeString("id-ID", timeOptions);

  return `${dateFormat} ${timeFormat}`;
};

export const formatLastSavedOperator = (date) => {
  if (!date) {
    return "";
  }

  const lastDate = new Date(`${date}Z`);

  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  const dateFormat = lastDate.toLocaleDateString("id-ID", options);
  const timeFormat = lastDate.toLocaleTimeString("id-ID", timeOptions);

  return `${dateFormat} ${timeFormat}`;
};

export const formatCalendarDate = (date, format = "id - ID", timeZone) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric', timeZone };
  return new Intl.DateTimeFormat(format, options).format(date);
};

export const formatDateShort = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);

  return `${year}${month}${day}`;
}

export const formatDateINA = (date, format = "id-ID") => {

  const utcDate = new Date(date + "Z");
  const timeCompare = new Date().getTimezoneOffset() / 60

  let timeZone = "Asia/Jakarta"
  let zone = " WIB"
  if (timeCompare === 8) {
    zone = " WITA"
    timeZone = "Asia/Makasar"
  }

  if (timeCompare === 9) {
    zone = " WIT"
    timeZone = "Asia/Jayapura"
  }

  const options = { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone };
  const formattedDate = utcDate.toLocaleString(format, options).replace(',', '') + zone;
  return formattedDate
}

export const initDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const options_day = { weekday: "long" };

  const dateTime = date ? new Date(date) : new Date();

  const currDate = dateTime.toLocaleDateString("id-ID", options);
  const currDay = dateTime.toLocaleDateString("en-US", options_day);

  return `${currDay}, ${currDate}`;
};

const emptyOption = { label: "All", value: "" };

export const emptyOptionPad = (
  options,
  emptyLabel = "All",
  isEmptyLabel = true
) => {
  if (isArray(options)) {
    if (!isEmptyLabel) return [...options];
    return [{ ...emptyOption, label: emptyLabel }, ...options];
  }
  return [
    { ...emptyOption, label: emptyLabel },
    { label: options, value: options },
  ];
};

export const optionMapper = (data) => {
  return data.map((e) => ({ label: e, value: e }));
};

export const optionMapperVersion = (data) => {
  return data.map((e) => ({ label: `Version ${e}`, value: e }));
};

export const optionMapperEquipment = (data) => {
  const newData = data.map((e) => ({ label: e.type, value: e.id }));
  newData.unshift({ label: "-", value: "" });
  return newData;
};

export const optionMapperId = (data) => {
  const newData = data.map((e) => ({ label: e.name, value: e.id }));
  newData.unshift({ label: "All", value: "" });
  return newData;
};

export const masterDataIdChecker = (data, id) => {
  for (var i of data) {
    for (var j in id) {
      if (i.id == id[j].id) {
        id[j] = i;
      }
    }
  }

  return id;
};

export const sortItems = (prev, curr, columnId) => {
  const subObject = columnId.includes(".");
  let prevData;
  let currData;
  let splitObj;

  if (subObject) {
    splitObj = columnId.split(".");

    prevData = !isNull(prev.original[splitObj[0]][splitObj[1]])
      ? prev.original[splitObj[0]][splitObj[1]]
      : "";
    currData = !isNull(curr.original[splitObj[0]][splitObj[1]])
      ? curr.original[splitObj[0]][splitObj[1]]
      : "";
  } else {
    prevData = !isNull(prev.original[columnId]) ? prev.original[columnId] : "";
    currData = !isNull(curr.original[columnId]) ? curr.original[columnId] : "";
  }

  if (isObject(prevData)) {
    prevData = prevData?.name ? prevData?.name : prevData?.type;
    currData = currData?.name ? currData?.name : currData?.type;
  }

  if (isString(prevData)) {
    if (prevData?.toLowerCase() > currData?.toLowerCase()) return 1;
    else if (prevData?.toLowerCase() < currData?.toLowerCase()) return -1;
    // else return 0;
  } else if (isInteger(prevData)) {
    if (prevData > currData) return 1;
    else if (prevData < currData) return -1;
    // else return 0;
  }
};

export const optionMapperCustomMultiple = (
  data,
  option,
  secondOption = "",
  thirdOption = ""
) => {
  let newData = [];

  try {
    newData = data
      .filter((value, index, self) => {
        if (thirdOption)
          return (
            index ===
            self.findIndex(
              (t) =>
                t[option][secondOption][thirdOption] ===
                value[option][secondOption][thirdOption]
            )
          );
        else if (secondOption)
          return (
            index ===
            self.findIndex(
              (t) => t[option][secondOption] === value[option][secondOption]
            )
          );
        return index === self.findIndex((t) => t[option] === value[option]);
      })
      .map((e) => {
        if (thirdOption)
          return {
            label: e[option][secondOption][thirdOption],
            value: e[option][secondOption][thirdOption],
          };
        else if (secondOption)
          return {
            label: e[option][secondOption],
            value: e[option][secondOption],
          };
        return { label: e[option], value: e[option] };
      })
      .sort((a, b) =>
        String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
          ? -1
          : 1
      );
  } catch { }

  newData.unshift({ label: "All", value: "" });
  return newData;
};

export const optionMapperCustomMultipleId = (
  data,
  option,
  secondOption = "",
  thirdOption = "",
  isEmptyLabel = true
) => {
  let newData = [];

  try {
    newData = data
      .filter((value, index, self) => {
        if (thirdOption)
          return (
            index ===
            self.findIndex(
              (t) =>
                t[option][secondOption][thirdOption] ===
                value[option][secondOption][thirdOption]
            )
          );
        else if (secondOption)
          return (
            index ===
            self.findIndex(
              (t) => t[option][secondOption] === value[option][secondOption]
            )
          );
        return index === self.findIndex((t) => t[option] === value[option]);
      })
      .map((e) => {
        if (thirdOption)
          return {
            label: e[option][secondOption][thirdOption],
            value: e[option][secondOption]["id"],
          };
        else if (secondOption)
          return {
            label: e[option][secondOption],
            value: e[option]["id"],
          };
        return { label: e[option], value: e[option] };
      })
      .sort((a, b) =>
        String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
          ? -1
          : 1
      );
  } catch { }

  if (isEmptyLabel) newData.unshift({ label: "All", value: "" });
  return newData;
};

export const optionMapperCustom = (data, option, defaultAll = true) => {
  if (!data) return [];
  const newData = data
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[option] === value[option])
    )
    .map((e) => ({
      label: e[option],
      value: e[option],
    }))
    .sort((a, b) =>
      String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
        ? -1
        : 1
    );
  if (defaultAll) newData.unshift({ label: "All", value: "" });
  return newData;
};

export const optionObjectMapperCustom = (
  data,
  option,
  defaultAll = true,
  showDuplicateLabel = false,
  duplicateValue = true
) => {
  const newData = duplicateValue
    ? data
      .map((e) => ({
        label: e[option],
        value: e,
      }))
      .sort((a, b) =>
        String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
          ? -1
          : 1
      )
    : data
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t[option] === value[option])
      )
      .map((e) => ({
        label: e[option],
        value: e,
      }))
      .sort((a, b) =>
        String(a["label"]).toLowerCase() < String(b["label"]).toLowerCase()
          ? -1
          : 1
      );

  if (!showDuplicateLabel)
    newData.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t[option] === value[option])
    );
  if (defaultAll) newData.unshift({ label: "All", value: "" });
  return newData;
};

export const selectOption = (options, key) => {
  const label = key ? options[key] : options;
  return { label, value: options };
};

export const findOption = (options, value) => {
  if (isArray(value)) {
    return value.map((nestedItem) => findOption(options, nestedItem));
  }
  return options.find((option) => option.value === value);
};

export const genUUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const genReportId = (customId) => {
  const year = new Date().getFullYear().toString().padEnd(2, 0);
  const month = new Date().getMonth().toString().padEnd(2, 0);

  return [customId, year, month].join("-");
};

export const upperToCapital = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const AutoSaveDisplay = ({ saving }) => {
  let display;
  switch (saving) {
    case savingState.SAVING:
      display = <em>Saving...</em>;
      break;
    case savingState.SAVED:
      display = (
        <>
          <em className="saved">Saved!</em>
        </>
      );
      break;
    case savingState.FAILED:
      display = (
        <>
          <em className="failed">Failed!</em>
        </>
      );
      break;
    default:
      display = <br />;
  }
  return <span className="mr-10">{display}</span>;
};

export const slicingRequestBody = (data) => {
  let bodyData = data;
  let id = data.id;

  delete bodyData.id;
  delete bodyData.dataStatus;
  delete bodyData.createdBy;
  delete bodyData.updatedBy;

  return {
    id,
    bodyData,
  };
};

export const ltrim = (str) => {
  if (!str) return str;
  return str.replace(/^\s+/g, "");
};

export const numberFormat = (value, min = 2, max = 2) => {
  if (value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })
      .format(value)
      .replace("Rp", "");
  } else return value;
};

export const generateNumber = (digit) => {
  var add = 1,
    max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

  if (digit > max) {
    return generateNumber(max) + generateNumber(digit - max);
  }

  max = Math.pow(10, digit + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
};

export const inputFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const mergeObjectsByDay = (arr) => {
  if (!arr) return [];

  let merged = {};

  for (let obj of arr) {
    if (merged[obj.day]) {
      merged[obj.day].detail = merged[obj.day].detail.concat(obj.detail);
    } else {
      merged[obj.day] = { ...obj };
    }
  }

  return Object.values(merged);
};

const mergeAndSumBcm = (arr) => {
  if (!arr) return [];

  let merged = {};

  for (let obj of arr) {
    for (let detail of obj.detail) {
      let key = `${detail.contractorCode}-${detail.pitCode}`;

      if (merged[obj.day]) {
        if (merged[obj.day][key]) {
          merged[obj.day][key].bcm += detail.bcm;
        } else {
          merged[obj.day][key] = {
            contractorCode: detail.contractorCode,
            pitCode: detail.pitCode,
            bcm: detail.bcm,
          };
        }
      } else {
        merged[obj.day] = {
          [key]: {
            contractorCode: detail.contractorCode,
            pitCode: detail.pitCode,
            bcm: detail.bcm,
          },
        };
      }
    }
  }

  return Object.entries(merged).map(([day, details]) => ({
    day: Number(day),
    detail: Object.values(details),
  }));
};

export const formatSeparatorNumber = (value) => {
  let parts = value.toString().split(".");
  parts[0] = parseInt(parts[0]).toLocaleString();
  return parts.join(".");
};

export const generateActualTooltip = (arr, currentMonthLength = 0) => {
  if (!arr) return [];

  const result = new Array(currentMonthLength).fill(0).map(() => []);

  arr.forEach((item) => {
    const dayIndex = item.day - 1;
    if (dayIndex < currentMonthLength) {
      const details = item.detailList.flatMap((detail) =>
        detail.pitList.map(
          (pit) =>
            `${detail.contractor} - ${pit.pit}: ${formatSeparatorNumber(
              `${pit.bcm.toFixed(3)}`
            )}`
        )
      );
      result[dayIndex] = details;
    }
  });

  return result;
};

const transformDataBcmPlan = (currentData, devidedData) => {
  if (!currentData || !devidedData) return [];
  const result = [];

  currentData.forEach(({ day, detail }) => {
    const devidedValue = devidedData[day - 1];
    const divisor = devidedValue
      ? (devidedValue / detail.length).toFixed(3)
      : 0;
    const dayResult = [];

    detail.forEach(({ contractorCode, pitCode }) => {
      dayResult.push(`${contractorCode} - ${pitCode}: ${divisor}`);
    });

    result.push(dayResult);
  });

  return result;
};

export const mergeAndSumBcmDetail = (
  data,
  dataType = "actual",
  devidedData = []
) => {
  if (!data) return [];

  const mergedData = mergeAndSumBcm(mergeObjectsByDay(data));

  switch (dataType) {
    case "actual":
      return generateActualTooltip(mergedData);
    case "plan":
      return transformDataBcmPlan(mergedData, devidedData);
    default:
      return [];
  }
};

const calculateRitaseAndBcmForRow = (row, totalShif) => {
  let sumRitase = 0;
  for (let i = 1; i <= totalShif; i++) {
    sumRitase += row[`shif${i}`] || 0;
  }
  const bcm =
    sumRitase && row?.payloadValue
      ? sumRitase * row?.payloadValue
      : row.bcm || 0;
  return { ...row, ritase: sumRitase, bcm };
};

const updateParentShifsAndCalculate = (subRows, totalShif) => {
  const result = { ritase: 0, bcm: 0 };

  for (let i = 1; i <= totalShif; i++) {
    result[`shif${i}`] = 0;
  }

  subRows.forEach((item) => {
    for (let i = 1; i <= totalShif; i++) {
      result[`shif${i}`] += item[`shif${i}`] || 0;
    }
    result.ritase += item.ritase;
    result.bcm += item.bcm;
  });

  return result;
};

export const calculateShifRitaseAndBcm = (data) => {
  if (!data) return data;

  const TOTAL_SHIF = 12;

  return data.map((item) => {
    const updatedSubRows = item.subRows.map((row) =>
      calculateRitaseAndBcmForRow(row, TOTAL_SHIF)
    );
    const updatedParent = updateParentShifsAndCalculate(
      updatedSubRows,
      TOTAL_SHIF
    );
    return { ...item, ...updatedParent, subRows: updatedSubRows };
  });
};

export const formatNumber = (num) => {
  if (!num) return "0.000";

  const [integerPart, decimalPart] = Number(num).toFixed(3).split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  return decimalPart === "000"
    ? formattedIntegerPart
    : `${formattedIntegerPart},${decimalPart}`;
};

export const addDataChartPit = (dataDetails) => {
  if (!dataDetails) return [];

  const newDataDetails = JSON.parse(JSON.stringify(dataDetails));
  return newDataDetails.map((dayData) => {
    const dataChartPit = {};

    const detailList = dayData.detailList.map((detail) => {
      const { contractor, totalBcm, pitList } = detail;

      if (!dataChartPit[contractor]) {
        dataChartPit[contractor] = {
          label: [],
          dataActual: [],
        };
      }

      pitList.forEach((pit) => {
        dataChartPit[contractor].label.push(pit.pit);
        dataChartPit[contractor].dataActual.push(pit.bcm);
      });

      return {
        contractor,
        totalBcm,
        pitList,
      };
    });

    return {
      day: dayData.day,
      totalBcm: dayData.totalBcm,
      detailList,
      dataChartPit,
    };
  });
};

export const addDataChartContractor = (dataDetails) => {
  if (!dataDetails) return [];

  const newDataDetails = JSON.parse(JSON.stringify(dataDetails));
  return newDataDetails.map((item) => {
    const contractors = {};

    item.detailList.forEach((detail) => {
      if (!contractors[detail.contractor]) {
        contractors[detail.contractor] = 0;
      }
      contractors[detail.contractor] += detail.totalBcm;
    });

    item.dataChartContractor = {
      label: Object.keys(contractors),
      dataActual: Object.values(contractors),
    };

    return item;
  });
};
export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const base64ToFileImage = (base64String, filename) => {
  // Split the base64 string into header and data
  const [header, data] = base64String.split(",");

  // Check if the header and data are present
  if (!header || !data) {
    throw new Error("Invalid base64 string");
  }

  // Extract MIME type from the header
  const mimeType = header.match(/:(.*?);/)?.[1] || "image/png";

  // Decode base64 string to binary data
  const binaryString = atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([bytes], { type: mimeType });

  // Create a File from the Blob
  const newFile = new File([blob], filename, { type: mimeType });
  return newFile;
};


export const checkIsPortDayTime = (port, selectedDate) => {
  const currentHour = new Date().getHours();
  const currentDate = formatDate2(new Date());
  const dateToCheck = (selectedDate); // Ensure selectedDate is formatted to match currentDate format


  let isNightShift;
  const isSameDate = dateToCheck === currentDate;
  const isBeforeCurrentDate = dateToCheck < currentDate;

  switch (port) {
    case "ROM":
      // Night shift: 7 pm to 7 am
      isNightShift = currentHour >= 19 || currentHour < 7;
      break;

    case "Crushing":
    case "BLC":
    case "Jetty":
      // Night shift: 6 pm to 6 am
      isNightShift = currentHour >= 18 || currentHour < 6;
      break;

    default:
      isNightShift = false;
      break;
  }



  // Show both options if it's a night shift on the current date or if the selected date is before the current date
  return isNightShift && isSameDate || isBeforeCurrentDate ? ["Day", "Night"] : isNightShift ? ["Night"] : ["Day"];
};


